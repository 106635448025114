import * as Yup from 'yup';
import { AutoEmailRecipientAddFormData } from '~/wm/packages/organization/packages/auto-email/packages/auto-email-recipients/packages/auto-email-recipient-add-form/AutoEmailRecipientAddForm';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const autoEmailRecipientAddFormValidationSchema: Yup.ObjectSchema<any> = Yup.object().shape<Partial<AutoEmailRecipientAddFormData>>({
  name: Yup.string()
    .required('Required.')
    .max(255, 'The name may only be 255 characters.')
    .test('is_valid_name', 'Name can only be a first or first and last name.', value => {
      if (!value) {
        return false;
      }
      const nameParts = value.trim().split(' ');
      return nameParts.length === 1 || nameParts.length === 2;
    }),
  email: Yup.string().required('Required.').max(255, 'The email may only be 255 characters.').email('Invalid email address.'),
});

export default autoEmailRecipientAddFormValidationSchema;
