import { css } from '@emotion/react';
import { SidebarDisplay } from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/SidebarDisplay';
import useIntegrationSetupAndDefinitions, {
  IntegrationSetupSubmitState,
} from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/hooks/useIntegrationSetupAndDefinitions';
import {
  findOauthCredentialField,
  IntegrationCredentials,
} from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/credentials/IntegrationCredentials';
import React, { StrictMode, useEffect, useRef, useState } from 'react';
import IntegrationSetupDeleteButton from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/delete/IntegrationSetupDeleteButton';
import { ToolbarControl } from '~/neo-ui/packages/layout/packages/toolbar/Toolbar';
import SyncPreferences from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/sync-preferences/SyncPreferences';
import { calculateStage } from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/utils';
import {
  calculateOauthInformation,
  OauthTileController,
} from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/credentials/OauthTileController';
import { NicknameBox } from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/nickname/NicknameBox';
import { useSyncPreferencesForm } from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/hooks/useSyncPreferencesForm';
import IntegrationSetupPageSection from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/page-layout/IntegrationSetupPageSection';
import { SyncStatusController } from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/sync-status/SyncStatusController';
import routes from '~/router/types/routes';
import { InputTypeNested } from '@AssetManagementClient/BeastClient/SyncGod/Shared/ApiHandler/Credential.gen';
import { useNavigate } from 'react-router-dom';
import { DevModeBanner } from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/DevModeBanner';

export type IntegrationSetupPageProps = {
  integrationId?: string;
  integrationSetupId?: string;
  integrationOauthRedirectUri: string;
};

export const IntegrationSetupPage: React.FunctionComponent<IntegrationSetupPageProps> = ({
  integrationId,
  integrationSetupId,
  integrationOauthRedirectUri,
}) => {
  const { integrationSetupDisplayDto, submitIntegrationSetup, integrationSetupSubmitState } = useIntegrationSetupAndDefinitions({
    integrationId,
    integrationSetupId,
  });

  const derivedIntegrationSetupId = integrationSetupDisplayDto?.integrationSetupId;

  const stage = calculateStage(integrationSetupId);
  const navigate = useNavigate();

  /** URL Create to Edit URL transition Logic
   * Update URL when credentials are synced when an integration is first added
   * When someone is building a new integration, submits their credentials
   * successfully, and then refreshes the page. Unless we change the URL, they'll return to the create page dialogue
   * and their creds will be wiped. So we need to change the URL to the edit URL with the integrationSetupId. When a
   * user reloads, their credentials will be shown as saved. **/
  useEffect(() => {
    if (derivedIntegrationSetupId && stage === 'newIntegration') {
      const newPathname = routes.ACCOUNT_INTEGRATION_EDIT.build([derivedIntegrationSetupId]);
      const currentPathname = window.location.pathname;
      if (newPathname !== currentPathname) {
        navigate(newPathname, { replace: true });
      }
    }
  }, [derivedIntegrationSetupId, navigate, stage]);
  /** END Create to Edit URL transition Logic **/

  const [expandSuccessSection, setExpandSuccessSection] = useState(
    stage === 'newIntegration'
      ? true // Always expand the credential section during creation
      : integrationSetupSubmitState === IntegrationSetupSubmitState.Ready ||
          integrationSetupSubmitState === IntegrationSetupSubmitState.LastSubmitFailed,
  );

  /** OAuth Logic **/
  const oauthCredentialField = integrationSetupDisplayDto
    ? findOauthCredentialField(integrationSetupDisplayDto.integrationCredentials)
    : undefined;
  const usingOauth = oauthCredentialField !== undefined;
  const oauthInfo = calculateOauthInformation(oauthCredentialField?.value);
  const lastOauthSuccess = oauthInfo.status === 'lastAttemptSuccess';
  /** END OAuth Logic **/

  const { submitSyncPreferences, useFormReturn } = useSyncPreferencesForm({
    integrationPreferences: integrationSetupDisplayDto?.integrationPreferences,
    submitIntegrationSetup,
    integrationId: integrationSetupDisplayDto?.integrationId,
    integrationSetupId: derivedIntegrationSetupId,
  });

  const deleteControl: ToolbarControl | undefined =
    typeof derivedIntegrationSetupId === 'undefined'
      ? undefined
      : {
          expanded: (
            <IntegrationSetupDeleteButton
              isDisabled={expandSuccessSection}
              integrationSetupId={derivedIntegrationSetupId}
            />
          ),
        };

  /** Routing **/
  const helpCenterLink = integrationSetupDisplayDto?.integrationDetails.helpCenterUrlPath
    ? routes.HELP_CENTER_ARTICLE.build(
        integrationSetupDisplayDto?.integrationDetails.helpCenterUrlPath
          ? [integrationSetupDisplayDto?.integrationDetails.helpCenterUrlPath]
          : [],
      )
    : undefined;
  /** END Routing **/

  const syncStatusTileRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (useFormReturn.formState.isSubmitSuccessful) {
      // Scroll box into view after transition to syncing state
      syncStatusTileRef.current?.scrollIntoView({ block: 'end', behavior: 'auto' });
    }
  }, [useFormReturn.formState.isSubmitSuccessful]);

  return !integrationSetupDisplayDto ? (
    <></>
  ) : (
    <StrictMode>
      <div
        css={css`
          flex-grow: 1;
          display: flex;
          flex-direction: row;
          // Apply margin-top of one rem something with the class of 'banners' is present on the page
          ${document.getElementsByClassName('banners').length > 0 ? 'margin-top: 1rem;' : ''}
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            row-gap: 2rem;
            flex: 4 1 65rem;
            padding-right: 1.5rem;
          `}
        >
          {integrationSetupDisplayDto.integrationDetails.developmentMode && (
            <DevModeBanner
              vendorBrandName={integrationSetupDisplayDto.integrationDetails.vendorBrandName}
              isNewIntegration={stage === 'newIntegration'}
            />
          )}
          <IntegrationSetupPageSection title={'Manage sync'}>
            <IntegrationCredentials
              integrationId={integrationSetupDisplayDto.integrationId}
              integrationSetupId={integrationSetupId || derivedIntegrationSetupId}
              credentialFields={integrationSetupDisplayDto.integrationCredentials}
              initialPermissionWarnings={integrationSetupDisplayDto.integrationPermissionWarnings}
              submitIntegrationSetupForm={submitIntegrationSetup}
              integrationSetupSubmitState={integrationSetupSubmitState}
              expandSuccessSection={expandSuccessSection}
              setExpandSuccessSection={setExpandSuccessSection}
              helpCenterLinkUrl={helpCenterLink}
              integrationOauthRedirectUri={integrationOauthRedirectUri}
            />
            {oauthCredentialField && (
              <OauthTileController
                redirectUri={integrationOauthRedirectUri}
                integrationCredentials={integrationSetupDisplayDto.integrationCredentials}
                oauthUrl={(oauthCredentialField.inputType as InputTypeNested.InteractiveOAuth).url}
                integrationStatus={oauthCredentialField.value}
                isDisabled={expandSuccessSection}
                integrationObfuscatedId={integrationSetupId}
              />
            )}
            {derivedIntegrationSetupId && (
              <SyncStatusController
                syncStatusTileRef={syncStatusTileRef}
                integrationSetupId={derivedIntegrationSetupId}
                integrationSetupSubmitState={integrationSetupSubmitState}
              />
            )}
            {stage === 'updateIntegration' && (
              <NicknameBox
                placeholder={integrationSetupDisplayDto.integrationDetails.vendorBrandName}
                register={useFormReturn.register}
              />
            )}
          </IntegrationSetupPageSection>
          <SyncPreferences
            integrationSetupId={integrationSetupId || derivedIntegrationSetupId}
            integrationPreferences={integrationSetupDisplayDto.integrationPreferences}
            deleteControl={deleteControl}
            submitControlDisabled={expandSuccessSection || (usingOauth && !lastOauthSuccess)}
            submitSyncPreferences={submitSyncPreferences}
            useFormReturn={useFormReturn}
          />
        </div>
        <SidebarDisplay
          integrationDetails={integrationSetupDisplayDto.integrationDetails}
          helpCenterLink={helpCenterLink}
        />
      </div>
    </StrictMode>
  );
};
