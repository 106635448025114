import { ConsoleMetadata, ConsoleState } from '~/neo-ui/packages/table/packages/console/types';
import * as React from 'react';
import debounce from 'debounce-promise';
import { v4 as uuidv4 } from 'uuid';
import Console from '~/neo-ui/packages/table/packages/console/Console';
import { ConsoleRow } from '@AssetManagementClient/BeastClient/Search/Model/Console.gen';
import { ToolbarControl } from '~/neo-ui/packages/layout/packages/toolbar/Toolbar';
import { Enum as SpreadsheetTypeFactoryEnum } from '@AssetManagementClient/Document/Spreadsheet/Model/SpreadsheetTypeFactoryNested.gen';
import { ResponseTemplate } from '~/neo-ui/packages/table/packages/console/hook/useConsole';
import { DisplayFeaturesEnum } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/AssetScope/Model/AssetScopeNested.gen';

export type AsyncConsoleProps = {
  onFetchRows: (consoleState: ConsoleState, context: object) => Promise<ResponseTemplate | undefined>;
  onFetchIds?: (consoleState: ConsoleState, context: object) => Promise<{ ids: string[] } | undefined>;
  topFilterLine?: React.ReactNode;
  tableHeaderSection?: React.ReactNode;
  hasCheckboxes?: boolean;
  noResultsText?: string;
  banner?: JSX.Element;
  reset?: () => void;
  extraToolbarButtons?: { control: ToolbarControl; order: number }[];
  download?: (
    consoleState: ConsoleState,
    selectedColumns: Set<string> | undefined,
    context: object,
    spreadsheetType: SpreadsheetTypeFactoryEnum,
  ) => void;
  canDownload?: boolean;
  legacyDownloadUrl: string;
  displayFeatures: Set<DisplayFeaturesEnum> | undefined;

  clearFiltersOnFirstLoad?: boolean;
  canSelectDisplayColumns?: boolean;
  hasUpgradeBanner?: boolean;
};

// eslint-disable-next-line @typescript-eslint/ban-types
const AsyncConsole = ({
  onFetchRows,
  onFetchIds = () => Promise.resolve({ ids: [] }),
  topFilterLine,
  tableHeaderSection,
  hasCheckboxes = false,
  noResultsText,
  banner,
  reset = () => {},
  extraToolbarButtons,
  download,
  canDownload,
  legacyDownloadUrl,
  displayFeatures,
  clearFiltersOnFirstLoad = false,
  canSelectDisplayColumns = true,
  hasUpgradeBanner,
}: AsyncConsoleProps) => {
  const currentFetchToken = React.useRef<string>();

  const populateRows = React.useCallback(
    async (consoleState: ConsoleState, context: object, fetchToken: string) => {
      const rowResponse: ResponseTemplate | undefined = await onFetchRows(consoleState, context);
      if (typeof rowResponse !== 'undefined' && currentFetchToken.current === fetchToken) {
        const { results, filters, columns } = rowResponse.result;
        setData(d => ({
          ...d,
          consoleRows: results.results,
        }));

        setMetadata({
          filters: filters.map(filter => ({
            key: filter.key.value,
            label: filter.label,
            tags: new Map(Object.entries(filter.tags)),
            order: filter.order,
            render: filter.render,
            isEnabled: filter.isEnabled,
          })),
          columns: columns.map(column => ({
            key: column.key.value,
            label: column.label,
            isSortable: column.isSortable,
            order: column.order,
            availability: column.availability,
            size: column.size,
          })),
          pagination: {
            totalPages: results.metadata.totalPages,
            totalResults: results.metadata.totalResult,
          },
        });
      }
    },
    [onFetchRows],
  );

  const populateIds = React.useCallback(
    async (consoleState: ConsoleState, context: object, fetchToken: string) => {
      const idResponse = await onFetchIds(consoleState, context);
      if (typeof idResponse !== 'undefined' && currentFetchToken.current === fetchToken) {
        setData(d => ({ ...d, consoleIds: idResponse.ids }));
      }
    },
    [onFetchIds],
  );

  const onFetchConsoleRowsDebounced = React.useMemo(
    () =>
      debounce((consoleState: ConsoleState, context: object, fetchToken: string) => {
        const requests = [populateRows(consoleState, context, fetchToken)];
        if (hasCheckboxes) {
          requests.push(populateIds(consoleState, context, fetchToken));
        }
        return Promise.all(requests);
      }, 250),
    [populateIds, populateRows, hasCheckboxes],
  );

  const [metadata, setMetadata] = React.useState<ConsoleMetadata>();
  const [data, setData] = React.useState<{
    consoleRows?: ConsoleRow[];
    consoleIds?: string[];
    consoleSelectedRows?: string[];
  }>({
    consoleRows: undefined,
    consoleIds: undefined,
  });

  // Instead of this custom token thing, we could use something like
  // https://github.com/slorber/react-async-hook in the future.
  // Currently, this debouncing strategy doesn't abort existing requests,
  // it just ignores them.
  const onQueryChange = React.useCallback(
    async (consoleState: ConsoleState, context: object) => {
      const fetchToken = uuidv4();
      currentFetchToken.current = fetchToken;

      await onFetchConsoleRowsDebounced(consoleState, context, fetchToken);
    },
    [onFetchConsoleRowsDebounced],
  );

  return (
    <Console
      data={data}
      metadata={metadata}
      onConsoleStateChange={onQueryChange}
      topFilterLine={topFilterLine}
      tableHeaderSection={tableHeaderSection}
      hasCheckboxes={hasCheckboxes}
      noResultsText={noResultsText}
      banner={banner}
      reset={reset}
      extraToolbarButtons={extraToolbarButtons}
      download={download}
      canDownload={canDownload}
      legacyDownloadPayload={{
        url: legacyDownloadUrl,
        shouldShow: displayFeatures?.has(DisplayFeaturesEnum.LegacyHardwareSpreadsheetDownload) ?? false,
      }}
      clearFiltersOnFirstLoad={clearFiltersOnFirstLoad}
      canSelectDisplayColumns={canSelectDisplayColumns}
      hasUpgradeBanner={hasUpgradeBanner}
    />
  );
};

export default AsyncConsole;
