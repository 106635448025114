import { css } from '@emotion/react';
import { tabletBreakpointRem } from '~/neo-ui/packages/layout/types/breakpoints';
import theme from '~/sp-ui/theme';
import { generatePath } from 'react-router-dom';
import routes from '~/router/types/routes';

export type SubscriptionSummaryCardCtaSection = {
  isFreeSubscription: boolean;
  manageSubscriptionUrl: string;
  productFamily: string;
};

const SubscriptionSummaryCardCtaSection = ({
  isFreeSubscription,
  manageSubscriptionUrl,
  productFamily,
}: SubscriptionSummaryCardCtaSection) => {
  const ctaUrl = isFreeSubscription
    ? generatePath(routes.ACCOUNT_BILLING_UPGRADE.route, { productFamily: productFamily.toLowerCase() })
    : manageSubscriptionUrl;

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
        [`@media (max-width: ${tabletBreakpointRem}rem)`]: { alignItems: 'start' },
      }}
    >
      <a
        href={ctaUrl}
        css={[
          {
            all: 'unset',
            borderRadius: 4,
            ['&:hover:not([disabled])']: {
              cursor: 'pointer',
              textDecoration: 'none',
              color: theme.palette.white,
            },
            ['& .button__content-wrapper']: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          css(theme.typography.buttonSmall, {
            paddingInline: '1rem',
            paddingBlock: '0.5rem',
            ['& .button__content-wrapper__main']: {
              paddingInline: '0.5rem',
            },
          }),
          css({
            backgroundColor: theme.palette.primary1,
            color: theme.palette.white,
            ['&:hover:not([disabled])']: {
              backgroundColor: theme.palette.primary0,
            },
            ['&:focus,&:focus-visible']: {
              outline: `4px solid ${theme.palette.primary1_15}`,
            },
          }),
          { padding: '4px 8px', minWidth: '7rem', maxWidth: '8.25rem' },
        ]}
      >
        {isFreeSubscription ? 'Upgrade Now' : 'Manage your plan'}
      </a>
    </div>
  );
};

export default SubscriptionSummaryCardCtaSection;
