import { useContext } from 'react';
import AutoEmailAvailabilitiesContext from '~/wm/packages/organization/packages/auto-email/context/availabilities/AutoEmailAvailabilitiesContext';

const useAutoEmailAvailabilitiesContext = () => {
  const autoEmailAvailabilitiesContext = useContext(AutoEmailAvailabilitiesContext);
  if (!autoEmailAvailabilitiesContext) {
    throw new Error('Tried to use AutoEmailAvailabilitiesContext outside of an AutoEmailAvailabilitiesProvider');
  }

  return autoEmailAvailabilitiesContext;
};

export default useAutoEmailAvailabilitiesContext;
