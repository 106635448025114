import React from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { autoEmailReportTemplateAvailabilityListGet } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import AutoEmailReportListMutationContext from '~/wm/packages/organization/packages/auto-email-report/context/AutoEmailReportListMutationContext';
import AutoEmailReportListContext from '~/wm/packages/organization/packages/auto-email-report/context/AutoEmailReportListContext';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import { AutoEmailReportTemplateDto } from '@AssetManagementClient/BeastClient/Beast/AutoEmail/Packages/AutoEmailReport/Packages/Template/Dto/Model.gen';
import { autoEmailReportListGet } from '@BeastClient/BeastClientMsp.gen';
import { AutoEmailReportAvailabilityDto, AutoEmailReportDto } from '@BeastClient/Beast/AutoEmail/Packages/AutoEmailReport/Dto/Model.gen';

export type AutoEmailReportListProviderProps = {
  children: React.ReactNode;
};

const AutoEmailReportListProvider = ({ children }: AutoEmailReportListProviderProps) => {
  const { organizationId } = useOrganizationContext();

  const [autoEmailReports, setAutoEmailReports] = React.useState<AutoEmailReportDto[] | undefined>(undefined);

  const [autoEmailReportAvailability, setAutoEmailReportAvailability] = React.useState<AutoEmailReportAvailabilityDto | undefined>(
    undefined,
  );

  const [autoEmailReportTemplateAvailabilities, setAutoEmailReportTemplateAvailabilities] = React.useState<
    AutoEmailReportTemplateDto[] | undefined
  >(undefined);

  const { callApi } = useApi();

  // Get autoEmailReport list
  const reload = React.useCallback(
    async (isRequestActive?: () => boolean) => {
      const autoEmailReportListResponse = await callApi(() => autoEmailReportListGet({ organizationId }));

      const autoEmailReportTemplateAvailabilityResponse = await callApi(() => autoEmailReportTemplateAvailabilityListGet({}));

      if (!autoEmailReportListResponse || !autoEmailReportTemplateAvailabilityResponse) {
        return;
      }

      // if a function is provided to determine whether to propagate
      // a state update (to prevent race conditions), then it will
      // ensure not to update state. otherwise, each call updates state.
      if (!isRequestActive || isRequestActive()) {
        autoEmailReportListResponse.autoEmailReportAvailability.columns =
          autoEmailReportListResponse.autoEmailReportAvailability.columns.sort((a, b) => a.order - b.order);

        setAutoEmailReports(autoEmailReportListResponse.autoEmailReportList);
        setAutoEmailReportAvailability(autoEmailReportListResponse.autoEmailReportAvailability);
        setAutoEmailReportTemplateAvailabilities(autoEmailReportTemplateAvailabilityResponse.autoEmailReportTemplateAvailabilities);
      }
    },
    [callApi, organizationId],
  );

  // Load autoEmailReport list
  React.useEffect(() => {
    reload();
  }, [reload]);

  if (!autoEmailReports || !autoEmailReportAvailability || !autoEmailReportTemplateAvailabilities) {
    return null;
  }

  return (
    <AutoEmailReportListMutationContext.Provider
      value={{
        triggerAutoEmailReportListReload: isRequestActive => reload(isRequestActive),
      }}
    >
      <AutoEmailReportListContext.Provider
        value={{
          autoEmailReports,
          autoEmailReportAvailability,
          autoEmailReportTemplateAvailabilities,
        }}
      >
        {children}
      </AutoEmailReportListContext.Provider>
    </AutoEmailReportListMutationContext.Provider>
  );
};

export default AutoEmailReportListProvider;
