import * as React from 'react';
import Spinner from '~/neo-ui/spinner/Spinner';
import useDisposalOrderInfoContext from '../../../../context/hooks/useDisposalOrderInfoContext';
import { Enum } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Packages/DisposalOrderType/Model/DisposalOrderTypeFactoryNested.gen';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Button from '~/neo-ui/packages/button/Button';
import { css } from '@emotion/react';
import useDisposalOrderPackagePurchase from '~/wm/packages/disposal/packages/order-info/packages/disposal-order-shipment-section/packages/disposal-order-package-module/hooks/useDisposalOrderPackagePurchase';
import useDisposalOrderShippingLabelGet from '~/wm/packages/disposal/packages/order-info/hooks/useDisposalOrderShippingLabelGet';
import useDisposalOrderPackagesLabelPoller from '~/wm/packages/disposal/packages/order-info/hooks/useDisposalOrderPackagesLabelPoller';
import useEmailContext from '~/wm/packages/product/context/hooks/useEmailContext';
import InputNumberStepper from '~/neo-ui/packages/input/packages/input-number-stepper/InputNumberStepper';
import { TrackingServices, useEventTracking } from '~/extensions/packages/tracking/hooks/useEventTracking';
import useFeatureFlagProvider from '~/router/feature-flag-provider/hooks/useFeatureFlagProvider';

type DisposalOrderPackageModuleUspsState = 'waiting' | 'purchasing-labels' | 'purchased' | 'error';

/**
 * Do not use this is a temporary file for the restoring old behaviour when ups is not released
 * use DisposalOrderPackageModule
 */
const DisposalOrderPackageModuleUsps = () => {
  const { 'disposal-carrier-ups': isUpsDisposalCarrierEnabled } = useFeatureFlagProvider();
  const [packageAmount, setPackageAmount] = React.useState(0);

  const { disposalOrderSummary } = useDisposalOrderInfoContext();
  const { supportEmail } = useEmailContext();

  const { pollingState } = useDisposalOrderPackagesLabelPoller(disposalOrderSummary);

  const { downloadDisposalOrderShippingLabel } = useDisposalOrderShippingLabelGet();
  const { packagePurchase, isPurchasingPackage } = useDisposalOrderPackagePurchase();

  const { trackEvent: trackEventProcessor } = useEventTracking(TrackingServices.EventProcessor);

  if (typeof disposalOrderSummary === 'undefined') {
    return <Spinner />;
  }

  // We currently only support self and wa for this component
  if (disposalOrderSummary.orderType !== Enum.ItadSelf && disposalOrderSummary.orderType !== Enum.WorkstationAssurance) {
    throw new Error(`Tried to render this disposal order of type ${disposalOrderSummary.orderType}`);
  }

  const moduleState: DisposalOrderPackageModuleUspsState = disposalOrderSummary.isAllShippingLabelsCreated
    ? 'purchased'
    : disposalOrderSummary.packageCount > 0
    ? 'purchasing-labels'
    : pollingState === 'error'
    ? 'error'
    : 'waiting';

  // Duplicated in backend: 81d49186-0123-4413-8d2a-a1ec381e2d3d
  const packageLimit = 30;
  const isPackageCountInvalid = isNaN(packageAmount) || packageAmount < 1 || packageAmount > packageLimit;

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
      `}
    >
      <Label
        color={'dark-700'}
        bold={true}
        size={'md'}
      >
        Shipping labels
      </Label>
      {moduleState === 'waiting' && (
        <React.Fragment>
          <Label
            color={'dark-900-64'}
            size={'sm'}
            css={css`
              display: flex;
            `}
          >
            Generate up to
            <Label
              size={'sm'}
              bold={true}
              css={css`
                margin-left: 0.25rem;
              `}
            >
              {packageLimit} labels
            </Label>
            . Each package can weigh up to
            <Label
              size={'sm'}
              bold={true}
              css={css`
                margin-left: 0.25rem;
              `}
            >
              {isUpsDisposalCarrierEnabled ? '150' : '70'} lbs
            </Label>
            .
          </Label>
        </React.Fragment>
      )}
      {moduleState === 'purchased' && (
        <Label
          css={css`
            display: flex;
            margin-bottom: 0.625rem;
          `}
          color={'dark-900-64'}
          size={'sm'}
        >
          Labels will be voided if unused within 21 days.
        </Label>
      )}
      {
        {
          waiting: (
            <div
              css={css`
                margin-top: 0.5rem;
              `}
            >
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 0.5rem;
                `}
              >
                <InputNumberStepper
                  value={packageAmount}
                  disabledButtonRemove={packageAmount <= 1}
                  disabledButtonAdd={packageAmount >= packageLimit}
                  onChange={value => {
                    if (isNaN(value)) {
                      return;
                    }

                    setPackageAmount(value);
                  }}
                  onRemoveClick={() => {
                    // Guard against packageAmount going below 1
                    if (packageAmount <= 1) {
                      return;
                    }

                    setPackageAmount(prevState => prevState - 1);
                  }}
                  onAddClick={() => {
                    // Guard against packageAmount exceeding packageLimit
                    if (packageAmount === packageLimit) {
                      return;
                    }

                    setPackageAmount(prevState => prevState + 1);
                  }}
                />
                <Button
                  css={css`
                    margin-left: 0.5rem;
                  `}
                  theme={'secondary'}
                  onClick={() =>
                    packagePurchase({
                      disposalOrderId: disposalOrderSummary?.disposalOrderId,
                      packageCount: packageAmount,
                      packagePurchasePayloads: undefined,
                    })
                  }
                  disabled={isPurchasingPackage || isPackageCountInvalid}
                >
                  Generate labels
                </Button>
              </div>
            </div>
          ),
          'purchasing-labels': (
            <div
              css={css`
                display: flex;
                align-items: center;
                gap: 0.5rem;
              `}
            >
              <Label>Please wait while we generate your labels...</Label>
              <Spinner />
            </div>
          ),
          purchased: (
            <div
              css={css`
                display: flex;
                align-items: center;
                gap: 0.5rem;
              `}
            >
              <Label>
                <b>{disposalOrderSummary.packageCount}</b> shipping label
                {disposalOrderSummary.packageCount === 1 ? '' : 's'} ready to print
              </Label>
              <Button
                iconLeft={'Printer'}
                theme={'secondary'}
                onClick={() => {
                  downloadDisposalOrderShippingLabel(disposalOrderSummary?.disposalOrderId);

                  trackEventProcessor('disposal_shipping_label_downloaded', {
                    disposalOrderId: disposalOrderSummary?.disposalOrderId,
                    scope: typeof disposalOrderSummary.disposalOrderPickupDetails !== 'undefined' ? 'Pickup' : 'Dropoff',
                  });
                }}
              >
                Print...
              </Button>
            </div>
          ),
          error: (
            <div
              css={css`
                display: inline-flex;
                flex-direction: row;
                margin-top: 0.5rem;
              `}
            >
              <Label
                muted={true}
                color={'negative-400'}
              >
                There has been an issue with purchasing your shipping labels for order {disposalOrderSummary.disposalOrderId}. Please
                contact
                <a
                  href={`mailto:${supportEmail}`}
                  target={'_blank'}
                >
                  {' '}
                  {supportEmail}{' '}
                </a>
                for assistance.
              </Label>
            </div>
          ),
        }[moduleState]
      }
    </div>
  );
};

export default DisposalOrderPackageModuleUsps;
