import * as React from 'react';
import { Fragment } from 'react';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import BannerUpgrade from '~/neo-ui/packages/banner/packages/banner-upgrade/BannerUpgrade';
import useFeatureAvailabilitiesContext from '~/wm/packages/feature/context/hooks/useFeatureAvailabilitiesContext';
import FeatureAccessUnlockModal from '~/wm/packages/feature/packages/feature-access-banner/packages/feature-access-unlock-modal/FeatureAccessUnlockModal';
import { CaseEnum as FeatureAccessTypeEnum } from '@SsoClient/BeastClient/Beast/Feature/Packages/Access/Dto/Model/FeatureAccessDtoNested.gen';
import Anchor from '~/neo-ui/packages/anchor/Anchor';
import routes from '~/router/types/routes';
import { css } from '@emotion/react';
import { FeatureAccessKey } from '~/wm/packages/feature/context/FeatureAvailabilitiesContext';

export type FeatureBannerType = 'unlock' | 'upgrade';

type FeatureBannerDetails = {
  title: string;
  description: string;
};

export type FeatureAccessBannerProps = {
  featureKey: FeatureAccessKey;
  unlockBannerDetails: FeatureBannerDetails;
  upgradeBannerDetails: FeatureBannerDetails;
  imageSrc: string;
  upgradeUrl: string;
  isUnlocking: boolean;
  onOrganizationUnlock: (featureKey: string, organizationId: string) => void;
};

const FeatureAccessBanner: React.FunctionComponent<FeatureAccessBannerProps> = ({
  featureKey,
  unlockBannerDetails,
  upgradeBannerDetails,
  imageSrc,
  upgradeUrl,
  isUnlocking,
  onOrganizationUnlock,
}) => {
  const { organizationId: contextOrganizationId, organizationName } = useOrganizationContext();
  const { featureAccessAvailabilities } = useFeatureAvailabilitiesContext();
  const [isDisplayingUnlockModal, setDisplayingUnlockModal] = React.useState(false);

  let bannerType: FeatureBannerType | undefined;

  const featureAccess = typeof featureAccessAvailabilities !== 'undefined' ? featureAccessAvailabilities[featureKey] : undefined;

  // Don't show the banner if we don't have enough information
  if (typeof featureAccess === 'undefined') {
    return null;
  }

  // Is the current organization unlocked show upgrade banner
  if (
    featureAccess.enum === FeatureAccessTypeEnum.LimitedAccess &&
    typeof featureAccess.featureAccessOrganization !== 'undefined' &&
    featureAccess.featureAccessOrganization.organization.organizationId === contextOrganizationId
  ) {
    bannerType = 'upgrade';
  }

  // If the feature has unlocks available show unlock banner and the organization is not unlocked
  if (
    featureAccess.enum === FeatureAccessTypeEnum.LimitedAccess &&
    !(
      typeof featureAccess.featureAccessOrganization !== 'undefined' &&
      featureAccess.featureAccessOrganization.organization.organizationId === contextOrganizationId
    ) &&
    featureAccess.numberOfUnlocks < featureAccess.maxNumberOfUnlocks
  ) {
    bannerType = 'unlock';
  }

  // If the feature has no unlocks available show upgrade banner
  if (
    featureAccess.enum === FeatureAccessTypeEnum.LimitedAccess &&
    !(
      typeof featureAccess.featureAccessOrganization !== 'undefined' &&
      featureAccess.featureAccessOrganization.organization.organizationId === contextOrganizationId
    ) &&
    typeof featureAccess.maxNumberOfUnlocks !== 'undefined' &&
    featureAccess.numberOfUnlocks >= featureAccess.maxNumberOfUnlocks
  ) {
    bannerType = 'upgrade';
  }

  switch (bannerType) {
    case 'unlock':
      return (
        <div>
          <BannerUpgrade
            title={unlockBannerDetails.title}
            description={unlockBannerDetails.description}
            imageSource={imageSrc}
            callToAction={{
              text: 'Unlock for this client',
              onClick: () => setDisplayingUnlockModal(true),
            }}
          />
          <FeatureAccessUnlockModal
            featureKey={featureKey}
            organizationId={contextOrganizationId}
            organizationName={organizationName}
            numberOfUnlocks={featureAccess.enum === FeatureAccessTypeEnum.LimitedAccess ? featureAccess.numberOfUnlocks : 0}
            maxNumberOfUnlocks={featureAccess.enum === FeatureAccessTypeEnum.LimitedAccess ? featureAccess.maxNumberOfUnlocks : 0}
            upgradeUrl={upgradeUrl}
            isOpen={isDisplayingUnlockModal}
            onDismiss={() => {
              setDisplayingUnlockModal(false);
            }}
            isUnlocking={isUnlocking}
            onOrganizationUnlock={onOrganizationUnlock}
          />
        </div>
      );
    case 'upgrade':
      return (
        <BannerUpgrade
          title={upgradeBannerDetails.title}
          description={
            <Fragment>
              {upgradeBannerDetails.description}
              {featureAccess.enum === FeatureAccessTypeEnum.LimitedAccess &&
                typeof featureAccess.featureAccessOrganization !== 'undefined' && (
                  <Fragment>
                    {' '}
                    Your current unlocked client is{' '}
                    <Anchor
                      href={routes.ORGANIZATION_DASHBOARD.build([featureAccess.featureAccessOrganization.organization.organizationId])}
                      css={css`
                        font-size: inherit;
                      `}
                    >
                      {featureAccess.featureAccessOrganization.organization.displayName}.
                    </Anchor>
                  </Fragment>
                )}
            </Fragment>
          }
          imageSource={imageSrc}
          callToAction={{
            text: 'Get started',
            link: upgradeUrl,
          }}
        />
      );
    default:
      return null;
  }
};

export default FeatureAccessBanner;
