import React from 'react';
import { IntegrationPreferences } from '@AssetManagementClient/AssetManagement/Packages/Integration/IntegrationPage/Dto.gen';
import {
  displaySyncPreferences,
  SyncPreferencesInternal,
} from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/sync-preferences/SyncPreferencesInternal';
import LayoutFooter from '~/neo-ui/packages/layout/packages/footer/LayoutFooter';
import { ToolbarControl } from '~/neo-ui/packages/layout/packages/toolbar/Toolbar';
import Button from '~/neo-ui/packages/button/Button';
import { css } from '@emotion/react';
import Box from '~/neo-ui/packages/box/Box';
import SyncFilterSection from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/sync-filters/SyncFilterSection';
import { calculateStage } from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/utils';
import { HeaderTwo } from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/page-layout/HeaderTwo';
import { produce } from 'immer';
import {
  FormSelectedMappings,
  SyncPreferencesData,
} from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/hooks/useSyncPreferencesForm';
import { UseFormReturn } from 'react-hook-form';
import MappingPayloadTypeSection from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/mapping/MappingPayloadTypeSection';

export type SyncPreferencesProps = {
  integrationSetupId: string | undefined;
  integrationPreferences: IntegrationPreferences | undefined;
  deleteControl?: ToolbarControl;
  submitControlDisabled: boolean;

  // object returned from useForm hook, looks like { control, register, watch ...}
  useFormReturn: UseFormReturn<SyncPreferencesData>;
  submitSyncPreferences: (e: React.FormEvent<HTMLFormElement>) => void;
};

export const filterUnassignedOptions = (selectedMappings: FormSelectedMappings) =>
  produce(selectedMappings, (draft: FormSelectedMappings) => {
    const payloadKeys = Object.keys(draft);
    payloadKeys.forEach(payload => {
      const mappingSectionKeys = Object.keys(draft[payload]);
      mappingSectionKeys.forEach(mappingSectionKey => {
        const mappingIds = Object.keys(draft[payload][mappingSectionKey]);
        mappingIds.forEach(mappingId => {
          if (draft[payload][mappingSectionKey][mappingId] === null) {
            delete draft[payload][mappingSectionKey][mappingId];
          }
        });
      });
    });
  }) as { [x: string]: { [x: string]: { [x: string]: string } } };

const SyncPreferences: React.FunctionComponent<SyncPreferencesProps> = ({
  integrationSetupId,
  integrationPreferences,
  deleteControl,
  submitControlDisabled,
  useFormReturn,
  submitSyncPreferences,
}) => {
  const formRef = React.useRef<HTMLFormElement>(null);

  const {
    watch,
    control,
    formState: { isSubmitting },
    register,
    setValue,
    getValues,
    resetField,
  } = useFormReturn;

  const submitControl = {
    expanded: (
      <Button
        onClick={() => {
          formRef.current!.requestSubmit();
        }}
        iconLeft={'Done'}
        disabled={submitControlDisabled}
        theme={submitControlDisabled ? undefined : 'positive'}
        loading={isSubmitting}
        size={'md'}
      >
        Save and sync now
      </Button>
    ),
  };

  if (!integrationPreferences) {
    return <></>;
  }

  const syncPreferencesInternalIsEmpty = Object.values(displaySyncPreferences(integrationPreferences)).every(value => !value);

  const stage = calculateStage(integrationSetupId);

  return syncPreferencesInternalIsEmpty ? (
    <form
      css={css`
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
        padding-bottom: 1.5rem;
      `}
      onSubmit={submitSyncPreferences}
      ref={formRef}
    >
      <LayoutFooter
        leftControls={[submitControl]}
        rightControls={typeof deleteControl === 'undefined' ? [] : [deleteControl]}
      />
    </form>
  ) : (
    <form
      css={css`
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
        padding-bottom: 1.5rem;
      `}
      onSubmit={submitSyncPreferences}
      ref={formRef}
    >
      <section
        css={css`
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        `}
      >
        <HeaderTwo>Sync data</HeaderTwo>
        <Box
          css={css`
            padding: 1rem;
          `}
          borderRadius={'radius400'}
        >
          <SyncPreferencesInternal
            integrationPreferences={integrationPreferences}
            register={register}
            watch={watch}
          />
        </Box>
        {stage !== 'newIntegration' &&
          Object.entries(integrationPreferences.filterDisplaySettings).flatMap(([payloadType, payloadTypeInfo]) =>
            Object.entries(payloadTypeInfo).map(([sectionName, sectionInformation]) => (
              <SyncFilterSection
                key={`${payloadType}.${sectionName}`}
                sectionInformation={sectionInformation}
                payloadType={payloadType}
                sectionName={sectionName}
                register={register}
              />
            )),
          )}
      </section>
      {stage !== 'newIntegration' && Object.entries(integrationPreferences.valueMappingDisplaySettings).length > 0 && (
        <section>
          <HeaderTwo>Configure fields</HeaderTwo>
          {Object.entries(integrationPreferences.valueMappingDisplaySettings).map(([payloadType, payloadTypeInfo]) => (
            <MappingPayloadTypeSection
              key={payloadType}
              payloadType={payloadType}
              payloadSectionInfo={payloadTypeInfo}
              control={control}
              watch={watch}
              setValue={setValue}
              getValues={getValues}
              resetField={resetField}
            />
          ))}
        </section>
      )}
      <LayoutFooter
        leftControls={[submitControl]}
        rightControls={typeof deleteControl === 'undefined' ? [] : [deleteControl]}
      />
    </form>
  );
};
export default SyncPreferences;
