import Button from '~/neo-ui/packages/button/Button';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import React from 'react';
import {
  AutoEmailRecipientAddFormData,
  AutoEmailRecipientAddPayload,
} from '~/wm/packages/organization/packages/auto-email/packages/auto-email-recipients/packages/auto-email-recipient-add-form/AutoEmailRecipientAddForm';
import { AutoEmailRecipient } from '~/wm/packages/organization/packages/auto-email/packages/auto-email-recipients/AutoEmailRecipients';
import AutoEmailSuggestedRecipientInput from '~/wm/packages/organization/packages/auto-email/packages/auto-email-recipients/packages/auto-email-suggested-recipient-input/AutoEmailSuggestedRecipientInput';

const nameFieldKey: FieldKeyExpression<AutoEmailRecipientAddFormData> = values => values.name;
const emailFieldKey: FieldKeyExpression<AutoEmailRecipientAddFormData> = values => values.email;

export type AutoEmailRecipientAddProps = {
  existingRecipients: AutoEmailRecipient[];
  isAddingRecipient: boolean;
  onAddRecipient: (addRecipientPayload: AutoEmailRecipientAddPayload) => void;
};

const AutoEmailRecipientAdd = ({ existingRecipients, isAddingRecipient, onAddRecipient }: AutoEmailRecipientAddProps) => {
  const { submitForm, isSubmitting } = useFormContext<AutoEmailRecipientAddFormData>();

  return (
    <tr>
      <td>
        <AutoEmailSuggestedRecipientInput
          autofillKey={'fullName'}
          fieldKey={nameFieldKey}
          placeholder={"Recipient's Name..."}
          existingRecipients={existingRecipients}
          onResultSelected={result => {
            onAddRecipient({
              firstName: result.fullName.split(' ')[0],
              lastName: result.fullName.split(' ')[1],
              email: result.email,
            });
          }}
        />
      </td>
      <td>
        <AutoEmailSuggestedRecipientInput
          autofillKey={'email'}
          fieldKey={emailFieldKey}
          placeholder={'Email...'}
          existingRecipients={existingRecipients}
          onResultSelected={result => {
            onAddRecipient({
              firstName: result.fullName.split(' ')[0],
              lastName: result.fullName.split(' ')[1],
              email: result.email,
            });
          }}
        />
      </td>
      <td>
        <Button
          size={'sm'}
          iconLeft={'Add'}
          theme={'primary'}
          loading={isAddingRecipient || isSubmitting}
          onClick={() => submitForm()}
        />
      </td>
    </tr>
  );
};

export default AutoEmailRecipientAdd;
