import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Spinner from '~/neo-ui/spinner/Spinner';
import routes from '~/router/types/routes';
import useBillingInfo from '~/wm/packages/subscription/packages/billing/hooks/useBillingInfo';
import usePaymentSummarySubscriptionSummaryGet from '~/wm/packages/subscription/packages/product-subscription-page/hooks/usePaymentSummarySubscriptionSummaryGet';
import ContactSalesToManagePlanBanner from '~/wm/packages/subscription/packages/product-subscription-page/packages/subscription-summary-banner/ContactSalesToManagePlanBanner';
import ExploreProductsBanner from '~/wm/packages/subscription/packages/product-subscription-page/packages/subscription-summary-banner/ExploreProductsBanner';
import SubscriptionSummaryCard from '~/wm/packages/subscription/packages/product-subscription-page/packages/subscription-summary-card/SubscriptionSummaryCard';

const ProductSubscriptionPageV2 = () => {
  const { subscriptionSummaries, productSquareIcons } = usePaymentSummarySubscriptionSummaryGet();
  const { billingInfo } = useBillingInfo();
  if (!subscriptionSummaries || !productSquareIcons || !billingInfo) {
    return <Spinner />;
  }
  const isFreeAccount = subscriptionSummaries.every(summary => summary.isFreeSubscription);
  const allowSelfServeSubscriptionUpdate = subscriptionSummaries.every(summary => summary.allowSelfServeUpdate);

  const manageSubscriptionBaseUrl = isFreeAccount ? routes.ACCOUNT_UPGRADE.route : routes.ACCOUNT_GROW.route;

  return (
    <div>
      {/* Subscription summary section */}
      <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'left', marginBottom: '2rem' }}>
        <div css={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
          <Header size={2}>Subscription</Header>
          <a
            href={routes.ACCOUNT_BILLING_EDIT.route}
            css={{ fontSize: '1rem' }}
          >
            Edit billing info
          </a>
        </div>
        {subscriptionSummaries.map((subscriptionSummary, index) => (
          <SubscriptionSummaryCard
            subscriptionSummary={subscriptionSummary}
            allowSelfServeSubscriptionUpdate={allowSelfServeSubscriptionUpdate}
            key={index}
          />
        ))}
        {!isFreeAccount && (
          <p css={{ color: colorToCode('dark-050') }}>*Price includes discounts before applicable taxes. Plan subject to change.</p>
        )}
      </div>

      {allowSelfServeSubscriptionUpdate && (
        <ExploreProductsBanner
          productSquareIcons={productSquareIcons}
          manageSubscriptionBaseUrl={manageSubscriptionBaseUrl}
        />
      )}
      {!allowSelfServeSubscriptionUpdate && <ContactSalesToManagePlanBanner />}
    </div>
  );
};

export default ProductSubscriptionPageV2;
