import * as React from 'react';
import { ReactNode } from 'react';
import Form from '~/neo-ui/packages/form/Form';
import autoEmailRecipientAddFormValidationSchema from '~/wm/packages/organization/packages/auto-email/packages/auto-email-recipients/packages/auto-email-recipient-add-form/validation/autoEmailRecipientAddFormValidationSchema';

export type AutoEmailRecipientAddPayload = {
  firstName: string;
  lastName: string | undefined;
  email: string;
};

export type AutoEmailRecipientAddFormData = {
  name: string;
  email: string;
};

export type AutoEmailRecipientAddFormProps = {
  onAutoEmailRecipientAdd: (addRecipientPayload: AutoEmailRecipientAddPayload) => void;
  children: ReactNode;
};

const AutoEmailRecipientAddForm = ({ onAutoEmailRecipientAdd, children }: AutoEmailRecipientAddFormProps) => {
  const onSubmit = React.useCallback(
    async (data: AutoEmailRecipientAddFormData) => {
      onAutoEmailRecipientAdd({
        firstName: data.name.split(' ')[0],
        lastName: data.name.split(' ')[1],
        email: data.email,
      });
    },
    [onAutoEmailRecipientAdd],
  );

  const defaultFormData = React.useMemo<AutoEmailRecipientAddFormData>(
    () => ({
      name: '',
      email: '',
    }),
    [],
  );

  return (
    <Form
      submitMethod={'manual'}
      hideSubmissionButton={true}
      hideSubmitStatusIndicator={true}
      defaultFormData={defaultFormData}
      validationSchema={autoEmailRecipientAddFormValidationSchema}
      onSubmit={onSubmit}
    >
      {children}
    </Form>
  );
};

export default AutoEmailRecipientAddForm;
