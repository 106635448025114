import React from 'react';
import Form from '~/neo-ui/packages/form/Form';
import useDisposalOrderPackagePurchase from '~/wm/packages/disposal/packages/order-info/packages/disposal-order-shipment-section/packages/disposal-order-package-module/hooks/useDisposalOrderPackagePurchase';
import disposalOrderShippingLabelFormValidationSchemaUps from '~/wm/packages/disposal/packages/order-info/packages/disposal-order-shipment-section/packages/disposal-order-package-module/packages/disposal-order-shipping-label-purchase-form/validation/disposalOrderShippingLabelFormValidationSchemaUps';
import disposalOrderShippingLabelFormValidationSchemaUsps from '~/wm/packages/disposal/packages/order-info/packages/disposal-order-shipment-section/packages/disposal-order-package-module/packages/disposal-order-shipping-label-purchase-form/validation/disposalOrderShippingLabelFormValidationSchemaUsps';
import useFeatureFlagProvider from '~/router/feature-flag-provider/hooks/useFeatureFlagProvider';

export type DisposalOrderShippingLabelPurchaseFormData = {
  packageWeightPoundsList: number[];
};

export type DisposalOrderShippingLabelPurchaseFormProps = {
  disposalOrderId: string;
  children: React.ReactNode;
};

const DisposalOrderShippingLabelPurchaseForm = ({ disposalOrderId, children }: DisposalOrderShippingLabelPurchaseFormProps) => {
  const { packagePurchase } = useDisposalOrderPackagePurchase();
  const { 'disposal-carrier-ups': enabledFeatureFlagUps } = useFeatureFlagProvider();

  const onSubmit = React.useCallback(
    async (data: DisposalOrderShippingLabelPurchaseFormData) => {
      packagePurchase({
        disposalOrderId,
        packageCount: undefined,
        packagePurchasePayloads: data.packageWeightPoundsList.map(weightPounds => ({ weightPounds: Number(weightPounds) })),
      });
    },
    [disposalOrderId, packagePurchase],
  );

  const toFormData = React.useCallback((): DisposalOrderShippingLabelPurchaseFormData => ({ packageWeightPoundsList: [] }), []);

  const defaultFormData = React.useMemo(() => toFormData(), [toFormData]);

  return (
    <Form
      submitMethod={'manual'}
      defaultFormData={defaultFormData}
      onSubmit={onSubmit}
      disableSubmitOnEnter={true}
      disableOverwriteDefaultFormDataOnSave={true}
      hideSubmissionButton={true}
      validationSchema={
        enabledFeatureFlagUps ? disposalOrderShippingLabelFormValidationSchemaUps : disposalOrderShippingLabelFormValidationSchemaUsps
      }
    >
      {children}
    </Form>
  );
};

export default DisposalOrderShippingLabelPurchaseForm;
