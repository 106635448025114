import AutoEmailReportCreateButton from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-create/packages/auto-email-report-create-button/AutoEmailReportCreateButton';
import AutoEmailReportListProvider from '~/wm/packages/organization/packages/auto-email-report/context/AutoEmailReportListProvider';
import AutoEmailReportSection from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-section/AutoEmailReportSection';
import AutoEmailReportUrlProvider from '~/wm/packages/organization/packages/auto-email-report/context/AutoEmailReportUrlProvider';
import * as React from 'react';
import { css } from '@emotion/react';
import LayoutFooter from '~/neo-ui/packages/layout/packages/footer/LayoutFooter';

export type AutoEmailReportPageProps = {
  autoEmailReportEmailTemplateEditUrl: string;
  isFreeEdition: boolean;
};

const AutoEmailReportPage = ({ autoEmailReportEmailTemplateEditUrl, isFreeEdition }: AutoEmailReportPageProps) => {
  const ref = React.useRef<HTMLDivElement>(null);

  return isFreeEdition ? null : (
    <AutoEmailReportUrlProvider autoEmailReportEmailTemplateEditUrl={autoEmailReportEmailTemplateEditUrl}>
      <AutoEmailReportListProvider>
        <div
          ref={ref}
          css={css`
            scroll-margin-top: 10rem;
          `}
        >
          <AutoEmailReportSection />
        </div>
        <LayoutFooter
          leftControls={[
            {
              expanded: <AutoEmailReportCreateButton scrollToRef={ref} />,
            },
          ]}
        />
      </AutoEmailReportListProvider>
    </AutoEmailReportUrlProvider>
  );
};

export default AutoEmailReportPage;
