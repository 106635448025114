import { DmiTrendDto } from '@AssetManagementClient/AssetManagement/Packages/Dmi/Controller/Dto.gen';
import { DmiScoreDto } from '@AssetManagementClient/AssetManagement/Packages/Dmi/Model.gen';
import {
  assetHardwareStatsDeviceTypeScopedGet,
  dmiScoreScope,
  dmiTrendGet,
  strategyDashboardGet,
} from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { useCallback, useEffect, useState } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { FrontendScope } from '@AssetManagementClient/Scoping/Model.gen';
import { Response as AssetTypeCountsResponse } from '@AssetManagementClient/AssetManagement/Packages/Asset/Hardware/Packages/Stats/Controller/ScopedHardwareTypeStatsGetControllerNested.gen';
import { InsightDashboardDto } from '@AssetManagementClient/AssetManagement/Packages/Strategy/Packages/Insights/Model.gen';
import useFeatureAvailabilitiesContext from '~/wm/packages/feature/context/hooks/useFeatureAvailabilitiesContext';
import { Enum as AssetManagementClientScopingModelScopeNestedEnum } from '@AssetManagementClient/Scoping/Model/ScopeNested.gen';

export type UseStrategyDashboard = {
  presentationalInsightDashboard: InsightDashboardDto | undefined;
  presentationalScore: DmiScoreDto | undefined;
  assetTypeCounts: AssetTypeCountsResponse | undefined;
  dateToDmiScore: { [key in string]: DmiTrendDto } | undefined;
  reload: () => void;
};

export const useStrategyDashboard = (scope: FrontendScope): UseStrategyDashboard => {
  const [insightDashboard, setInsightDashboard] = useState<InsightDashboardDto | undefined>(undefined);
  const [dmiScore, setDmiScore] = useState<DmiScoreDto | undefined>(undefined);
  const [assetTypeCounts, setAssetTypeCounts] = useState<AssetTypeCountsResponse | undefined>(undefined);

  const [dateToDmiScore, setDateToDmiScore] = useState<{ [key in string]: DmiTrendDto } | undefined>(undefined);

  const { hasFeatureAccessOrganization } = useFeatureAvailabilitiesContext();

  /**
   * Show dmi if essential is not enabled or if the user has access to dmi in Organization scope
   * All other scopes have access to dmi
   */
  const hasFeatureAccessDmi =
    scope.type === AssetManagementClientScopingModelScopeNestedEnum.Organization
      ? hasFeatureAccessOrganization('dmi', scope.organizationId)
      : true;

  const { callApi } = useApi();

  const reload = useCallback(async () => {
    const dashboardResponse = await callApi(() =>
      strategyDashboardGet({
        frontendScope: scope,
      }),
    );

    setInsightDashboard(dashboardResponse?.dashboard);
  }, [callApi, scope]);

  useEffect(() => {
    reload();
  }, [reload]);

  useEffect(() => {
    if (!hasFeatureAccessDmi) {
      return;
    }

    (async () => {
      const dmiTrendGetRequest = callApi(() =>
        dmiTrendGet({
          scope,
        }),
      );
      const dmiScoreRequest = callApi(() =>
        dmiScoreScope({
          scope,
        }),
      );
      const assetTypeCountsRequest = callApi(() =>
        assetHardwareStatsDeviceTypeScopedGet({
          frontendScope: scope,
        }),
      );

      const [dmiScoreResponse, assetTypeCountsResponse, dmiTrendGetResponse] = await Promise.all([
        dmiScoreRequest,
        assetTypeCountsRequest,
        dmiTrendGetRequest,
      ]);

      if (!dmiScoreResponse || !assetTypeCountsResponse || !dmiTrendGetResponse) {
        return;
      }

      setDmiScore(dmiScoreResponse?.score);
      setAssetTypeCounts(assetTypeCountsResponse);
      setDateToDmiScore(dmiTrendGetResponse.trendData);
    })();
  }, [scope, callApi, hasFeatureAccessDmi]);

  return {
    presentationalInsightDashboard: insightDashboard,
    presentationalScore: dmiScore,
    dateToDmiScore,
    assetTypeCounts,
    reload,
  };
};
