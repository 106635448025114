import pluralize from 'pluralize';
import React from 'react';
import { ValueMappingDisplaySettingsDto } from '@AssetManagementClient/AssetManagement/Packages/Integration/IntegrationPage/Dto.gen';

const stringSeparator = (totalItems: number, index: number) => {
  const secondToLastIndex = totalItems - 2;
  if (index < secondToLastIndex) {
    return ', ';
  }
  if (index === secondToLastIndex) {
    return ' and ';
  } else {
    return '';
  }
};

type InfoUnit = { label: string; unassignedCount: number; autoMappedCount: number };

const informationSection = (
  infoArray: InfoUnit[],
  valueAccessor: (infoUnit: InfoUnit) => number,
  isOrWas: 'is' | 'was',
  endText: string,
): React.ReactNode => (
  <>
    {infoArray.map((x, i, a) => (
      <React.Fragment key={x.label}>
        <strong key={x.label}>{`${valueAccessor(x)} ${pluralize(x.label, valueAccessor(x)).toLowerCase()}`}</strong>
        {stringSeparator(a.length, i)}
      </React.Fragment>
    ))}{' '}
    {pluralize(
      isOrWas,
      infoArray.reduce((acc, x) => Math.abs(valueAccessor(x)) + acc, 0),
    )}{' '}
    {endText}.
  </>
);

type MappingInformationTextProps = {
  mappingSelectionsByName: { [p: string]: { [p: string]: string | null } } | undefined;
  displayInformationByName: { [p: string]: ValueMappingDisplaySettingsDto } | undefined;
};

export const MappingInformationText: React.FunctionComponent<MappingInformationTextProps> = ({
  mappingSelectionsByName,
  displayInformationByName,
}) => {
  if (mappingSelectionsByName === undefined || displayInformationByName === undefined) {
    return <React.Fragment />;
  }

  const infoArray = Object.values(displayInformationByName).reduce((acc, sectionDisplayInformation) => {
    const sectionName = sectionDisplayInformation.name;
    const currentSectionsSelections = mappingSelectionsByName[sectionName];
    if (currentSectionsSelections === undefined || sectionDisplayInformation === undefined) {
      return acc;
    }
    return [
      ...acc,
      {
        label: sectionDisplayInformation.integrationsLabel,
        unassignedCount: sectionDisplayInformation.mapFromOptions.length - Object.values(currentSectionsSelections).filter(x => x).length,
        autoMappedCount: Object.entries(currentSectionsSelections).filter(([mappedFrom, mappedTo]: [string, string | null]) => {
          const selectionPayload = sectionDisplayInformation.selectedMappings[mappedFrom];
          return selectionPayload?.autoMapped && selectionPayload?.selectedValue === mappedTo;
        }).length,
      },
    ];
  }, [] as InfoUnit[]);

  return (
    <span>
      {informationSection(infoArray, x => x.autoMappedCount, 'was', 'mapped automatically')}{' '}
      {informationSection(infoArray, x => x.unassignedCount, 'is', 'unassigned')}
    </span>
  );
};
