import { useMemo } from 'react';
import {
  FieldValueMapDisplay,
  ValueMappingDisplaySettingsDto,
} from '@AssetManagementClient/AssetManagement/Packages/Integration/IntegrationPage/Dto.gen';

export type UseMappingDataProcessingProps = {
  payloadType: string;
  payloadSectionsDictionary: { [key in string]: ValueMappingDisplaySettingsDto };
};

export type SelectedMappingsKey = `selectedMappings.${string}.${string}.${string}`;

export type SelectionInfo = {
  isChildSelected: boolean;
  parentMappingKey: SelectedMappingsKey | null;
};

const useMappingDataProcessing = ({ payloadType, payloadSectionsDictionary }: UseMappingDataProcessingProps) => {
  // To help sort out the cross-section mapping keys and selection logic
  const formKeyToSelectionInfo = useMemo(() => {
    const optionIdToSelectionInfo = new Map<SelectedMappingsKey, SelectionInfo>();
    Object.values(payloadSectionsDictionary).forEach(sectionInformation => {
      const sectionId = sectionInformation.name;
      sectionInformation.mapFromOptions.forEach((mapFromOption: FieldValueMapDisplay) => {
        const hasByChildKey =
          payloadSectionsDictionary[sectionId].selectedMappings[mapFromOption.id]?.selectedValue ===
          payloadSectionsDictionary[sectionId].byChildMapToOption;
        const childSectionKey = mapFromOption.childSectionKey ?? '';

        const isChildOptionSelected =
          (mapFromOption?.childOptions &&
            mapFromOption.childOptions.some(
              (childOption: FieldValueMapDisplay) =>
                payloadSectionsDictionary[childSectionKey]?.selectedMappings[childOption.id] !== undefined,
            )) ??
          false;

        const mappingKey = `selectedMappings.${payloadType}.${sectionId}.${mapFromOption.id}` as const;

        mapFromOption.childOptions?.forEach((childOption: FieldValueMapDisplay) => {
          if (childOption.id !== undefined && mapFromOption.childSectionKey !== undefined) {
            const childMappingKey = `selectedMappings.${payloadType}.${childSectionKey}.${childOption.id}` as const;
            optionIdToSelectionInfo.set(childMappingKey, {
              isChildSelected: false,
              parentMappingKey: mappingKey,
            });
          }
        });

        optionIdToSelectionInfo.set(mappingKey, {
          isChildSelected: hasByChildKey && isChildOptionSelected,
          parentMappingKey: null,
        });
      });
    });
    return optionIdToSelectionInfo;
  }, [payloadType, payloadSectionsDictionary]);

  return { formKeyToSelectionInfo };
};
export default useMappingDataProcessing;
