import { useFormikContext } from 'formik';
import { useContext } from 'react';
import { FormContextInternal } from '~/neo-ui/packages/form/context/FormContextInternal';
import { FieldKeyExpression, resolveFieldKey } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { NoInfer } from '~/extensions/packages/types/noInfer';

export const useFormContext = <T, TResponse = unknown>() => {
  const formik = useFormikContext<T>();
  const formInternal = useContext(FormContextInternal);

  if (!formInternal) {
    throw new Error('Called useFormContext outside of form context');
  }

  return {
    formData: formik.values,
    dirty: formik.dirty,
    submitForm: formik.submitForm,
    isSubmitting: formik.isSubmitting,
    setSubmitting: formik.setSubmitting,
    submitCount: formik.submitCount,
    removeFormInput: <U = never>(fieldKey: FieldKeyExpression<T>) => formik.unregisterField(resolveFieldKey(fieldKey)),
    isValid: formik.isValid,
    setFormData: (formData: T) => formik.setValues(formData),
    getFormInput: <U = never>(fieldKey: FieldKeyExpression<T>) => formik.getFieldMeta<NoInfer<U>>(resolveFieldKey(fieldKey)),
    setFormInput: <U = never>(fieldKey: FieldKeyExpression<T>, value: NoInfer<U>) =>
      formik.setFieldValue(resolveFieldKey(fieldKey), value, true),
    setFieldTouched: (fieldKey: FieldKeyExpression<T>, isTouched: boolean) => formik.setFieldTouched(resolveFieldKey(fieldKey), isTouched),
    addBeforeSubmitListener: (onBeforeSubmit: (formData: T) => Promise<void>) => formInternal.addBeforeSubmitListener(onBeforeSubmit),
    addAfterSubmitListener: (onAfterSubmit: (formData: T, formResponse: TResponse) => Promise<void>) =>
      formInternal.addAfterSubmitListener(onAfterSubmit),
    resetForm: () => formik.resetForm(),
    errors: formik.errors,
    setFieldError: formik.setFieldError,
    touched: Object.keys(formik.touched).length !== 0,
  };
};
