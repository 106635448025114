import { css } from '@emotion/react';
import ToggleSwitch from '~/neo-ui/packages/toggle/packages/toggle-switch/ToggleSwitch';
import { Fragment, useState } from 'react';
import Dropdown from '~/neo-ui/packages/dropdown/Dropdown';
import useAutoEmailAvailabilitiesContext from '~/wm/packages/organization/packages/auto-email/context/hooks/availabilities/useAutoEmailAvailabilitiesContext';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Icon from '~/neo-ui/packages/icon/Icon';
import { formatDateFriendly } from '~/extensions/packages/date/formatDate';
import { parseISO } from 'date-fns';

export type AutoEmailControlsProps = {
  // Settings
  isAutoEmailEnabled: boolean;
  frequencyEnum: string;
  hasRecipients: boolean;
  nextOccurrence: string | undefined;
  // Emitters
  onEnablementChange: (isAutoEmailEnabled: boolean) => void;
  onFrequencyChange: (frequencyEnum: string) => void;
  // Extra
  isUpsertingSettings: boolean;
  disableEnablementToggle?: boolean;
};

const AutoEmailControls = ({
  isAutoEmailEnabled,
  frequencyEnum,
  hasRecipients,
  nextOccurrence,
  onEnablementChange,
  onFrequencyChange,
  isUpsertingSettings,
  disableEnablementToggle = false,
}: AutoEmailControlsProps) => {
  const [isEnabled, setIsEnabled] = useState(isAutoEmailEnabled);
  const { emailFrequencies } = useAutoEmailAvailabilitiesContext();

  const selectedReportFrequency = {
    label: emailFrequencies.find(frequency => frequency.id === frequencyEnum)!.labelDisplay,
    value: emailFrequencies.find(frequency => frequency.id === frequencyEnum)!.id,
  };

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 2.25rem;
      `}
    >
      <ToggleSwitch
        onChange={isScheduled => {
          setIsEnabled(() => isScheduled);
          onEnablementChange(isScheduled);
        }}
        checked={isEnabled && hasRecipients}
        showLabel={true}
        theme={'positive'}
        disabled={!hasRecipients || isUpsertingSettings || disableEnablementToggle}
      />
      <Dropdown
        options={emailFrequencies.map(frequency => ({
          label: frequency.labelDisplay,
          value: frequency.id,
        }))}
        selectedOption={selectedReportFrequency}
        onOptionSelected={option => {
          onFrequencyChange(option.value);
        }}
      />
      <Label
        muted={true}
        css={css`
          display: flex;
          align-items: center;
          gap: 0.5rem;
        `}
      >
        {!hasRecipients
          ? 'To get started, add some recipients below.'
          : typeof nextOccurrence !== 'undefined' && (
              <Fragment>
                <Icon
                  icon={'Calendar'}
                  color={'dark-900-32'}
                />{' '}
                Next report will send on {formatDateFriendly(parseISO(nextOccurrence))}
              </Fragment>
            )}
      </Label>
    </div>
  );
};

export default AutoEmailControls;
