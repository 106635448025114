import { css } from '@emotion/react';
import Toolbar from '~/neo-ui/packages/layout/packages/toolbar/Toolbar';
import LayoutBar from '~/neo-ui/packages/layout/packages/bar/LayoutBar';
import LayoutHeaderFooterProps from '~/neo-ui/packages/layout/types/LayoutHeaderFooterProps';
import { enceladusMainBodyPaddingRem } from '~/neo-ui/packages/layout/packages/layout-enceladus/packages/enceladus-body/EnceladusBody';
import useLayoutHeaderContext from '~/neo-ui/packages/layout/packages/layout-enceladus/context/hooks/useLayoutHeaderContext';

export type EnceladusBodyLayoutHeaderProps = LayoutHeaderFooterProps;

const EnceladusBodyLayoutHeader = () => {
  const { layoutHeaderProps } = useLayoutHeaderContext();

  return (
    <div
      className={layoutHeaderProps?.className}
      css={css`
        position: sticky;
        z-index: 1040;

        margin: -${enceladusMainBodyPaddingRem}rem -${enceladusMainBodyPaddingRem}rem 1.25rem -${enceladusMainBodyPaddingRem}rem;
        top: -${enceladusMainBodyPaddingRem}rem;
      `}
    >
      {(layoutHeaderProps?.leftControls || layoutHeaderProps?.rightControls) && (
        <Toolbar
          css={css`
            border-top: none;
          `}
          leftControls={layoutHeaderProps?.leftControls}
          rightControls={layoutHeaderProps?.rightControls}
        />
      )}
      {layoutHeaderProps?.layoutBarComponent && (
        <LayoutBar
          css={css`
            border-top: none;
          `}
        >
          {layoutHeaderProps?.layoutBarComponent}
        </LayoutBar>
      )}
    </div>
  );
};

export default EnceladusBodyLayoutHeader;
