// __      ___   ___ _  _ ___ _  _  ___ _
// \ \    / /_\ | _ \ \| |_ _| \| |/ __| |
//  \ \/\/ / _ \|   / .` || || .` | (_ |_|
//   \_/\_/_/ \_\_|_\_|\_|___|_|\_|\___(_)
// 
//  This file was auto generated
//  Any changes made here *will* be overwritten, so don't do it
//  You. Have. Been. Warned.


/**
 * Be wary modifying, renaming or removing any of these enum values.
 * 
 * - DB references: These enums are referenced in the DB, by string value AND by int value. If any of these values change (string or int), data integrity problems can arise.
 * * DB references could be in JSON and changing the values below could cause runtime failure due to deserialization
 * * E.g., EventTrace, KPI, IntegrationSetup, IssueElement, IssueEntry
 * - PHP references: Generating client may not automatically catch all references via inspection failure.
 */
export enum Enum
{
  ConnectWise = "ConnectWise",
  Autotask = "Autotask",
  Kaseya = "Kaseya",
  LabTech = "LabTech",
  NCentral = "NCentral",
  MAXfocus = "MAXfocus",
  LogMeIn = "LogMeIn",
  AEM = "AEM",
  NinjaRMM = "NinjaRMM",
  Continuum = "Continuum",
  Panorama9 = "Panorama9",
  ITGlue = "ITGlue",
  Atera = "Atera",
  BarracudaMSPManagedWorkplaceRMM = "BarracudaMSPManagedWorkplaceRMM",
  KaseyaBms = "KaseyaBms",
  PulsewayRmm = "PulsewayRmm",
  ServiceNow = "ServiceNow",
  Watchman = "Watchman",
  MerakiSm = "MerakiSm",
  JamfPro = "JamfPro",
  Addigy = "Addigy",
  Naverisk = "Naverisk",
  ManageEngine = "ManageEngine",
  RgSystem = "RgSystem",
  WarrantyMaster = "WarrantyMaster",
  Meraki = "Meraki",
  Freshservice = "Freshservice",
  SyncroMsp = "SyncroMsp",
  SonicWall = "SonicWall",
  CiscoSmartNet = "CiscoSmartNet",
  Auvik = "Auvik",
  CommitCRM = "CommitCRM",
  BarracudaCloudControl = "BarracudaCloudControl",
  DattoBCDR = "DattoBCDR",
  Vorex = "Vorex",
  PulsewayPsa = "PulsewayPsa",
  ConnectWiseManage = "ConnectWiseManage",
  AutotaskPSA = "AutotaskPSA",
  Passportal = "Passportal",
  SonicWallV2 = "SonicWallV2",
  RepairShopr = "RepairShopr",
  Bluetrait = "Bluetrait",
  MicrosoftEndpointManagerSingleTenant = "MicrosoftEndpointManagerSingleTenant",
  MicrosoftEndpointManagerMultiTenant = "MicrosoftEndpointManagerMultiTenant",
  HaloPsa = "HaloPsa",
  HaloItsm = "HaloItsm",
  BackupRadar = "BackupRadar",
  Fortinet = "Fortinet",
  ConnectWiseRmm = "ConnectWiseRmm",
  AutomateRest = "AutomateRest",
  Hudu = "Hudu",
  UbiquitiCloudPortal = "UbiquitiCloudPortal",
  BreachSecureNow = "BreachSecureNow",
  KaseyaVsax = "KaseyaVsax",
  SuperOps = "SuperOps",
  MicrosoftEndpointManagerMultiTenantOAuth = "MicrosoftEndpointManagerMultiTenantOAuth",
  NCentralRest = "NCentralRest",
  Microsoft365SingleTenant = "Microsoft365SingleTenant",
  Microsoft365MultiTenantOauth = "Microsoft365MultiTenantOauth",
  CiscoSmartNetTotalCare = "CiscoSmartNetTotalCare",
  NinjaOneOAuth = "NinjaOneOAuth",
}


