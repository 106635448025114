import {
  InsightCategoryDto,
  InsightDashboardDto,
} from '@AssetManagementClient/AssetManagement/Packages/Strategy/Packages/Insights/Model.gen';
import { DetailLevel } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/Category/Model/InsightCategoryNested.gen';
import { Enum } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/Insights/Model/Dashboard/PresentationalInsightStateNested.gen';
import { css } from '@emotion/react';
import { Fragment, FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { Orientation } from '~/legacy-ui/packages/list/List';
import { Styleable } from '~/neo-ui/model/capacity';
import StrategyInsightContainer from '~/wm/packages/strategy/packages/strategy-dashboard/packages/insight-container/StrategyInsightContainer';
import StrategyInsightDashboardItem from '~/wm/packages/strategy/packages/strategy-dashboard/packages/insight-dashboard/insight-dashboard-item/large-tile/StrategyInsightDashboardItem';
import StrategyInsightDashboardItemSmall from '~/wm/packages/strategy/packages/strategy-dashboard/packages/insight-dashboard/insight-dashboard-item/small-tile/StrategyInsightDashboardItemSmall';
import getAssetConsoleUrl from '~/wm/packages/strategy/packages/insight-console/getAssetConsoleUrl';
import { FrontendScope } from '@AssetManagementClient/Scoping/Model.gen';
import Box from '~/neo-ui/packages/box/Box';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import InsightCreateButton from '~/wm/packages/strategy/packages/insight/packages/insight-create-button/InsightCreateButton';
import { useStrategyDashboard } from '~/wm/packages/strategy/packages/strategy-dashboard/hooks/useStrategyDashboard';
import AnimationHeight from '~/neo-ui/packages/animation/packages/animation-height/AnimationHeight';
import Icon from '~/neo-ui/packages/icon/Icon';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';
import routes from '~/router/types/routes';
import BadgeMarketingPro from '~/neo-ui/packages/badge/packages/badge-marketing/packages/badge-marketing-pro/BadgeMarketingPro';
import useFeatureFlagProvider from '~/router/feature-flag-provider/hooks/useFeatureFlagProvider';
import useFeatureAvailabilitiesContext from '~/wm/packages/feature/context/hooks/useFeatureAvailabilitiesContext';
import { Enum as ScopeEnum } from '@AssetManagementClient/Scoping/Model/ScopeNested.gen';

export type StrategyInsightDashboardContainerProps = {
  scope: FrontendScope;
  presentationInsightDashboard: InsightDashboardDto | undefined;
  renderCompleteHandler: () => void;
  canFullyAccessInsights: boolean;
  hardwareConsoleUrl: string;
  peopleConsoleUrl: string;
  enabledLmClientFocus: boolean;
} & Styleable;

const StrategyInsightDashboardContainer: FunctionComponent<StrategyInsightDashboardContainerProps> = ({
  scope,
  presentationInsightDashboard,
  className,
  renderCompleteHandler,
  canFullyAccessInsights,
  hardwareConsoleUrl,
  peopleConsoleUrl,
  enabledLmClientFocus,
}) => {
  const { 'lm-essential': enabledLmEssential } = useFeatureFlagProvider();
  const { hasFeatureAccessAccount, unlockedFeatureAccessOrganization } = useFeatureAvailabilitiesContext();
  const hasFeatureEssential = hasFeatureAccessAccount('plan-essential');

  const [isExpanded, setIsExpanded] = useState(false);

  const { reload } = useStrategyDashboard(scope);
  // This is to notify the parent component that this component has been loaded so that other components can be shown to avoid popping of components.
  useEffect(() => {
    if (typeof presentationInsightDashboard !== 'undefined') {
      renderCompleteHandler();
    }
  });

  if (presentationInsightDashboard === undefined) {
    return <div />;
  }

  const scopeOrganizationId = scope.type === ScopeEnum.Organization ? scope.organizationId : undefined;
  const isOrganizationUnlockedAndEnabled =
    typeof scopeOrganizationId !== 'undefined' && typeof unlockedFeatureAccessOrganization !== 'undefined'
      ? unlockedFeatureAccessOrganization.organization.organizationId === scopeOrganizationId && unlockedFeatureAccessOrganization.isEnabled
      : false;

  /**
   * They should only be previewing pro insights
   * - If essentials flag is on
   * - If they are on essentials
   * - If they do not have pro unlocked and toggled on for the organization
   * - If they are on the account strategy dashboard
   *   - this tab should be hidden at a later time after we start applying access to the tabs
   *   - this means we are preserving the old behaviour for now on the account strategy dashboard
   * - currently we will preserve the old behaviour for free edition until we know what we want to do with it
   *
   * This is also calculated on the backend in the same way GUID: 35AF1A24-9E4F-4CD3-8FBD-AE8796B12DFC
   */
  const isPreviewingProInsights =
    enabledLmEssential && hasFeatureEssential && typeof scopeOrganizationId !== 'undefined' && !isOrganizationUnlockedAndEnabled;

  const buildCategory = (category: InsightCategoryDto): ReactElement => {
    let insightContainerElements: ReactElement[] = [];
    let orientation = Orientation.Vertical;
    switch (category.detail) {
      case DetailLevel.Expanded:
      case DetailLevel.Large:
        insightContainerElements = category.insights.map(i => (
          <StrategyInsightDashboardItem
            insightTitle={i.title}
            insightDescription={i.description}
            insightCount={i.value}
            key={i.insightId}
            insightUrl={getAssetConsoleUrl(hardwareConsoleUrl, peopleConsoleUrl, scope, i)}
            insightState={i.state}
            insightRiskLevel={i.riskLevel}
            insightTrend={i.trendValue}
            insightForecast={i.forecastValue}
            canFullyAccessInsights={canFullyAccessInsights}
            frontendScope={scope}
            isProInsight={i.isProInsight}
            isPreviewingProInsights={isPreviewingProInsights}
          />
        ));
        orientation = Orientation.Horizontal;
        break;
      case DetailLevel.Medium:
      case DetailLevel.Small:
        insightContainerElements = category.insights.map(i => {
          const prerequisiteReason = i.state.type === Enum.Prerequisite ? i.state.reason : undefined;
          return (
            <StrategyInsightDashboardItemSmall
              insightTitle={i.title}
              insightDescription={category.detail === DetailLevel.Small ? undefined : prerequisiteReason?.title ?? i.description}
              insightCount={i.value}
              key={i.insightId}
              insightUrl={getAssetConsoleUrl(hardwareConsoleUrl, peopleConsoleUrl, scope, i)}
              insightState={i.state}
              insightTrend={i.trendValue}
              insightForecast={i.forecastValue}
              canFullyAccessInsights={canFullyAccessInsights}
              frontendScope={scope}
              isProInsight={i.isProInsight}
              isPreviewingProInsights={isPreviewingProInsights}
            />
          );
        });
    }

    return (
      <StrategyInsightContainer
        title={category.label}
        logoSource={category.logo}
        orientation={orientation}
        elements={insightContainerElements}
        key={category.enum}
      />
    );
  };

  const renderedInsightContainers: ReactElement[] = [];
  const renderedInsightContainersPro: ReactElement[] = [];

  presentationInsightDashboard.insightCategories.map(category => {
    renderedInsightContainers.push(buildCategory(category));
  });

  if (typeof presentationInsightDashboard.insightCategoriesPro !== 'undefined') {
    presentationInsightDashboard.insightCategoriesPro.map(category => {
      renderedInsightContainersPro.push(buildCategory(category));
    });
  }

  // Previous way we were displaying the insights container when they have access to client focus
  const PreviousClientFocusDashboardDisplay = () => (
    <Box
      borderRadius={'radius400'}
      padding={'padding200'}
      css={css`
        padding: 1.5rem 1.25rem;
      `}
    >
      <div
        css={css`
          display: flex;
          gap: 1rem;
          align-items: center;
          margin-bottom: 1rem;
        `}
      >
        <Header
          size={3}
          weight={'medium'}
          css={css`
            flex-grow: 1;
          `}
        >
          Insights
        </Header>
        <InsightCreateButton
          disabled={false}
          refreshAction={reload}
          isButtonLink={true}
        />
      </div>
      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
          gap: 0.626rem;
        `}
        className={className}
      >
        {renderedInsightContainers}
      </div>
    </Box>
  );

  // Render the case specifically for having essentials because we don't know how we will handle free yet.
  if (isPreviewingProInsights) {
    return (
      <Fragment>
        <PreviousClientFocusDashboardDisplay />
        {typeof presentationInsightDashboard.insightCategoriesPro !== 'undefined' && (
          <Box
            borderRadius={'radius400'}
            padding={'padding200'}
            css={css`
              padding: 1.5rem 1.25rem;
            `}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 0.5rem;
                  cursor: pointer;
                `}
                onClick={() => {
                  setIsExpanded(!isExpanded);
                }}
              >
                <Icon
                  css={css`
                    margin-left: 0.5rem;
                  `}
                  icon={isExpanded ? 'ArrowUp' : 'ArrowDown'}
                />
                <Header
                  size={3}
                  weight={'medium'}
                  css={css`
                    flex-grow: 1;
                  `}
                >
                  More Insights with Lifecycle Manager
                </Header>
                <BadgeMarketingPro />
              </div>
              <ButtonLink
                css={css`
                  margin-left: auto;
                `}
                iconRight={'GoExternal'}
                anchor={{
                  href: routes.ACCOUNT_BILLING.route,
                  openInNewTab: true,
                }}
              >
                Learn more about Lifecycle Manager Pro
              </ButtonLink>
            </div>
            <AnimationHeight isExpanded={isExpanded}>
              <div
                css={css`
                  display: flex;
                  flex-wrap: wrap;
                  gap: 0.626rem;
                  margin-top: 1rem;
                `}
                className={className}
              >
                {renderedInsightContainersPro}
              </div>
            </AnimationHeight>
          </Box>
        )}
      </Fragment>
    );
  }

  return enabledLmClientFocus ? (
    <PreviousClientFocusDashboardDisplay />
  ) : (
    <div
      css={css`
        display: flex;
        flex-wrap: wrap;
        gap: 0.625rem;
      `}
      className={className}
    >
      {renderedInsightContainers}
    </div>
  );
};

export default StrategyInsightDashboardContainer;
