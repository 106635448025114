import { ExploreProduct } from '@SubscriptionClient/BeastClient/Beast/Msp/Billing/ExploreProducts/Dto.gen';
import { generatePath } from 'react-router-dom';
import Button from '~/neo-ui/packages/button/Button';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { mobileBreakpointRem } from '~/neo-ui/packages/layout/types/breakpoints';
import routes from '~/router/types/routes';

export type ProductSelectionCardProps = {
  exploreProduct: ExploreProduct;
  isOwnedProduct: boolean;
};

const ProductSelectionCard = ({ exploreProduct, isOwnedProduct }: ProductSelectionCardProps) => {
  const ctaLink = !isOwnedProduct
    ? generatePath(routes.ACCOUNT_BILLING_UPGRADE.route, { productFamily: exploreProduct.productFamily.toLowerCase() })
    : '?product=' + exploreProduct.productAbbreviation;

  return (
    <div
      css={{
        display: 'grid',
        borderRadius: '8px',
        boxShadow: `0px 1px 4px 0px ${colorToCode('dark-000')}`,
        padding: '1.25rem 1rem',
        height: 'min-content',
        backgroundColor: colorToCode('light-000'),
        [`@media (max-width: ${mobileBreakpointRem}rem)`]: {
          maxWidth: '580px',
        },
      }}
    >
      <img
        css={{ height: '1.5rem', marginBottom: '1rem', display: 'block' }}
        src={exploreProduct.icon}
      />
      <p css={{ textAlign: 'left' }}>{exploreProduct.marketingText}</p>
      {exploreProduct.callToActionLabel && (
        <Button
          size={'md'}
          anchor={{ href: ctaLink }}
          theme={'primary'}
          css={{ width: '11rem' }}
        >
          {exploreProduct.callToActionLabel}
        </Button>
      )}
    </div>
  );
};

export default ProductSelectionCard;
