import { createContext } from 'react';
import { OrganizationContactDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Contact/Dto/Model.gen';

export type OrganizationContactListContextData = {
  organizationContacts: OrganizationContactDto[];
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const OrganizationContactListContext = createContext<OrganizationContactListContextData | undefined>(undefined);

export default OrganizationContactListContext;
