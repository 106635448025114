import { createContext } from 'react';
import { AutoEmailReportAvailabilityDto, AutoEmailReportDto } from '@BeastClient/Beast/AutoEmail/Packages/AutoEmailReport/Dto/Model.gen';
import { AutoEmailReportTemplateDto } from '@AssetManagementClient/BeastClient/Beast/AutoEmail/Packages/AutoEmailReport/Packages/Template/Dto/Model.gen';

export type AutoEmailReportListContextData = {
  autoEmailReports: AutoEmailReportDto[];
  autoEmailReportAvailability: AutoEmailReportAvailabilityDto;
  autoEmailReportTemplateAvailabilities: AutoEmailReportTemplateDto[];
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const AutoEmailReportListContext = createContext<AutoEmailReportListContextData | undefined>(undefined);

export default AutoEmailReportListContext;
