/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { registerComponents } from '~/wm/packages/dashboard/packages/componentRegistrarWidget';
import { ComponentUniqueIds } from '~/wm/packages/dashboard/packages/widgetComponents.gen';
import SimpleCountComponent from '~/DashboardSharedComponent/packages/simple-count-component/SimpleCountComponent';
import SimplePrerequisiteState from '~/DashboardSharedComponent/packages/simple-prerequisite-state/SimplePrerequisiteState';
import DmiScoreComponent from '~/DashboardSharedComponent/packages/dmi-score/DmiScoreComponent';
import AreaChartComponent from '~/DashboardSharedComponent/packages/area-chart/AreaChartComponent';
import SimpleTableComponent from '~/DashboardSharedComponent/packages/simple-table-component/SimpleTableComponent';

export default () => {
  registerComponents(
    new Map<ComponentUniqueIds, React.FunctionComponent<any>>([
      ['simple-count', SimpleCountComponent],
      ['simple-prerequisite', SimplePrerequisiteState],
      ['dmi-score', DmiScoreComponent],
      ['area-chart', AreaChartComponent],
      ['simple-table', SimpleTableComponent],
      ['simple-preview-count', SimpleCountComponent],
      ['dmi-score-preview', DmiScoreComponent],
    ]),
  );
};
