import { css } from '@emotion/react';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import Tooltip from '~/neo-ui/packages/tooltip/Tooltip';
import Icon from '~/neo-ui/packages/icon/Icon';
import Button from '~/neo-ui/packages/button/Button';
import { Styleable } from '~/neo-ui/model/capacity';
import AutoEmailRecipientAddForm, {
  AutoEmailRecipientAddPayload,
} from '~/wm/packages/organization/packages/auto-email/packages/auto-email-recipients/packages/auto-email-recipient-add-form/AutoEmailRecipientAddForm';
import AutoEmailRecipientAdd from '~/wm/packages/organization/packages/auto-email/packages/auto-email-recipients/packages/auto-email-recipient-add/AutoEmailRecipientAdd';

export type AutoEmailRecipient = {
  fullName: string;
  emailAddress: string;
  isBouncing: boolean;
};

export type AutoEmailRecipientsProps = {
  recipients: AutoEmailRecipient[];
  isAddingRecipient: boolean;
  onAddRecipient: (addRecipientPayload: AutoEmailRecipientAddPayload) => void;
  isRemovingRecipient: boolean;
  onRemoveRecipient: (emailAddress: string) => void;
} & Styleable;

const AutoEmailRecipients = ({
  recipients,
  onAddRecipient,
  isAddingRecipient,
  onRemoveRecipient,
  isRemovingRecipient,
  className,
}: AutoEmailRecipientsProps) => (
  <div
    // This key is used to force a re-render when the recipients change
    key={recipients.length}
    className={className}
    css={css`
      display: flex;
      flex-direction: column;
      gap: 1rem;
    `}
  >
    <Header
      size={4}
      weight={'bold'}
    >
      Recipients
    </Header>
    <AutoEmailRecipientAddForm onAutoEmailRecipientAdd={addRecipientPayload => onAddRecipient(addRecipientPayload)}>
      <table className={'table wm-table-white table-outer-bordered wm-vertical-middle-table table-striped'}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {recipients.map((recipient, index) => (
            <tr
              key={index}
              css={
                recipient.isBouncing
                  ? css`
                      // PHP styles override this, important is needed
                      background-color: ${colorToCode('warning-400')} !important;
                      color: ${colorToCode('light-000')};
                    `
                  : undefined
              }
            >
              <td>{recipient.fullName}</td>
              <td>{recipient.emailAddress}</td>
              <td
                css={css`
                  display: flex;
                  align-items: flex-end;
                  gap: 0.5rem;
                `}
              >
                {recipient.isBouncing && (
                  <Tooltip content={'The email you chose may not exist. If it looks correct, please contact our support team.'}>
                    <Icon
                      icon={'Warning'}
                      color={'light-000'}
                    />
                  </Tooltip>
                )}
                <Button
                  size={'sm'}
                  iconLeft={'Remove'}
                  loading={isRemovingRecipient}
                  onClick={() => onRemoveRecipient(recipient.emailAddress)}
                />
              </td>
            </tr>
          ))}
          <AutoEmailRecipientAdd
            existingRecipients={recipients}
            isAddingRecipient={isAddingRecipient}
            onAddRecipient={onAddRecipient}
          />
        </tbody>
      </table>
    </AutoEmailRecipientAddForm>
  </div>
);

export default AutoEmailRecipients;
