import AlertHook from '~/legacy-ui/packages/alert/state/AlertHook';
import ModalHook from '~/legacy-ui/packages/modal/packages/alert-modal/state/ModalHook';
import { Styleable } from '~/neo-ui/model/capacity';
import BannerUpgrade from '~/neo-ui/packages/banner/packages/banner-upgrade/BannerUpgrade';
import ActionButton from '~/neo-ui/packages/button/packages/action-button/ActionButton';
import DownloadLink from '~/neo-ui/packages/button/packages/action-button/packages/download-link/DownloadLink';
import EmptyStateInfo from '~/neo-ui/packages/empty-state-info/EmptyStateInfo';
import ProgressBar from '~/neo-ui/packages/progress/packages/progress-bar/ProgressBar';
import ToastProvider from '~/neo-ui/packages/toast/context/ToastProvider';
import { ComponentRegistrar, provideInnerHtml } from '~/wm-legacy/ComponentRegistrar';
import ExportedComponentDefinition from '~/wm-legacy/model/ExportedComponentDefinition';
import StyleResetKitchenSink from '~/wm-legacy/packages/style-reset/packages/style-reset-kitchen-sink/StyleResetKitchenSink';
import ActivityLog from '~/wm/packages/activity/packages/activity-log/ActivityLog';
import ActivitySummary from '~/wm/packages/activity/packages/activity-summary/ActivitySummary';
import AssetConsole from '~/wm/packages/asset/packages/console/packages/asset-console/AssetConsole';
import DashboardPage from '~/wm/packages/dashboard/packages/dashboard-page/DashboardPage';
import DashboardUserPreferenceSection from '~/wm/packages/dashboard/packages/dashboard-page/packages/dashboard-preference/DashboardUserPreferenceSection';
import AssetInfo from '~/wm/packages/hardware/packages/asset-info/AssetInfo';
import HardwareView from '~/wm/packages/hardware/packages/hardware-view/HardwareView';
import HardwareInsightShareButton from '~/wm/packages/hardware/packages/insight-share-button/HardwareInsightShareButton';
import HardwareSettings from '~/wm/packages/hardware/packages/settings/HardwareSettings';
import IntegrationSyncLogPage from '~/wm/packages/integration/packages/integration-sync-log/packages/integration-sync-log-page/IntegrationSyncLogPage';
import AddIntegrationPage from '~/wm/packages/integration/packages/scalepad-account/packages/add-integration-page/AddIntegrationPage';
import { IntegrationSetupPage } from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/IntegrationSetupPage';
import BillingDataSyncPage from '~/wm/packages/niche/packages/billing-data-sync-page/BillingDataSyncPage';
import NotificationBellButton from '~/wm/packages/notification/packages/notification-bell-button/NotificationBellButton';
import AutoEmailReportPage from '~/wm/packages/organization/packages/auto-email-report/AutoEmailReportPage';
import AutoEmailReportEmailTemplateEditPage from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-email-template-edit/AutoEmailReportEmailTemplateEditPage';
import OrganizationNameAndLinks from '~/wm/packages/organization/packages/name-and-links/OrganizationNameAndLinks';
import OrganizationLock from '~/wm/packages/organization/packages/organization-lock/OrganizationLock';
import OrganizationStarTitle from '~/wm/packages/organization/packages/organization-star/packages/organization-star-title/OrganizationStarTitle';
import OrganizationHardwareSettingsPage from '~/wm/packages/organization/packages/settings/packages/hardware-settings-page/OrganizationHardwareSettingsPage';
import OrganizationRegionalSettingsPage from '~/wm/packages/organization/packages/settings/packages/regional-settings-page/OrganizationRegionalSettingsPage';
import OrganizationWarrantySettingsPage from '~/wm/packages/organization/packages/settings/packages/warranty-settings-page/OrganizationWarrantySettingsPage';
import OrganizationsSummary from '~/wm/packages/organization/packages/summary/OrganizationsSummary';
import RegionalSettings from '~/wm/packages/settings/packages/regional-settings/RegionalSettings';
import SettingsLink from '~/wm/packages/settings/packages/settings-link/SettingsLink';
import SamConsoleRenderer from '~/wm/packages/software/packages/sam-console/SamConsoleRenderer';
import ApiPage from '~/wm/packages/sp-account/packages/api-page/ApiPage';
import HomePage from '~/wm/packages/sp-account/packages/home-page/HomePage';
import PublicPage from '~/wm/packages/sp-account/packages/public-page/PublicPage';
import DmiScore from '~/wm/packages/strategy/packages/dmi/packages/dmi-score/DmiScore';
import InsightList from '~/wm/packages/strategy/packages/insight-console/packages/insight-list/InsightList';
import StrategyPresentationPage from '~/wm/packages/strategy/packages/presentation/packages/strategy-presentation-page/StrategyPresentationPage';
import ScorecardPage from '~/wm/packages/strategy/packages/scorecard/packages/scorecard-page/ScorecardPage';
import StrategyInsightDashboardContainer from '~/wm/packages/strategy/packages/strategy-dashboard/packages/insight-dashboard/StrategyInsightDashboardContainer';
import SubscriptionGrowWizard from '~/wm/packages/subscription/packages/grow/wizard/SubscriptionGrowWizard';
import SubscriptionPlanOffer from '~/wm/packages/subscription/packages/offer/packages/subscription-offer/SubscriptionPlanOffer';
import ProductSelectionPage from '~/wm/packages/subscription/packages/product-selection/ProductSelectionPage';
import ProductSubscriptionPage from '~/wm/packages/subscription/packages/product-subscription-page/ProductSubscriptionPage';
import ProductSubscriptionPageV2 from '~/wm/packages/subscription/packages/product-subscription-page/ProductSubscriptionPageV2';
import PurchaseSubscription from '~/wm/packages/subscription/packages/purchase-subscription/PurchaseSubscription';
import Quotes from '~/wm/packages/subscription/packages/quotes/Quotes';
import SubscriptionPdContactBar from '~/wm/packages/subscription/packages/subscription-pd-contact/packages/subscription-pd-contact-bar/SubscriptionPdContactBar';
import SubscriptionPdContactSection from '~/wm/packages/subscription/packages/subscription-pd-contact/packages/subscription-pd-contact-section/SubscriptionPdContactSection';
import SubscriptionUpgradeWizard from '~/wm/packages/subscription/packages/upgrade/wizard/SubscriptionUpgradeWizard';
import TreeImpactLearnMoreButton from '~/wm/packages/tree-impact/packages/tree-impact-learn-more-button/TreeImpactLearnMoreButton';
import CoverageListDownloadButton from '~/wm/packages/warranty/packages/coverage/packages/coverage-list-download-button/CoverageListDownloadButton';
import CoverageList from '~/wm/packages/warranty/packages/coverage/packages/coverage-list/CoverageList';
import CoverageTransferPage from '~/wm/packages/warranty/packages/coverage/packages/coverage-transfer/CoverageTransferPage';
import WarrantyCoverageSettings from '~/wm/packages/warranty/packages/coverage/packages/settings/WarrantyCoverageSettings';
import ServiceRequestDetailPage from '~/wm/packages/warranty/packages/service-request/packages/service-request-detail-page/ServiceRequestDetailPage';
import ServiceRequestList from '~/wm/packages/warranty/packages/service-request/packages/service-request-list/ServiceRequestList';
import TreeImpactSection from '~/wm/packages/warranty/packages/tree-impact/TreeImpactSection';
import WarrantyCartPage from '~/wm/packages/warranty/packages/warranty-cart/WarrantyCartPage';
import WarrantyListPage from '~/wm/packages/warranty/packages/warranty-list/WarrantyListPage';
import RootState from '~/wm/state/RootState';
import SupportedIntegrationsListPage from '~/wm/packages/documentation/packages/supported-integrations/SupportedIntegrationsListPage';
import SupportedIntegrationDetailsPage from '~/wm/packages/documentation/packages/supported-integrations/SupportedIntegrationDetailsPage';
import EnforceSsoToggle from '~/wm/packages/account/packages/user/packages/user-list-page/packages/enforce-sso-button/EnforceSsoToggle';
import ProductAccessBanner from '~/wm/packages/sp-account/product-access-banner/ProductAccessBanner';
import BannerUpgradeScalePadAccount from '~/neo-ui/packages/banner/packages/banner-upgrade/BannerUpgradeScalePadAccount';

// MRP Monolith warning!
// This ASAP must be split through composition so that domains specify their registrations.
// See: https://warrantymaster.myjetbrains.com/youtrack/issue/WM-398
export const exportedComponents: ExportedComponentDefinition[] = [
  { uniqueId: 'StyleResetKitchenSink', component: StyleResetKitchenSink },

  // Global redux components
  {
    uniqueId: 'AlertHook',
    component: (props: Styleable) =>
      AlertHook({
        alertStateSelector: (state: RootState) => state.ui.alert,
        ...props,
      }),
  },

  {
    uniqueId: 'ModalHook',
    component:
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (props: any) =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        ModalHook({
          modalStateSelector: (state: RootState) => state.ui.modal,
          ...props,
        }),
  },

  // Toast
  {
    uniqueId: 'ToastProvider',
    component: ToastProvider,
  },

  // Dashboard page
  {
    uniqueId: 'DashboardPage',
    component: DashboardPage,
  },

  // DashboardUserPreferenceSection
  {
    uniqueId: 'DashboardUserPreferenceSection',
    component: DashboardUserPreferenceSection,
  },

  // Subscription upgrade
  {
    uniqueId: 'SubscriptionUpgradeWizard',
    component: SubscriptionUpgradeWizard,
  },
  {
    uniqueId: 'ProductSelectionPage',
    component: ProductSelectionPage,
  },
  {
    uniqueId: 'PurchaseSubscription',
    component: PurchaseSubscription,
  },

  // Subscription grow
  { uniqueId: 'SubscriptionGrowWizard', component: SubscriptionGrowWizard },

  // Product Subscription page
  { uniqueId: 'ProductSubscriptionPage', component: ProductSubscriptionPage },
  { uniqueId: 'ProductSubscriptionPageV2', component: ProductSubscriptionPageV2 },

  // Action Button
  { uniqueId: 'ActionButton', component: ActionButton },

  // Subscription Partner Development
  { uniqueId: 'SubscriptionPdContactBar', component: SubscriptionPdContactBar },

  // Regional Settings
  { uniqueId: 'RegionalSettings', component: RegionalSettings },

  // Organization
  {
    uniqueId: 'OrganizationLock',
    component: provideInnerHtml(OrganizationLock),
  },
  {
    uniqueId: 'OrganizationStarTitle',
    component: OrganizationStarTitle,
  },

  // Organization Settings
  {
    uniqueId: 'OrganizationHardwareSettingsPage',
    component: OrganizationHardwareSettingsPage,
  },
  {
    uniqueId: 'OrganizationWarrantySettingsPage',
    component: OrganizationWarrantySettingsPage,
  },
  {
    uniqueId: 'OrganizationRegionalSettingsPage',
    component: OrganizationRegionalSettingsPage,
  },

  // Organization Linking
  { uniqueId: 'OrganizationNameAndLinks', component: OrganizationNameAndLinks },

  // Asset information
  { uniqueId: 'AssetInfo', component: AssetInfo },

  // Hardware information
  { uniqueId: 'HardwareView', component: HardwareView },

  // Service request list
  { uniqueId: 'ServiceRequestList', component: ServiceRequestList },

  // Coverage list
  { uniqueId: 'CoverageList', component: CoverageList },

  // Coverage list download button
  {
    uniqueId: 'CoverageListDownloadButton',
    component: CoverageListDownloadButton,
  },

  // Service request summary page
  {
    uniqueId: 'ServiceRequestSummaryPage',
    component: ServiceRequestDetailPage,
  },

  { uniqueId: 'CoverageTransferPage', component: CoverageTransferPage },

  // Integration sync log page
  { uniqueId: 'IntegrationSyncLogPage', component: IntegrationSyncLogPage },

  // Settings link
  { uniqueId: 'SettingsLink', component: SettingsLink },

  // Download link
  { uniqueId: 'DownloadLink', component: DownloadLink },

  // Hardware insight share button
  {
    uniqueId: 'HardwareInsightShareButton',
    component: HardwareInsightShareButton,
  },

  // Progress
  { uniqueId: 'ProgressBar', component: ProgressBar },

  // Account Hardware Settings
  { uniqueId: 'HardwareSettings', component: HardwareSettings },

  // Account Warranty coverage Settings
  { uniqueId: 'WarrantyCoverageSettings', component: WarrantyCoverageSettings },

  // Warranty cart tree impact section
  {
    uniqueId: 'WarrantyTreeImpactSection',
    component: TreeImpactSection,
  },

  // Tree Impact Provider Link Button
  {
    uniqueId: 'TreeImpactLearnMoreButton',
    component: TreeImpactLearnMoreButton,
  },

  // Account Insight List
  { uniqueId: 'InsightList', component: InsightList },

  // Software
  { uniqueId: 'SamConsoleRenderer', component: SamConsoleRenderer },

  // Organizations summary page
  { uniqueId: 'OrganizationsSummary', component: OrganizationsSummary },

  // Auto email page
  { uniqueId: 'AutoEmailReportPage', component: AutoEmailReportPage },

  // Auto email report email template edit page
  {
    uniqueId: 'AutoEmailReportEmailTemplateEditPage',
    component: AutoEmailReportEmailTemplateEditPage,
  },

  // Activity log
  { uniqueId: 'ActivityLog', component: ActivityLog },

  // Activity summary
  { uniqueId: 'ActivitySummary', component: ActivitySummary },

  // Empty State Info
  { uniqueId: 'EmptyStateInfo', component: EmptyStateInfo },

  // Notification
  { uniqueId: 'NotificationBellButton', component: NotificationBellButton },

  // Strategy insight dashboard-grid container
  {
    uniqueId: 'StrategyInsightDashboardContainer',
    component: StrategyInsightDashboardContainer,
  },

  // Scorecard View
  {
    uniqueId: 'ScorecardPage',
    component: ScorecardPage,
  },

  // Presentation views for strategy
  { uniqueId: 'StrategyPresentationPage', component: StrategyPresentationPage },

  // Subscription offer
  { uniqueId: 'SubscriptionPlanOffer', component: SubscriptionPlanOffer },

  // DMI Score
  { uniqueId: 'DmiScore', component: DmiScore },

  // BannerUpgrade
  { uniqueId: 'BannerUpgrade', component: BannerUpgrade, featureFlagRequired: true },
  // BannerUpgradeScalePadAccount
  { uniqueId: 'BannerUpgradeScalePadAccount', component: BannerUpgradeScalePadAccount },

  {
    uniqueId: 'SubscriptionPdContactSection',
    component: SubscriptionPdContactSection,
  },

  // Hardware console
  {
    uniqueId: 'AssetConsole',
    component: AssetConsole,
  },
  // SP Account Home page
  {
    uniqueId: 'HomePage',
    component: HomePage,
  },
  // SP Account Public page
  {
    uniqueId: 'PublicPage',
    component: PublicPage,
  },
  // SP Account add integration page
  {
    uniqueId: 'AddIntegrationPage',
    component: AddIntegrationPage,
  },
  // SP Account add api page
  {
    uniqueId: 'ApiPage',
    component: ApiPage,
  },
  {
    uniqueId: 'IntegrationSetupPage',
    component: IntegrationSetupPage,
  },
  /* Warranties list */
  {
    uniqueId: 'WarrantyListPage',
    component: WarrantyListPage,
  },
  {
    uniqueId: 'Quotes',
    component: Quotes,
  },
  {
    uniqueId: 'BillingDataSyncPage',
    component: BillingDataSyncPage,
  },
  /* Warranties page*/
  {
    uniqueId: 'WarrantyCartPage',
    component: WarrantyCartPage,
  },
  {
    uniqueId: 'SupportedIntegrationsListPage',
    component: SupportedIntegrationsListPage,
  },

  {
    uniqueId: 'SupportedIntegrationDetailsPage',
    component: SupportedIntegrationDetailsPage,
  },

  {
    uniqueId: 'EnforceSsoToggle',
    component: EnforceSsoToggle,
  },
  {
    uniqueId: 'ProductAccessBanner',
    component: ProductAccessBanner,
  },
];

const componentRegistrarMsp = new ComponentRegistrar(exportedComponents);

// Export the instance
export default componentRegistrarMsp;
