import * as React from 'react';
import Spinner from '~/neo-ui/spinner/Spinner';
import useDisposalOrderInfoContext from '../../../../context/hooks/useDisposalOrderInfoContext';
import { Enum } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Packages/DisposalOrderType/Model/DisposalOrderTypeFactoryNested.gen';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Button from '~/neo-ui/packages/button/Button';
import { css } from '@emotion/react';
import useDisposalOrderShippingLabelGet from '~/wm/packages/disposal/packages/order-info/hooks/useDisposalOrderShippingLabelGet';
import useDisposalOrderPackagesLabelPoller from '~/wm/packages/disposal/packages/order-info/hooks/useDisposalOrderPackagesLabelPoller';
import useEmailContext from '~/wm/packages/product/context/hooks/useEmailContext';
import InputNumberStepper from '~/neo-ui/packages/input/packages/input-number-stepper/InputNumberStepper';
import { TrackingServices, useEventTracking } from '~/extensions/packages/tracking/hooks/useEventTracking';
import useFeatureFlagProvider from '~/router/feature-flag-provider/hooks/useFeatureFlagProvider';
import { DisposalOrderShippingLabelPurchaseFormData } from '~/wm/packages/disposal/packages/order-info/packages/disposal-order-shipment-section/packages/disposal-order-package-module/packages/disposal-order-shipping-label-purchase-form/DisposalOrderShippingLabelPurchaseForm';
import { FieldKeyExpressionSegment, resolveFieldKey } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { FieldArray } from 'formik';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import FormNumberInput from '~/neo-ui/packages/form/packages/form-input/packages/form-number-input/FormNumberInput';

type DisposalOrderPackageModuleState = 'waiting' | 'purchasing-labels' | 'purchased' | 'error';

const DisposalOrderPackageModule = () => {
  const { 'disposal-carrier-ups': isUpsDisposalCarrierEnabled } = useFeatureFlagProvider();
  const { getFormInput, isSubmitting, submitForm, isValid, setFormInput } = useFormContext<DisposalOrderShippingLabelPurchaseFormData>();

  const { disposalOrderSummary } = useDisposalOrderInfoContext();
  const { supportEmail } = useEmailContext();

  const { pollingState } = useDisposalOrderPackagesLabelPoller(disposalOrderSummary);

  const { downloadDisposalOrderShippingLabel } = useDisposalOrderShippingLabelGet();

  const { trackEvent: trackEventProcessor } = useEventTracking(TrackingServices.EventProcessor);

  if (typeof disposalOrderSummary === 'undefined') {
    return <Spinner />;
  }

  // We currently only support self and wa for this component
  if (disposalOrderSummary.orderType !== Enum.ItadSelf && disposalOrderSummary.orderType !== Enum.WorkstationAssurance) {
    throw new Error(`Tried to render this disposal order of type ${disposalOrderSummary.orderType}`);
  }

  const moduleState: DisposalOrderPackageModuleState = disposalOrderSummary.isAllShippingLabelsCreated
    ? 'purchased'
    : disposalOrderSummary.packageCount > 0
    ? 'purchasing-labels'
    : pollingState === 'error'
    ? 'error'
    : 'waiting';

  // A user can generate up to 30 labels
  // Duplicated in backend: 81d49186-0123-4413-8d2a-a1ec381e2d3d
  const packageLimit = 30;
  const packageWeightPoundsList = getFormInput<number[]>(formData => formData.packageWeightPoundsList).value;

  return (
    <FieldArray
      name={resolveFieldKey(
        (formData: FieldKeyExpressionSegment<DisposalOrderShippingLabelPurchaseFormData>) => formData.packageWeightPoundsList,
      )}
      render={arrayHelpers => (
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <Label
            color={'dark-700'}
            bold={true}
            size={'md'}
          >
            Shipping labels
          </Label>
          {moduleState === 'waiting' && (
            <React.Fragment>
              <Label
                color={'dark-900-64'}
                size={'sm'}
                css={css`
                  display: flex;
                `}
              >
                Generate up to
                <Label
                  size={'sm'}
                  bold={true}
                  css={css`
                    margin-left: 0.25rem;
                  `}
                >
                  {packageLimit} labels
                </Label>
                . Each package can weigh up to
                <Label
                  size={'sm'}
                  bold={true}
                  css={css`
                    margin-left: 0.25rem;
                  `}
                >
                  {isUpsDisposalCarrierEnabled ? '150' : '70'} lbs
                </Label>
                .
              </Label>
            </React.Fragment>
          )}
          {moduleState === 'purchased' && (
            <Label
              css={css`
                display: flex;
                margin-bottom: 0.625rem;
              `}
              color={'dark-900-64'}
              size={'sm'}
            >
              Labels will be voided if unused within 21 days.
            </Label>
          )}
          {
            {
              waiting: (
                <div
                  css={css`
                    margin-top: 0.5rem;
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;
                    align-items: flex-start;
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      gap: 0.5rem;
                    `}
                  >
                    <InputNumberStepper
                      value={packageWeightPoundsList.length}
                      disabledButtonRemove={packageWeightPoundsList.length <= 1}
                      disabledButtonAdd={packageWeightPoundsList.length >= packageLimit}
                      onChange={async value => {
                        if (isNaN(value)) {
                          return;
                        }

                        if (value > packageLimit) {
                          return;
                        }

                        if (value > packageWeightPoundsList.length) {
                          const amountFieldsAdd = value - packageWeightPoundsList.length;
                          for (let i = 0; i < amountFieldsAdd; i++) {
                            arrayHelpers.push(0);
                          }
                        } else {
                          const amountFieldsRemove = packageWeightPoundsList.length - value;
                          await setFormInput<number[]>(
                            formData => formData.packageWeightPoundsList,
                            packageWeightPoundsList.slice(0, -amountFieldsRemove),
                          );
                        }
                      }}
                      onRemoveClick={() => {
                        // Guard against packageAmount going below 1
                        if (packageWeightPoundsList.length <= 1) {
                          return;
                        }

                        arrayHelpers.remove(packageWeightPoundsList.length - 1);
                      }}
                      onAddClick={() => {
                        // Guard against packageAmount exceeding packageLimit
                        if (packageWeightPoundsList.length === packageLimit) {
                          return;
                        }

                        arrayHelpers.push(0);
                      }}
                    />
                  </div>

                  {packageWeightPoundsList.length > 0 && (
                    <div
                      css={css`
                        display: grid;
                        flex-direction: column;
                        gap: 0.5rem;
                      `}
                    >
                      <Label
                        color={'dark-700'}
                        bold={true}
                        size={'md'}
                      >
                        Enter approximate weight for each label
                      </Label>
                      <div
                        css={css`
                          display: grid;
                          grid-template-columns: auto auto auto auto;
                          align-items: center;
                          justify-content: start;
                          row-gap: 1rem;
                        `}
                      >
                        {packageWeightPoundsList.map((_, lineItemIndex) => {
                          const formInput = getFormInput(formData => formData.packageWeightPoundsList[lineItemIndex]);

                          return (
                            <React.Fragment key={lineItemIndex}>
                              <Label>Label {lineItemIndex + 1}</Label>
                              <FormNumberInput
                                fieldKey={(values: FieldKeyExpressionSegment<DisposalOrderShippingLabelPurchaseFormData>) =>
                                  values.packageWeightPoundsList[lineItemIndex]
                                }
                                showFormError={false}
                                css={css`
                                  margin-left: 1.5rem;
                                  margin-right: 1rem;
                                  width: 6rem;
                                `}
                              />
                              <Label>lbs</Label>
                              <div>
                                {formInput.error && formInput.touched && (
                                  <Label
                                    css={css`
                                      margin-left: 1.5rem;
                                    `}
                                    color={'negative-400'}
                                  >
                                    {formInput.error}
                                  </Label>
                                )}
                              </div>
                            </React.Fragment>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  <Button
                    theme={'secondary'}
                    onClick={submitForm}
                    disabled={
                      isSubmitting ||
                      !isValid ||
                      packageWeightPoundsList.length === 0 ||
                      packageWeightPoundsList.length + disposalOrderSummary.packageCount > packageLimit
                    }
                  >
                    Generate labels
                  </Button>
                </div>
              ),
              'purchasing-labels': (
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                  `}
                >
                  <Label>Please wait while we generate your labels...</Label>
                  <Spinner />
                </div>
              ),
              purchased: (
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                  `}
                >
                  <Label>
                    <b>{disposalOrderSummary.packageCount}</b> shipping label
                    {disposalOrderSummary.packageCount === 1 ? '' : 's'} ready to print
                  </Label>
                  <Button
                    iconLeft={'Printer'}
                    theme={'secondary'}
                    onClick={() => {
                      downloadDisposalOrderShippingLabel(disposalOrderSummary?.disposalOrderId);

                      trackEventProcessor('disposal_shipping_label_downloaded', {
                        disposalOrderId: disposalOrderSummary?.disposalOrderId,
                        scope: typeof disposalOrderSummary.disposalOrderPickupDetails !== 'undefined' ? 'Pickup' : 'Dropoff',
                      });
                    }}
                  >
                    Print...
                  </Button>
                </div>
              ),
              error: (
                <div
                  css={css`
                    display: inline-flex;
                    flex-direction: row;
                    margin-top: 0.5rem;
                  `}
                >
                  <Label
                    muted={true}
                    color={'negative-400'}
                  >
                    There has been an issue with purchasing your shipping labels for order {disposalOrderSummary.disposalOrderId}. Please
                    contact
                    <a
                      href={`mailto:${supportEmail}`}
                      target={'_blank'}
                    >
                      {' '}
                      {supportEmail}{' '}
                    </a>
                    for assistance.
                  </Label>
                </div>
              ),
            }[moduleState]
          }
        </div>
      )}
    />
  );
};

export default DisposalOrderPackageModule;
