import { Enum as IntegrationVendor } from '@AssetManagementClient/SyncGod/Shared/Integration/IntegrationFactoryNested.gen';
import { VendorIcon } from '~/neo-ui/packages/icon/Icon';
import IconType from '~/neo-ui/packages/icon/IconType.gen';

/**
 * Note: This should come from the factory definitions!
 */
const vendorToIcon = (vendor: IntegrationVendor): VendorIcon | undefined => {
  switch (vendor) {
    case IntegrationVendor.Addigy:
      return 'Addigy';
    case IntegrationVendor.AEM:
      return 'DattoRMM';
    case IntegrationVendor.Atera:
      return 'Atera';
    case IntegrationVendor.Autotask:
    case IntegrationVendor.AutotaskPSA:
      return 'DattoPSA';
    case IntegrationVendor.Auvik:
      return 'Auvik';
    case IntegrationVendor.BackupRadar:
      return 'BackupRadar';
    case IntegrationVendor.BarracudaMSPManagedWorkplaceRMM:
      return 'Barracuda';
    case IntegrationVendor.BarracudaCloudControl:
      return undefined;
    case IntegrationVendor.Bluetrait:
      return 'Bluetrait';
    case IntegrationVendor.BreachSecureNow:
      return 'BreachSecureNow';
    case IntegrationVendor.CiscoSmartNet:
      return 'CiscoSmartNet';
    case IntegrationVendor.CiscoSmartNetTotalCare:
      return 'CiscoSmartNet';
    case IntegrationVendor.CommitCRM:
      return 'CommitCRM';
    case IntegrationVendor.AutomateRest:
      return 'ConnectWiseAutomateRest';
    case IntegrationVendor.ConnectWise:
    case IntegrationVendor.ConnectWiseManage:
      return 'ConnectWiseManage';
    case IntegrationVendor.ConnectWiseRmm:
      return 'ConnectWiseRMM';
    case IntegrationVendor.Continuum:
      return 'Continuum';
    case IntegrationVendor.DattoBCDR:
      return 'DattoBCDR';
    case IntegrationVendor.Fortinet:
      return 'FortiGateCloud';
    case IntegrationVendor.Freshservice:
      return 'Freshservice';
    case IntegrationVendor.HaloItsm:
      return 'HaloITSM';
    case IntegrationVendor.HaloPsa:
      return 'HaloPSA';
    case IntegrationVendor.Hudu:
      return 'Hudu';
    case IntegrationVendor.ITGlue:
      return 'ITGlue';
    case IntegrationVendor.JamfPro:
      return undefined;
    case IntegrationVendor.Kaseya:
      return 'KaseyaVsa';
    case IntegrationVendor.KaseyaBms:
      return 'KaseyaBms';
    case IntegrationVendor.KaseyaVsax:
      return 'KaseyaVSAx';
    case IntegrationVendor.LabTech:
      return 'ConnectWiseAutomate';
    case IntegrationVendor.LogMeIn:
      return 'LogMeInCentral';
    case IntegrationVendor.ManageEngine:
      return 'ManageEngine';
    case IntegrationVendor.MAXfocus:
      return 'SolarWindsRMM';
    case IntegrationVendor.Meraki:
    case IntegrationVendor.MerakiSm:
      return 'Meraki';
    case IntegrationVendor.MicrosoftEndpointManagerSingleTenant:
      return 'MicrosoftEndpointManagerSingleTenant';
    case IntegrationVendor.MicrosoftEndpointManagerMultiTenant:
      return 'MicrosoftEndpointManagerMultiTenant';
    case IntegrationVendor.MicrosoftEndpointManagerMultiTenantOAuth:
      return 'MicrosoftEndpointManagerMultiTenantOAuth';
    case IntegrationVendor.Naverisk:
      return 'Naverisk';
    case IntegrationVendor.NCentral:
    case IntegrationVendor.NCentralRest:
      return 'NCentral';
    case IntegrationVendor.NinjaRMM:
    case IntegrationVendor.NinjaOneOAuth:
      return 'NinjaRmm';
    case IntegrationVendor.Panorama9:
      return 'Panorama9';
    case IntegrationVendor.Passportal:
      return undefined;
    case IntegrationVendor.PulsewayPsa:
    case IntegrationVendor.PulsewayRmm:
      return 'Pulseway';
    case IntegrationVendor.RepairShopr:
      return 'RepairShopr';
    case IntegrationVendor.RgSystem:
      return 'RgSystem';
    case IntegrationVendor.ServiceNow:
      return 'ServiceNow';
    case IntegrationVendor.SonicWallV2:
    case IntegrationVendor.SonicWall:
      return 'SonicWall';
    case IntegrationVendor.SuperOps:
      return 'SuperOps';
    case IntegrationVendor.SyncroMsp:
      return 'SyncroMsp';
    case IntegrationVendor.Vorex:
      return 'Vorex';
    case IntegrationVendor.WarrantyMaster:
      return 'WarrantyMaster';
    case IntegrationVendor.Watchman:
      return undefined;
    case IntegrationVendor.UbiquitiCloudPortal:
      return 'UbiquitiCloudPortal';
    case IntegrationVendor.Microsoft365SingleTenant:
      return 'MicrosoftEndpointManagerSingleTenant'; // This should be updated when we have a proper logo for m365
    case IntegrationVendor.Microsoft365MultiTenantOauth:
      return 'MicrosoftEndpointManagerMultiTenantOAuth'; // This should also be updated when we have a proper logo for m365
  }
};

class IntegrationVendorDefinition {
  public icon?: IconType;
  constructor(public key: IntegrationVendor) {
    this.icon = vendorToIcon(key);
  }
}

export const vendorDefinition = (key: IntegrationVendor) => new IntegrationVendorDefinition(key);

export default IntegrationVendorDefinition;
