import { ComponentDto, WidgetDto } from '@DashboardClient/Dashboard/Packages/Widget/Packages/Dto/Model.gen';
import { ComponentProps } from '~/wm/packages/dashboard/packages/dashboard-grid/DashboardGrid';
import { getComponentByUniqueId } from '~/wm/packages/dashboard/packages/componentRegistrarWidget';
import Widget from '~/wm/packages/dashboard/packages/widget/Widget';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { Type, Type as WidgetType } from '@AssetManagementClient/Dashboard/Sdk/Model.gen';
import consoleLogOnDevEnvironment from '~/extensions/packages/environment/consoleLogOnDevEnvironment';

const validateWidgetComponent = (widget: WidgetDto, component: ComponentDto, widgetComponentProps: ComponentProps | undefined): boolean => {
  if (typeof widgetComponentProps === 'undefined' || typeof widgetComponentProps.payload?.componentUniqueId === 'undefined') {
    consoleLogOnDevEnvironment(`No component id found from the endpoint for widget ${widget.uniqueId}`);
    return false;
  }
  if (
    (typeof component.endpointUrl === 'undefined' || component.endpointUrl.trim().length === 0) &&
    widgetComponentProps.payload.enum !== WidgetType.PreReqResponse &&
    widgetComponentProps.payload?.componentUniqueId === component.componentUniqueId
  ) {
    consoleLogOnDevEnvironment(
      `Component endpoint url is undefined for widget ${widget.uniqueId}`,
      widgetComponentProps.payload?.componentUniqueId,
      component.componentUniqueId,
    );
    return false;
  }
  if (
    typeof getComponentByUniqueId(widgetComponentProps.payload?.componentUniqueId) === 'undefined' &&
    typeof component.endpointUrl !== 'undefined'
  ) {
    consoleLogOnDevEnvironment(
      `Component id received from the endpoint ${component.endpointUrl} cannot be found in the component registrar`,
    );
    return false;
  }
  return true;
};

export const buildWidgets = (widgetAvailability: WidgetDto[], componentProps: ComponentProps[]): EmotionJSX.Element[] =>
  widgetAvailability
    ?.map((widget: WidgetDto) => {
      // Validation
      const validatedComponents = widget.components.map(widgetComponent => {
        {
          const loadedComponentProps = componentProps.find(widgetComponentProps => widgetComponentProps.id === widget.key);
          return validateWidgetComponent(widget, widgetComponent, loadedComponentProps) ? loadedComponentProps : undefined;
        }
      });
      // Build the widget
      if (validatedComponents.length === 0) {
        return <div key={widget.key} />;
      }
      let component = validatedComponents.find(value => value?.payload?.enum === Type.MainResponse);
      if (component === undefined) {
        component = validatedComponents.find(
          value => value?.payload?.enum === Type.PreReqResponse || value?.payload?.enum === Type.PreviewResponse,
        );
      }

      return (
        <Widget
          key={widget.key}
          title={widget.title}
          tooltip={widget.tooltip}
          ctaLabel={widget.ctaLabel}
          ctaUrl={widget.ctaUrl}
          component={component?.payload}
        />
      );
    })
    .flat();
