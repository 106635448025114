/* eslint-disable @typescript-eslint/naming-convention */
import routesGeneratedDoNotUse, { RouteName, RouteOptionsGeneratedDoNotUse } from './routesGeneratedDoNotUse.gen';

export type RouteOptions = {
  /**
   * Get the label of a routeName
   */
  label?: string;
} & RouteOptionsGeneratedDoNotUse;

const routesKeysWithLabels: Partial<Record<RouteName, string>> = {
  INDEX_DASHBOARD: 'Dashboard',
  ACCOUNT_HOME: 'Home',
  ACCOUNT_HARDWARE_SETTINGS: 'Hardware',
  ACCOUNT_INSIGHTS_SETTINGS: 'Insights',
  ACCOUNT_SCHEDULED_REPORTS_SETTINGS: 'Scheduled reports',
  ACCOUNT_SETTINGS: 'Account',
  ACCOUNT_WARRANTY_COVERAGE_SETTINGS: 'Warranty coverage',
  ASSET_HARDWARE_INDEX: 'All Hardware',
  ASSET_SOFTWARE_INDEX: 'All Software',
  ASSET_SAAS_INDEX: 'Microsoft 365',
  DISPOSAL_INDEX: 'Next order',
  DISPOSAL_ORDER_LIST: 'Orders',
  NOTIFICATION_SETTINGS: 'My notifications',
  ORGANIZATION_ASSESSMENT_EVALUATION_LIST: 'Assessments',
  ORGANIZATION_ASSESSMENT_EVALUATION_PERFORM: 'Perform',
  ORGANIZATION_ASSESSMENT_EVALUATION_RESULTS: 'Results',
  ORGANIZATION_DASHBOARD: 'Overview',
  ORGANIZATION_ENGAGEMENT_ACTION_ITEM_LIST: 'Activities',
  ORGANIZATION_ENGAGEMENT_NOTE_LIST: 'Activities',
  ORGANIZATION_HARDWARE: 'Hardware',
  ORGANIZATION_HARDWARE_SETTINGS: 'Hardware',
  ORGANIZATION_INDEX: 'Clients',
  ORGANIZATION_NAME_AND_LINKS: 'Name & links',
  ORGANIZATION_PEOPLE: 'Users',
  ORGANIZATION_CONTACT: 'Users',
  ORGANIZATION_REGIONAL_SETTINGS: 'Regional',
  ORGANIZATION_SCHEDULED_REPORTS_SETTINGS: 'Scheduled reports',
  ORGANIZATION_SOFTWARE: 'Software',
  ORGANIZATION_STRATEGY_CONTRACT_LIST: 'Contracts',
  ORGANIZATION_STRATEGY_ROADMAP_VIEW: 'Roadmap',
  ORGANIZATION_STRATEGY_SCORECARD_VIEW: 'Scorecards',
  ORGANIZATION_BUDGET_FORECAST: 'Forecast',
  ORGANIZATION_WARRANTIES_CART_SERVER_NETWORK: 'Cart',
  ORGANIZATION_WARRANTIES_CART_WORKSTATION: 'Cart',
  ORGANIZATION_WARRANTY_SETTINGS: 'Warranty',
  ORGANIZATION_SOURCES: 'Sources',
  STRATEGY_INSIGHTS_DASHBOARD: 'Insights Dashboard',
  STRATEGY_ROADMAP_ACCOUNT_VIEW: 'Roadmap (all clients)',
  USER_LIST: 'Members',
  WARRANTIES_BUY_COVERAGE: 'Buy',
  WARRANTIES_COVERED: 'Covered assets',
  WARRANTIES_SERVICE_REQUESTS: 'Service requests',
  WARRANTIES_TRANSFER: 'Transfer',
  WARRANTIES_SUMMARY: 'Summary',
  DISPOSAL_ORDER_INFO: 'Order summary',
  ACCOUNT_INTEGRATION_ISSUE: 'Issues',
  ACCOUNT_INTEGRATION_INDEX: 'Integrations',
  ACCOUNT_INTEGRATION_ADD: 'Add',
  ACCOUNT_INTEGRATION_EDIT: 'Edit',
  ACCOUNT_INTEGRATION_ADD_INDEX: 'Add',
  HARDWARE_VIEW: 'Hardware',
  HARDWARE_VIEW_SOFTWARE: 'Software',
  MARKETPLACE_INFRASTRUCTURE_PROTECTION: 'Infrastructure Protection',
  ORGANIZATION_MARKETPLACE_CART_INFRASTRUCTURE_PROTECTION: 'Infrastructure Protection cart',
  ORGANIZATION_MARKETPLACE_CHECKOUT_INFRASTRUCTURE_PROTECTION: 'Infrastructure Protection checkout',
  MARKETPLACE_WORKSTATION_ASSURANCE: 'Workstation Assurance',
  ORGANIZATION_MARKETPLACE_CART_WORKSTATION_ASSURANCE: 'Workstation Assurance cart',
  ORGANIZATION_MARKETPLACE_CHECKOUT_WORKSTATION_ASSURANCE: 'Workstation Assurance checkout',
  MARKETPLACE_COVERED: 'Covered assets',
  MARKETPLACE_SERVICE_REQUESTS: 'Service requests',
  ASSESSMENT_TEMPLATE_SETTINGS: 'Assessment templates',
  ASSESSMENT_TEMPLATE_EDIT: 'Edit',
};

const routes: Record<RouteName, RouteOptions> = Object.entries(routesGeneratedDoNotUse).reduce<Record<RouteName, RouteOptions>>(
  (acc, [key, value]) => ({
    [key]: { ...value, label: routesKeysWithLabels[key as RouteName] || undefined } satisfies RouteOptions,
    ...acc,
  }),
  {} as Record<RouteName, RouteOptions>,
);

export default routes;
