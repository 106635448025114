import { DmiScorePreviewDto } from '@AssetManagementClient/AssetManagement/Packages/Dmi/Packages/Widget/Model/DmiScorePreviewComponentNested.gen';
import { SimplePreviewCountDto } from '@AssetManagementClient/Dashboard/Sdk/SharedComponent/Model/Preview/SimplePreviewCountComponentNested.gen';
import Window from '~/neo-ui/packages/window/Window';
import { css } from '@emotion/react';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import BadgeMarketingPro from '~/neo-ui/packages/badge/packages/badge-marketing/packages/badge-marketing-pro/BadgeMarketingPro';
import Button from '~/neo-ui/packages/button/Button';
import routes from '~/router/types/routes';
import * as React from 'react';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import DmiScoreComponent from '~/DashboardSharedComponent/packages/dmi-score/DmiScoreComponent';
import Anchor from '~/neo-ui/packages/anchor/Anchor';

export type WidgetPreviewConfig = {
  'dmi-score-preview': { data: DmiScorePreviewDto };
  'simple-preview-count': { data: SimplePreviewCountDto };
};

export type WidgetPreviewComponentId = keyof WidgetPreviewConfig;

export type WidgetPreviewWindowProps = {
  isOpen: boolean;
  onClose: () => void;
  id: WidgetPreviewComponentId;
} & WidgetPreviewConfig[WidgetPreviewComponentId];

const WidgetPreviewWindow = ({ isOpen, onClose, id, data }: WidgetPreviewWindowProps) => {
  const getContent = () => {
    switch (id) {
      case 'dmi-score-preview':
        const dmiData = data as DmiScorePreviewDto;
        return (
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
            `}
          >
            <DmiScoreComponent
              scoreOn10000={dmiData.scoreOn10000}
              bandLabel={dmiData.bandLabel}
              scoreDisplay={dmiData.scoreDisplay}
              themeColor={dmiData.themeColor}
            />
            <Label
              muted={true}
              css={css`
                margin: 1.5rem 0;
              `}
            >
              The Digital Maturity Index (DMI) is a credit score between 300 and 850 that gauges the state of your client's technology.
            </Label>
            <Label muted={true}>The overall DMI for all your clients is {dmiData.scoreDisplay}</Label>
            <Label muted={true}>
              Upgrade to
              <BadgeMarketingPro
                size={'xs'}
                shouldInvertColors={true}
                css={css`
                  vertical-align: text-top;
                `}
              />
              to unlock the DMI score for each of your clients.
            </Label>
          </div>
        );

      case 'simple-preview-count':
        const countData = data as SimplePreviewCountDto;
        return (
          <div>
            <Label muted={true}>
              Upgrade to
              <BadgeMarketingPro
                size={'xs'}
                shouldInvertColors={true}
                css={css`
                  vertical-align: text-top;
                `}
              />
              {countData.description}
            </Label>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <Window
      title={''}
      isOpen={isOpen}
      onDismiss={onClose}
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 4rem;
      `}
      maxWidthRem={55}
    >
      <Header
        size={4}
        weight={'bold'}
      >
        You've discovered a Lifecycle Manager
        <BadgeMarketingPro
          css={css`
            margin: 0 0.3125rem 2rem 0.3125rem;
          `}
        />
        feature!
      </Header>
      {getContent()}
      {data.learnMoreCta && (
        <Anchor
          href={data.learnMoreCta}
          css={css`
            margin-top: 0.5rem;
          `}
        >
          Learn more
        </Anchor>
      )}
      <Button
        size={'lg'}
        theme={'positive'}
        iconLeft={'UpgradeNow'}
        anchor={{ href: routes.ACCOUNT_BILLING.build() }}
        css={css`
          margin-top: 2rem;
        `}
      >
        Upgrade to PRO
      </Button>
    </Window>
  );
};

export default WidgetPreviewWindow;
