import useDisposalOrderInfoMutation, {
  UseDisposalOrderInfoMutationOptions,
} from '~/wm/packages/disposal/packages/order-info/hooks/useDisposalOrderInfoMutation';
import { disposalOrderPackagePurchase } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { DisposalOrderPackagePurchasePayloadDto as AssetManagementClientBeastClientBeastDisposalPackagesDisposalOrderPackagesDisposalOrderPackageDtoModelDisposalOrderPackagePurchasePayloadDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Packages/DisposalOrderPackage/Dto/Model.gen';

export type DisposalOrderPackagePurchasePayload = {
  disposalOrderId: string;
  packageCount: number | undefined;
  packagePurchasePayloads:
    | AssetManagementClientBeastClientBeastDisposalPackagesDisposalOrderPackagesDisposalOrderPackageDtoModelDisposalOrderPackagePurchasePayloadDto[]
    | undefined;
};

export type UseDisposalOrderPackagePurchase = () => {
  packagePurchase: (request: DisposalOrderPackagePurchasePayload, isRequestActive?: () => boolean) => void;
  isPurchasingPackage: boolean;
};

const useDisposalOrderPackagePurchase: UseDisposalOrderPackagePurchase = (options?: UseDisposalOrderInfoMutationOptions) => {
  const [packagePurchase, isPurchasingPackage] = useDisposalOrderInfoMutation(
    (request: DisposalOrderPackagePurchasePayload) =>
      disposalOrderPackagePurchase({
        disposalOrderId: request.disposalOrderId,
        packageCount: request.packageCount,
        packagePurchasePayloads: request.packagePurchasePayloads,
      }),
    options,
  );

  return { packagePurchase, isPurchasingPackage };
};

export default useDisposalOrderPackagePurchase;
