import {
  OauthTile,
  OauthTileProps,
} from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/credentials/OauthTile';
import invariant from 'tiny-invariant';
import { formatDateFriendly } from '~/extensions/packages/date/formatDate';
import { useEffect } from 'react';
import useAlert from '~/neo-ui/packages/toast/hooks/useAlert';
import { CredentialFieldDisplaySettingsDto } from '@AssetManagementClient/AssetManagement/Packages/Integration/IntegrationPage/Dto.gen';

export type OauthTileControllerProps = {
  integrationStatus: string | undefined;
  integrationCredentials: { [key in string]: CredentialFieldDisplaySettingsDto };
  isDisabled: boolean;

  // I'm pretty sure integrationObfuscatedId will never be undefined, because once someone has saved their oauth credentials, we set an integration id.
  // However, the PHP logic does assume it could be undefined, so I'm leaving that in.
  integrationObfuscatedId: string | undefined;
  oauthUrl: string;
  redirectUri: string;
};

export const calculateOauthInformation = (
  integrationStatus: string | undefined,
): {
  status: OauthTileProps['status'];
  title: string;
  description: string;
} => {
  if (integrationStatus === undefined) {
    return {
      status: 'noLastAttempt',
      title: 'Authorize ScalePad',
      description: 'Authorize ScalePad using the client ID you have entered above to sync your data.',
    };
  }
  const integrationStatusParsed = JSON.parse(integrationStatus) as { StatusUpdated: string; Status: string };

  if (integrationStatusParsed.Status === 'Success') {
    const statusUpdated = formatDateFriendly(new Date(integrationStatusParsed.StatusUpdated));

    return {
      status: 'lastAttemptSuccess',
      title: "We're authorized!",
      description: `ScalePad last authorized on ${statusUpdated}`,
    };
  }

  return {
    status: 'lastAttemptFailed',
    title: 'Authorization Failed',
    description: 'Authorize ScalePad using the client ID you have entered above to sync your data.',
  };
};

const calculateOauthUrl = (
  integrationObfuscatedId: string | undefined,
  oauthUrl: string,
  integrationCredentials: { [key in string]: CredentialFieldDisplaySettingsDto },
  redirectUri: string,
): string => {
  let updatedUrl = oauthUrl;

  for (const key in integrationCredentials) {
    if (integrationCredentials.hasOwnProperty(key)) {
      const value = integrationCredentials[key].value;
      if (!value) {
        continue;
      }
      const placeholder = new RegExp(`{{${key}}}`, 'gi');
      updatedUrl = updatedUrl.replace(placeholder, value);
    }
  }

  return `${updatedUrl}&redirect_uri=${redirectUri}${integrationObfuscatedId ? '&state=' + integrationObfuscatedId : ''}`;
};

export const OauthTileController: React.FunctionComponent<OauthTileControllerProps> = ({
  integrationStatus,
  integrationObfuscatedId,
  isDisabled,
  integrationCredentials,
  oauthUrl,
  redirectUri,
}) => {
  invariant(!!integrationCredentials, 'clientId is required for OauthTileController');
  const { status, title, description } = calculateOauthInformation(integrationStatus);

  const sendAlert = useAlert();

  // Adding event listeners here to catch a custom OAuth event from integration-oauth-done.php. There's probably a better way to communicate during OAuth setup but I don't know what it is.
  useEffect(() => {
    const onError = ((e: CustomEvent<{ messagePublic: string }>) => {
      sendAlert({
        toastType: 'error',
        title: e.detail.messagePublic ?? 'There was a problem authorizing this integration',
        description: `Try authorizing again or contact support if the problem persists.`,
        icon: 'Danger',
        theme: 'negative',
        buttonLeft: {
          label: 'Refresh',
          iconLeft: 'Reload',
          onClick: () => window.location.reload(),
        },
        isNonDismissible: true,
      });
      // Typescript doesn't come with build in definitions for custom events, so I'm casting this to a built - in event.
      // Breaks type checking of the function input. Be careful!
    }) as EventListener;

    const onSuccess = ((e: CustomEvent<{ messagePublic: string }>) => {
      sendAlert({
        toastType: 'notification',
        title: e.detail.messagePublic ?? 'Integration authorized successfully',
        description: '',
        icon: 'Success',
        theme: 'positive',
        isNonDismissible: false,
      });
      // As for onError:
      // Typescript doesn't come with build in definitions for custom events, so I'm casting this to a built - in event.
      // Breaks type checking of the function input. Be careful!
    }) as EventListener;
    window.addEventListener('OAuthError', onError);
    window.addEventListener('OAuthSuccess', onSuccess);
    return () => {
      window.removeEventListener('OAuthError', onError);
      window.removeEventListener('OAuthSuccess', onSuccess);
    };
    // Even though sendAlert is listed as a dependency, I don't think it will ever change since it comes in as a callback from useAlert, which takes no arguments.
  }, [sendAlert]);

  const oauthUrlWithParams = calculateOauthUrl(integrationObfuscatedId, oauthUrl, integrationCredentials, redirectUri);

  return (
    <OauthTile
      onClick={() => {
        window.open(oauthUrlWithParams, '_blank', 'width=800,height=800');
      }}
      isDisabled={isDisabled}
      status={status}
      title={title}
      description={description}
    />
  );
};
