import { css } from '@emotion/react';
import Toolbar from '~/neo-ui/packages/layout/packages/toolbar/Toolbar';
import LayoutHeaderFooterProps from '~/neo-ui/packages/layout/types/LayoutHeaderFooterProps';
import LayoutBar from '~/neo-ui/packages/layout/packages/bar/LayoutBar';
import { isIframe } from '~/extensions/packages/iframe/isIframe';
import { useEffect, useRef } from 'react';
import { enceladusMainBodyPaddingRem } from '~/neo-ui/packages/layout/packages/layout-enceladus/packages/enceladus-body/EnceladusBody';

/**
 * A header that sticks to the bottom of the nav bar.
 */
const LayoutHeaderEnceladus = ({ className, leftControls, rightControls, layoutBarComponent }: LayoutHeaderFooterProps) => {
  const isInsideIframe = isIframe();
  const body = isInsideIframe ? document.getElementById('wm-content') : document.getElementById('enceladus-main-body');
  const headerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (headerRef.current && body) {
      headerRef.current.style.marginLeft = `-${enceladusMainBodyPaddingRem}rem`;
      headerRef.current.style.marginRight = `-${enceladusMainBodyPaddingRem}rem`;
      headerRef.current.style.marginTop = `-${enceladusMainBodyPaddingRem}rem`;
    }
  }, [headerRef, body, isInsideIframe]);

  return (
    <div
      ref={headerRef}
      // WM-6038 Hacky solution to prevent header from covering critical errors
      id={'layout-header'}
      className={className}
      css={css`
        position: sticky;
        margin-bottom: 1.25rem;
        z-index: 1040;
        top: ${body && isInsideIframe ? 0 : `-${enceladusMainBodyPaddingRem}rem`};
      `}
    >
      {(leftControls || rightControls) && (
        <Toolbar
          css={css`
            border-top: none;
          `}
          leftControls={leftControls}
          rightControls={rightControls}
        />
      )}
      {layoutBarComponent && (
        <LayoutBar
          css={css`
            border-top: none;
          `}
        >
          {layoutBarComponent}
        </LayoutBar>
      )}
    </div>
  );
};

export default LayoutHeaderEnceladus;
