import Label from '~/neo-ui/packages/text/packages/label/Label';
import SelectList from '~/neo-ui/packages/list/packages/select-list/SelectList';
import * as React from 'react';
import SelectOptionGroup from '~/neo-ui/packages/select/model/SelectOptionGroup';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import { css } from '@emotion/react';
import Icon from '~/neo-ui/packages/icon/Icon';
import Box from '~/neo-ui/packages/box/Box';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import BadgeMarketingPro from '~/neo-ui/packages/badge/packages/badge-marketing/packages/badge-marketing-pro/BadgeMarketingPro';

export type DownloadMenuSectionProps<DownloadAction extends string> = {
  heading: string;
  description: string;
  assetDownloadOptions: SelectOptionGroup<DownloadAction>[];
  onOptionSelected?: (option: SelectOption<DownloadAction>) => void;

  /**
   * If an upgrade is required to download the report:
   * Disables the input and shows pro badge
   */
  hasUpgradeRequired?: boolean;
};

const DownloadMenuSection = <DownloadAction extends string>({
  heading,
  description,
  assetDownloadOptions,
  onOptionSelected,
  hasUpgradeRequired = false,
}: DownloadMenuSectionProps<DownloadAction>) => (
  <div>
    <div
      css={css`
        display: flex;
        align-items: flex-start;
        flex-direction: column;
      `}
    >
      <Label
        size={'lg'}
        bold={true}
      >
        {heading}
        {hasUpgradeRequired && (
          <BadgeMarketingPro
            size={'xs'}
            css={css`
              margin-left: 0.625rem;
            `}
          />
        )}
      </Label>
      <Label
        muted={true}
        css={css`
          margin-bottom: 10px;
        `}
      >
        {description}
      </Label>
    </div>
    {hasUpgradeRequired ? (
      assetDownloadOptions
        .flatMap(option => option.options)
        .map((option, index) => (
          <Box
            key={index}
            borderRadius={'radius400'}
            padding={'padding100'}
            css={css`
              background-color: ${colorToCode('dark-900-08')};
              display: flex;
              align-items: center;
              gap: 0.875rem;
            `}
          >
            {option.prependIcon && (
              <Icon
                icon={option.prependIcon}
                sizePx={option.iconSizePx}
                color={'dark-900-64'}
              />
            )}
            <Label
              muted={true}
              bold={true}
            >
              {option.label}
            </Label>
          </Box>
        ))
    ) : (
      <SelectList
        options={assetDownloadOptions}
        selectedOptionValue={undefined}
        bgColor={'secondary-050'}
        horizontalDividers={false}
        boldOptions={true}
        optionPadding={'0.625rem'}
        onOptionSelected={onOptionSelected as (option: SelectOption) => void}
        isHorizontal={true}
        usesInversion={true}
      />
    )}
  </div>
);
export default DownloadMenuSection;
