import Box from '~/neo-ui/packages/box/Box';
import { FormTextboxDisplay } from '~/neo-ui/packages/form/packages/form-input/packages/form-textbox-input/FormTextboxInput';
import IntegrationSetupPageSubSection from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/page-layout/IntegrationSetupPageSubSection';
import { UseFormRegister } from 'react-hook-form';
import { SyncPreferencesData } from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/hooks/useSyncPreferencesForm';

export type NicknameBoxProps = {
  placeholder: string;
  register: UseFormRegister<SyncPreferencesData>;
};
export const NicknameBox: React.FunctionComponent<NicknameBoxProps> = ({ placeholder, register }) => (
  <Box
    borderRadius={'radius300'}
    padding={'padding200'}
  >
    <IntegrationSetupPageSubSection
      title={'Name'}
      headerMarginBottom={'0.5rem'}
    >
      <FormTextboxDisplay
        type={'text'}
        placeholder={placeholder}
        propsForHtmlInputComponent={register('nickname')}
      />
    </IntegrationSetupPageSubSection>
  </Box>
);
