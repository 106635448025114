import { FunctionComponent } from 'react';
import Text from '~/sp-ui/text/Text';
import Message from '~/sp-ui/message';

const ProductAccessBanner: FunctionComponent = () => {
  return (
    <Message type="info">
      <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <Text variant="body">
          <span>Product access</span>{' '}
        </Text>
        <Text variant="small">
          <span>
            After inviting users to ScalePad Hub, you can manage product access within the individual products of your subscription.
          </span>{' '}
        </Text>
      </div>
    </Message>
  );
};

export default ProductAccessBanner;
