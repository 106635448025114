import PopoverWithPopup from '~/neo-ui/packages/popover/PopoverWithPopup';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import DownloadMenuSection from '~/neo-ui/packages/table/packages/console/download/DownloadMenuSection';
import Divider from '~/neo-ui/packages/divider/Divider';
import Icon from '~/neo-ui/packages/icon/Icon';
import SelectOptionGroup from '~/neo-ui/packages/select/model/SelectOptionGroup';
import { css } from '@emotion/react';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import useDownloadLifecycleReportPdf from '~/neo-ui/packages/table/packages/console/download/hooks/useDownloadLifecycleReportPdf';
import { FrontendScope } from '@AssetManagementClient/Scoping/Model.gen';
import useLifecycleReportSettings from '~/neo-ui/packages/table/packages/console/download/hooks/useLifecycleReportSettings';
import { TrackingServices, useEventTracking } from '~/extensions/packages/tracking/hooks/useEventTracking';
import { Fragment, useState } from 'react';
import CustomReportDownloadWindow from '~/neo-ui/packages/table/packages/console/download/packages/custom-hardware-report-download-window/CustomReportDownloadWindow';
import useDownloadContactReportM365Pdf from '~/neo-ui/packages/table/packages/console/download/hooks/useDownloadContactReportM365Pdf';
import useDownloadContactReportCustomizedPdf from '~/neo-ui/packages/table/packages/console/download/hooks/useDownloadContactReportCustomizedPdf';
import { Enum as AssetScopeEnum } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/AssetScope/Logic/AssetScopeFactoryNested.gen';
import useDownloadLifecycleReportCustomizedPdf from '~/neo-ui/packages/table/packages/console/download/hooks/useDownloadLifecycleReportCustomizedPdf';
import useFeatureFlagProvider from '~/router/feature-flag-provider/hooks/useFeatureFlagProvider';
import useFeatureAvailabilitiesContext from '~/wm/packages/feature/context/hooks/useFeatureAvailabilitiesContext';
import AnchorWrapper from '~/neo-ui/packages/anchor/packages/anchor-wrapper/AnchorWrapper';

export type ReportDownloadButtonProps = {
  assetType: AssetScopeEnum;
  organizationId: string;
  frontendScope: FrontendScope;
  canDownload?: boolean;
  lifecycleReportDownloadLocation: string;
  hasHardwareLifecycleReportAccess: boolean;
};

export type DownloadAction =
  | 'download-lifecycle-report-customized-pdf'
  | 'download-lifecycle-report-pdf'
  | 'download-contact-report-customized-pdf'
  | 'download-contact-report-m365-pdf';

const ReportDownloadButton = ({
  assetType,
  organizationId,
  frontendScope,
  canDownload,
  lifecycleReportDownloadLocation,
  hasHardwareLifecycleReportAccess,
}: ReportDownloadButtonProps) => {
  const { trackEvent: trackEventProcessor } = useEventTracking(TrackingServices.EventProcessor);
  const { 'lm-essential': enabledLmEssential } = useFeatureFlagProvider();
  const { hasFeaturePermissionOrganization } = useFeatureAvailabilitiesContext();

  const { lifecycleReportSettings } = useLifecycleReportSettings(organizationId);
  const { downloadLifecycleReport } = useDownloadLifecycleReportPdf();
  const { downloadLifecycleReportCustomized } = useDownloadLifecycleReportCustomizedPdf(frontendScope);
  const { downloadContactReportM365 } = useDownloadContactReportM365Pdf(organizationId);
  const { downloadContactReportCustomized } = useDownloadContactReportCustomizedPdf(organizationId);

  const [isPdfDownloadModalOpen, setIsPdfDownloadModalOpen] = useState<
    { title: string; onDownload: (pdfName: string) => void } | undefined
  >(undefined);

  if (typeof lifecycleReportSettings === 'undefined') {
    return null;
  }

  const optionSelectedCallback = (option: SelectOption<DownloadAction>, callback: () => void) => {
    switch (option.value) {
      case 'download-lifecycle-report-customized-pdf':
        setIsPdfDownloadModalOpen({ title: 'Customized Hardware Report', onDownload: downloadLifecycleReportCustomized });
        break;
      case 'download-lifecycle-report-pdf':
        downloadLifecycleReport(
          organizationId,
          {
            showCpu: lifecycleReportSettings.showCpuColumn,
            showRam: lifecycleReportSettings.showRamColumn,
            showStorage: lifecycleReportSettings.showStorageColumn,
            showLastCheckInDate: lifecycleReportSettings.showLastCheckInDateColumn,
          },
          false,
        );

        trackEventProcessor('report_hw_downloaded', {
          obfuscatedClientId: organizationId,
          downloadSource: lifecycleReportDownloadLocation,
        });
        break;
      case 'download-contact-report-customized-pdf':
        setIsPdfDownloadModalOpen({ title: 'Customized User Report', onDownload: downloadContactReportCustomized });
        break;
      case 'download-contact-report-m365-pdf':
        downloadContactReportM365();
        break;
    }
    callback();
  };

  const downloadContactReportM365Options: SelectOptionGroup<DownloadAction>[] = [
    {
      options: [
        {
          value: 'download-contact-report-m365-pdf',
          label: 'Download M365 Report',
          labelColor: 'primary-500',
          prependIcon: 'DownloadDocument',
          iconSizePx: 22,
          disabled: false,
        },
      ],
    },
  ];

  const downloadContactReportCustomOptions: SelectOptionGroup<DownloadAction>[] = [
    {
      options: [
        {
          value: 'download-contact-report-customized-pdf',
          label: 'Download Custom Report',
          labelColor: 'primary-500',
          prependIcon: 'DownloadDocument',
          iconSizePx: 22,
          disabled: false,
        },
      ],
    },
  ];

  const downloadCustomizedLifecycleReportOptions: SelectOptionGroup<DownloadAction>[] = [
    {
      options: [
        {
          value: 'download-lifecycle-report-customized-pdf',
          label: 'Download Report',
          labelColor: 'primary-500',
          prependIcon: 'DownloadDocument',
          iconSizePx: 22,
          disabled: false,
        },
      ],
    },
  ];

  const downloadLifecycleReportOptions: SelectOptionGroup<DownloadAction>[] = [
    {
      options: [
        {
          value: 'download-lifecycle-report-pdf',
          label: 'PDF File',
          labelColor: 'negative-700',
          prependIcon: 'PDF',
          iconSizePx: 22,
          disabled: false,
        },
      ],
    },
  ];

  return assetType !== AssetScopeEnum.Hardware && assetType !== AssetScopeEnum.Contact ? null : (
    <Fragment>
      <PopoverWithPopup
        // Hardware console has sample downloads to display
        disabled={assetType !== AssetScopeEnum.Hardware && !canDownload}
        header={
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 5px;
            `}
          >
            <span>
              {(() => {
                switch (assetType) {
                  case AssetScopeEnum.Hardware:
                    return 'Hardware';
                  case AssetScopeEnum.Contact:
                    return 'User';
                  default:
                    return 'Hardware';
                }
              })()}{' '}
              Report
            </span>
            <Icon
              icon={'Download'}
              color={'dark-900'}
            />
          </div>
        }
        popoverAlign={'left-end'}
        css={css`
          width: clamp(12.5rem, 29.375rem, 90vw);
        `}
      >
        {(closeModal: () => void) => (
          <div
            css={css`
              padding: 0.625rem 0.75rem;
            `}
          >
            <Label
              className={'download-header'}
              size={'xlg'}
              color={'secondary-400'}
              bold={true}
              css={css`
                padding-bottom: 0.3125rem;
                display: flex;
                align-items: flex-start;
              `}
            >
              Download
            </Label>
            {assetType === AssetScopeEnum.Hardware && canDownload && (
              <Fragment>
                <DownloadMenuSection
                  heading={'Customized Hardware Report'}
                  description={"With the columns and filters you've selected."}
                  assetDownloadOptions={downloadCustomizedLifecycleReportOptions}
                  hasUpgradeRequired={
                    enabledLmEssential && !hasFeaturePermissionOrganization('custom-hardware-report-download', organizationId)
                  }
                  onOptionSelected={option => {
                    optionSelectedCallback(option, closeModal);
                  }}
                />
                <Divider
                  css={css`
                    margin: 1rem 0;
                  `}
                />
              </Fragment>
            )}
            {assetType === AssetScopeEnum.Hardware && hasHardwareLifecycleReportAccess && canDownload && (
              <DownloadMenuSection
                heading={'Hardware Lifecycle Report'}
                description={'With default columns and without filters.'}
                assetDownloadOptions={downloadLifecycleReportOptions}
                onOptionSelected={option => {
                  optionSelectedCallback(option, closeModal);
                }}
              />
            )}
            {assetType === AssetScopeEnum.Hardware && hasHardwareLifecycleReportAccess && !canDownload && (
              <AnchorWrapper
                href={'/assets/reports/hardware/ScalePad-Example-Hardware-Lifecycle-Report.pdf'}
                download={'Lifecycle-Manager-Example-Hardware-Lifecycle-Report.pdf'}
                openInNewTab={true}
              >
                <DownloadMenuSection
                  heading={'Preview - Example Hardware Lifecycle Report'}
                  description={'With default columns and without filters.'}
                  assetDownloadOptions={downloadLifecycleReportOptions}
                />
              </AnchorWrapper>
            )}
            {assetType === AssetScopeEnum.Contact && canDownload && (
              <Fragment>
                <DownloadMenuSection
                  heading={'Microsoft 365 User Report'}
                  description={'Report includes all Microsoft 365 data for users belonging to this client.'}
                  assetDownloadOptions={downloadContactReportM365Options}
                  onOptionSelected={option => {
                    optionSelectedCallback(option, closeModal);
                  }}
                />
                <Divider
                  css={css`
                    margin: 1rem 0;
                  `}
                />
              </Fragment>
            )}
            {assetType === AssetScopeEnum.Contact && canDownload && (
              <DownloadMenuSection
                heading={'Custom User Report'}
                description={"With the columns and filters you've selected."}
                assetDownloadOptions={downloadContactReportCustomOptions}
                onOptionSelected={option => {
                  optionSelectedCallback(option, closeModal);
                }}
              />
            )}
          </div>
        )}
      </PopoverWithPopup>
      <CustomReportDownloadWindow
        isWindowOpen={typeof isPdfDownloadModalOpen !== 'undefined'}
        onWindowClose={() => setIsPdfDownloadModalOpen(undefined)}
        onDownload={isPdfDownloadModalOpen?.onDownload ?? (() => {})}
        defaultPdfName={isPdfDownloadModalOpen?.title ?? ''}
      />
    </Fragment>
  );
};

export default ReportDownloadButton;
