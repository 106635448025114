import Button from '~/neo-ui/packages/button/Button';
import { borderRadiusToCode } from '~/neo-ui/packages/style/BorderRadius';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Video from '~/neo-ui/packages/video/Video';
import SalesPitchItemElement from '~/wm/packages/product/packages/product-tile/sales-pitch-item/SalesPitchItemElement';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import useFeatureFlagProvider from '~/router/feature-flag-provider/hooks/useFeatureFlagProvider';
import { generatePath } from 'react-router-dom';
import routes from '~/router/types/routes';

interface ProductCta {
  CtaLabel: string;
  CtaUrl: string;
  ProductFamily: string;
  OpenInNewTab: boolean;
}

interface SalesPitchListItem {
  Summary: string;
  Description: string;
}

export interface ProductTileV2Props {
  ProductLogoWithProductNameUrl: string;
  ProductSubtitle: string;
  ProductSummary: string;
  ProductCta?: ProductCta;
  ProductCtaOnClick?: () => void;
  ProductTestimonialUrl?: string;
  ProductTestimonialThumbnail?: string;
  SalesPitchSubtitle: string;
  SalesPitch: { SalesPitchItems: SalesPitchListItem[] };
  ContinueToProductLabel?: string;
  ContinueToProductUrl: string;
  ContinueToProductOnClick?: () => void;
  BookDemoLabel: string;
  BookDemoUrl: string;
  BookDemoOnClick?: () => void;
  OnVideoWatched?: (videoRef: React.MutableRefObject<HTMLVideoElement>, watchTime: number) => void;
}

const ProductTileV2: React.FunctionComponent<ProductTileV2Props> = ({
  ProductLogoWithProductNameUrl,
  ProductSubtitle,
  ProductSummary,
  ProductCta,
  ProductCtaOnClick,
  ProductTestimonialUrl,
  ProductTestimonialThumbnail,
  SalesPitchSubtitle,
  SalesPitch,
  ContinueToProductLabel,
  ContinueToProductUrl,
  ContinueToProductOnClick,
  BookDemoLabel,
  BookDemoUrl,
  BookDemoOnClick,
  OnVideoWatched,
}) => {
  const { 'purchase-quoter': enableNewPurchaseExperience } = useFeatureFlagProvider();
  const ctaUrl =
    ProductCta === undefined
      ? BookDemoUrl
      : enableNewPurchaseExperience
      ? generatePath(routes.ACCOUNT_BILLING_UPGRADE.route, { productFamily: ProductCta?.ProductFamily.toLowerCase() })
      : ProductCta?.CtaUrl;

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        borderTopLeftRadius: borderRadiusToCode('radius400'),
        borderTopRightRadius: borderRadiusToCode('radius400'),
        padding: '0 1.5rem 0 1.5rem',
      }}
    >
      <img
        css={{
          marginTop: '0.75rem',
          height: '2rem',
        }}
        src={ProductLogoWithProductNameUrl}
        alt={ProductSubtitle}
      />
      <div
        css={{
          paddingTop: '0.5rem',
          fontSize: '1.125rem',
          fontWeight: 500,
          lineHeight: '1.6875rem',
        }}
      >
        {ProductSubtitle}
      </div>
      <div
        css={{
          minHeight: '6.25rem',
          padding: '1rem 0 1rem 0',
          lineHeight: '1.7',
        }}
      >
        {ProductSummary}
      </div>
      <div
        css={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingBottom: '1.5rem',
        }}
      >
        <Button
          size={'lg'}
          theme={'primary'}
          anchor={{ href: ctaUrl, openInNewTab: ProductCta?.OpenInNewTab }}
          onClick={ProductCta ? ProductCtaOnClick : BookDemoOnClick}
          css={{
            height: '2.75rem',
            minWidth: '10.75rem',
            fontSize: '0.875rem',
            fontWeight: 400,
          }}
        >
          {ProductCta?.CtaLabel ?? BookDemoLabel}
        </Button>
      </div>
      {ProductTestimonialUrl && ProductTestimonialThumbnail && (
        <Video
          source={ProductTestimonialUrl}
          thumbnail={ProductTestimonialThumbnail}
          maxWidth={'20rem'}
          onStopWatching={OnVideoWatched}
        />
      )}
      <Header
        size={3}
        css={{ padding: '2rem 0 2rem 0', fontWeight: 500, lineHeight: '1.75rem' }}
      >
        {SalesPitchSubtitle}
      </Header>
      {SalesPitch.SalesPitchItems.map((SalesPitchItem, key) => (
        <SalesPitchItemElement
          key={key}
          summary={SalesPitchItem.Summary}
          description={SalesPitchItem.Description}
          icon={'Positive'}
          iconColor={'positive-400'}
        />
      ))}
      <div
        css={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '0.5rem',
          marginTop: '0.5rem',
        }}
      >
        {ContinueToProductLabel && (
          <ButtonLink
            anchor={{ href: ContinueToProductUrl, openInNewTab: true }}
            onClick={ContinueToProductOnClick}
          >
            <div css={{ textDecoration: 'underline' }}>{ContinueToProductLabel}</div>
          </ButtonLink>
        )}
        {ProductCta && (
          <>
            <div
              css={{
                height: '18px',
                border: `1px solid ${colorToCode('light-700')}`,
              }}
            />
            <ButtonLink
              anchor={{ href: BookDemoUrl, openInNewTab: true }}
              onClick={BookDemoOnClick}
            >
              <div css={{ textDecoration: 'underline' }}>{BookDemoLabel}</div>
            </ButtonLink>
          </>
        )}
      </div>
    </div>
  );
};

export default ProductTileV2;
