import { css } from '@emotion/react';
import Search from '~/neo-ui/packages/search/Search';
import Card from '~/legacy-ui/packages/card/Card';
import Info from '~/legacy-ui/packages/info/Info';
import FormErrorMessage from '~/neo-ui/packages/form/packages/form-display/packages/form-error-message/FormErrorMessage';
import React from 'react';
import useOrganizationContactListContext from '~/wm/packages/organization/packages/organization-contact/context/hooks/useOrganizationContactListContext';
import { AutoEmailRecipient } from '~/wm/packages/organization/packages/auto-email/packages/auto-email-recipients/AutoEmailRecipients';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { AutoEmailRecipientAddFormData } from '~/wm/packages/organization/packages/auto-email/packages/auto-email-recipients/packages/auto-email-recipient-add-form/AutoEmailRecipientAddForm';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import { StringPropertyNames } from '~/neo-ui/packages/search/types/searchTypes';

type OrganizationContactSearchResult = {
  organizationContactId: string;
  title: string | undefined;
  fullName: string;
  email: string;
  canReceiveEmail: boolean;
};

export type AutoEmailSuggestedRecipientInputProps = {
  autofillKey: StringPropertyNames<OrganizationContactSearchResult>;
  fieldKey: FieldKeyExpression<AutoEmailRecipientAddFormData>;
  placeholder: string;
  existingRecipients: AutoEmailRecipient[];
  onResultSelected: (searchResult: OrganizationContactSearchResult) => void;
};

const AutoEmailSuggestedRecipientInput = ({
  autofillKey,
  fieldKey,
  placeholder,
  existingRecipients,
  onResultSelected,
}: AutoEmailSuggestedRecipientInputProps) => {
  const { setFormInput, getFormInput, setFieldTouched } = useFormContext<AutoEmailRecipientAddFormData>();
  const { organizationContacts } = useOrganizationContactListContext();

  const organizationContactSearchResults: OrganizationContactSearchResult[] = organizationContacts.map(contact => ({
    organizationContactId: contact.organizationContactId,
    title: contact.title,
    fullName: contact.fullName,
    email: contact.email,
    canReceiveEmail: contact.canReceiveEmail,
  }));

  const notExistingRecipients = organizationContactSearchResults.filter(
    organizationContact => !existingRecipients.some(existingRecipient => existingRecipient.emailAddress === organizationContact.email),
  );

  const [filteredRecipients, setFilteredRecipients] = React.useState<OrganizationContactSearchResult[]>(notExistingRecipients);

  const hasError = getFormInput(fieldKey).error !== undefined;

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
      `}
    >
      <Search
        itemKey={autofillKey}
        tabIndex={1}
        placeholder={placeholder}
        selection={{
          multiSelect: false,
          onResultSelected: result => {
            onResultSelected(result);
          },
        }}
        renderSearchRow={recipient => (
          <Card
            padding={'sm'}
            backgroundColor={'light-200'}
          >
            <Info
              title={recipient.fullName}
              titleSize={'sm'}
              description={recipient.email}
              descriptionMuted={true}
            />
          </Card>
        )}
        options={filteredRecipients}
        onInputChange={async text => {
          await setFormInput<string>(fieldKey, text);
          setFilteredRecipients(
            notExistingRecipients.filter(
              recipient =>
                recipient.email.toLowerCase().includes(text.toLowerCase()) || recipient.fullName.toLowerCase().includes(text.toLowerCase()),
            ),
          );
        }}
        onBlur={() => {
          setFieldTouched(fieldKey, true);
        }}
      />
      {hasError && (
        <FormErrorMessage
          fieldKey={fieldKey}
          validationErrorColor={'negative-400'}
        />
      )}
    </div>
  );
};

export default AutoEmailSuggestedRecipientInput;
