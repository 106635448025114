import Window from '~/neo-ui/packages/window/Window';
import Button from '~/neo-ui/packages/button/Button';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import AutoEmailReportDeleteButton from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-delete/AutoEmailReportDeleteButton';
import React, { useState } from 'react';
import AutoEmailReportFormData, {
  autoEmailReportConsoleStateFieldKey,
  autoEmailReportTemplateFieldKey,
  autoEmailReportTitleFieldKey,
} from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-form/types/AutoEmailReportFormData';
import AutoEmailReportFormInputs from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-form/AutoEmailReportFormInputs';
import { ConsoleState } from '~/neo-ui/packages/table/packages/console/types';

export type AutoEmailReportEditWindowProps = {
  autoEmailReportId: string;
  isOpen: boolean;
  onDismiss: () => void;
};

const AutoEmailReportEditWindow = ({ autoEmailReportId, isOpen, onDismiss }: AutoEmailReportEditWindowProps) => {
  const { submitForm, isSubmitting, getFormInput, setFormInput } = useFormContext<AutoEmailReportFormData>();
  const title = getFormInput<string>(autoEmailReportTitleFieldKey).value;

  const [consoleState, setConsoleState] = useState<ConsoleState>(getFormInput<ConsoleState>(autoEmailReportConsoleStateFieldKey).value);
  const [shouldRemoveTemplate, setShouldRemoveTemplate] = useState<boolean>(
    typeof getFormInput<string | undefined>(autoEmailReportTemplateFieldKey).value === 'undefined',
  );

  return (
    <Window
      title={'Edit Scheduled Report'}
      isOpen={isOpen}
      onDismiss={onDismiss}
      footerProps={{
        leftControls: [
          {
            expanded: (
              <AutoEmailReportDeleteButton
                autoEmailReportId={autoEmailReportId}
                reportTitle={title}
                onDelete={onDismiss}
              />
            ),
          },
        ],
        rightControls: [
          {
            expanded: (
              <Button
                iconLeft={'Done'}
                theme={'primary'}
                loading={isSubmitting}
                onClick={async () => {
                  if (shouldRemoveTemplate) {
                    await setFormInput<undefined>(autoEmailReportTemplateFieldKey, undefined);
                  }
                  await setFormInput<ConsoleState>(autoEmailReportConsoleStateFieldKey, consoleState);
                  await submitForm();
                }}
              >
                Save
              </Button>
            ),
          },
        ],
      }}
    >
      <AutoEmailReportFormInputs
        consoleState={consoleState}
        setConsoleState={(consoleState, removeAutoEmailTemplate) => {
          setShouldRemoveTemplate(removeAutoEmailTemplate);
          setConsoleState(consoleState);
        }}
      />
    </Window>
  );
};

export default AutoEmailReportEditWindow;
