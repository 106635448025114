import useExploreProductsGet from '~/wm/packages/subscription/packages/product-selection/hooks/useExploreProductsGet';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import ProductSelectionGrid from '~/wm/packages/subscription/packages/product-selection/packages/product-selection-grid/ProductSelectionGrid';
import Spinner from '~/neo-ui/spinner/Spinner';

const ProductSelectionPage = () => {
  const { exploreProductGroups } = useExploreProductsGet();

  if (!exploreProductGroups) {
    return <Spinner />;
  }

  return (
    <div css={{ display: 'flex', flexDirection: 'column', textAlign: 'center', maxWidth: '75rem', margin: 'auto' }}>
      <Header
        size={1}
        weight={'light'}
        css={{ marginTop: '1rem', marginBottom: '0.5rem' }}
      >
        Equipping Your MSP Adventure
      </Header>
      <span css={{ fontSize: '1rem', marginBottom: '3rem' }}>Gear up with apps, services, and guidance for the road ahead.</span>

      <ProductSelectionGrid
        exploreProducts={exploreProductGroups.notOwnedProducts}
        isOwnedProduct={false}
      />

      {exploreProductGroups.ownedProducts.length > 0 && (
        <div>
          <Header
            size={3}
            css={{
              textAlign: 'left',
              margin: '3rem 0 1.5rem 1rem',
            }}
          >
            Already in your subscription
          </Header>
          <ProductSelectionGrid
            exploreProducts={exploreProductGroups.ownedProducts}
            isOwnedProduct={true}
          />
        </div>
      )}
    </div>
  );
};

export default ProductSelectionPage;
