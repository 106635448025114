import { arrow, autoPlacement, autoUpdate, offset, useDismiss, useFloating, useHover, useInteractions } from '@floating-ui/react';
import { HTMLProps, RefObject, useState } from 'react';

const useTooltip = (
  options: {
    arrowRef?: RefObject<Element>;
    anchorProps?: HTMLProps<Element>;
    tooltipProps?: HTMLProps<HTMLElement>;
  } = {},
) => {
  const [open, setOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    placement: 'top',
    middleware: [
      offset(16),
      autoPlacement(),
      options.arrowRef &&
        arrow({
          element: options.arrowRef,
        }),
    ],
  });

  const hover = useHover(context, { move: false });
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([hover, dismiss]);

  return {
    context,
    tooltipContext: context,
    setAnchorRef: refs.setReference,
    setTooltipRef: refs.setFloating,
    anchorProps: getReferenceProps(options.anchorProps),
    tooltipProps: getFloatingProps(options.tooltipProps),
    tooltipStyles: floatingStyles,
  };
};

export default useTooltip;
