import { createContext } from 'react';
import { EmailFrequencyDto } from '@BeastClient/Beast/AutoEmail/Packages/AutoEmailReport/Dto/Model.gen';

export type AutoEmailAvailabilitiesContextData = {
  emailFrequencies: EmailFrequencyDto[];
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const AutoEmailAvailabilitiesContext = createContext<AutoEmailAvailabilitiesContextData | undefined>(undefined);

export default AutoEmailAvailabilitiesContext;
