import Label from '~/neo-ui/packages/text/packages/label/Label';
import { css } from '@emotion/react';
import FormTitleInput from '~/neo-ui/packages/form/packages/form-input/packages/form-title-input/FormTitleInput';
import AutoEmailReportFormData, {
  autoEmailReportColumnKeysFieldKey,
  autoEmailReportSaveAsTemplateFieldKey,
  autoEmailReportSortFieldKey,
  autoEmailReportTemplateFieldKey,
  autoEmailReportTitleFieldKey,
  generateColumnKeys,
} from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-form/types/AutoEmailReportFormData';
import FormSelectInput from '~/neo-ui/packages/form/packages/form-input/packages/form-select-input/FormSelectInput';
import { FilterBar } from '~/neo-ui/packages/table/packages/console/filter-display/FilterBar';
import FormCheckboxInput from '~/neo-ui/packages/form/packages/form-input/packages/form-checkbox-input/FormCheckboxInput';
import { ConsoleState, SortState } from '~/neo-ui/packages/table/packages/console/types';
import React, { useCallback } from 'react';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import useAutoEmailReportListContext from '~/wm/packages/organization/packages/auto-email-report/context/hooks/useAutoEmailReportListContext';
import { produce } from 'immer';
import { convertToMapNestedArrays } from '~/extensions/packages/iterable/keyedNestedArrayConverter';
import Divider from '~/neo-ui/packages/divider/Divider';
import ButtonLink from '~/neo-ui/packages/button/packages/button-link/ButtonLink';
import InputCheckbox from '~/neo-ui/packages/input/packages/input-checkbox/InputCheckbox';
import AutoEmailReportFormInputColumn from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-form/packages/auto-email-report-form-input-column/AutoEmailReportFormInputColumn';
import { ColumnAvailabilityDto } from '@BeastClient/Search/Model/Console/Dto.gen';

export type AutoEmailReportFormInputsProps = {
  consoleState: ConsoleState;
  setConsoleState: (consoleState: ConsoleState, removeAutoEmailTemplate: boolean) => void;
};

const AutoEmailReportFormInputs = ({ consoleState, setConsoleState }: AutoEmailReportFormInputsProps) => {
  const { setFormInput } = useFormContext<AutoEmailReportFormData>();
  const { autoEmailReportTemplateAvailabilities, autoEmailReportAvailability } = useAutoEmailReportListContext();

  const onFilterChange = useCallback(
    async (key: string, value: Map<string, string[]>) => {
      const newMap = new Map(consoleState.parameters);
      if (value.size === 0) {
        newMap.delete(key);
      } else {
        newMap.set(key, value);
      }

      setConsoleState({ ...consoleState, parameters: newMap }, true);
    },
    [consoleState, setConsoleState],
  );

  const clearFilters = React.useCallback(async () => {
    setConsoleState(
      produce(consoleState, (draft: ConsoleState) => {
        draft.parameters.clear();
        draft.search = undefined;
      }),
      true,
    );

    await setFormInput<undefined>(autoEmailReportTemplateFieldKey, undefined);
  }, [consoleState, setConsoleState, setFormInput]);

  return (
    <div>
      <Label
        bold={true}
        css={css`
          margin-bottom: 1rem;
        `}
      >
        Report Name
      </Label>
      <FormTitleInput
        fieldKey={autoEmailReportTitleFieldKey}
        placeholder={'Enter report name'}
        canClear={true}
      />
      <Label
        bold={true}
        css={css`
          margin-top: 1.5rem;
          margin-bottom: 1rem;
        `}
      >
        Apply Template
      </Label>
      <FormSelectInput
        fieldKey={autoEmailReportTemplateFieldKey}
        options={autoEmailReportTemplateAvailabilities.map(option => ({
          value: option.autoEmailReportTemplateId,
          label: option.title,
        }))}
        fromFormData={formData => (typeof formData !== 'undefined' ? formData : '')}
        onSelect={async value => {
          const selectedTemplate = autoEmailReportTemplateAvailabilities.find(
            availability => availability.autoEmailReportTemplateId === value,
          );

          if (typeof selectedTemplate !== 'undefined') {
            await setFormInput<{
              [key in string]: boolean;
            }>(
              autoEmailReportColumnKeysFieldKey,
              generateColumnKeys(
                autoEmailReportAvailability.columns
                  .filter(column => column.availability !== ColumnAvailabilityDto.Mandatory)
                  .map(column => column.key.value),
                false,
                selectedTemplate.reportQuery.selectedColumns?.map(column => column.value) ?? [],
              ),
            );
            await setFormInput<SortState | undefined>(
              autoEmailReportSortFieldKey,
              selectedTemplate.reportQuery.sort.length === 0
                ? undefined
                : {
                    key: {
                      value: selectedTemplate.reportQuery.sort[0].field.value,
                    },
                    order: 'ascending',
                  },
            );
            setConsoleState(
              {
                search: undefined,
                pagination: { perPageSize: 0, pageNumber: 0 },
                parameters: convertToMapNestedArrays(selectedTemplate.reportQuery.parameters),
                sort: undefined,
              },
              false,
            );
          }
          await setFormInput<boolean>(autoEmailReportSaveAsTemplateFieldKey, false);
        }}
        placeholder={'Select a template...'}
      />
      <Divider
        css={css`
          margin: 1.5rem 0;
        `}
      />
      <Label
        bold={true}
        css={css`
          margin-bottom: 1rem;
        `}
      >
        Report Parameters
        <ButtonLink
          onClick={clearFilters}
          css={css`
            margin-left: 1.5rem;
          `}
        >
          Clear All
        </ButtonLink>
      </Label>
      <FilterBar
        filters={autoEmailReportAvailability.filters.map(filter => ({
          key: filter.key.value,
          label: filter.label,
          tags: new Map(Object.entries(filter.tags)),
          order: filter.order,
          render: filter.render,
          isEnabled: filter.isEnabled,
        }))}
        consoleState={consoleState}
        onFilterChange={onFilterChange}
      />
      <Divider
        css={css`
          margin: 1.5rem 0;
        `}
      />
      <Label
        bold={true}
        css={css`
          margin-bottom: 1rem;
        `}
      >
        Fixed Report Columns
      </Label>
      <div
        css={css`
          display: flex;
          gap: 1.5rem;
          margin-bottom: 1.5rem;
        `}
      >
        <InputCheckbox
          checked={true}
          disabled={true}
          description={'Asset Name'}
        />
        <InputCheckbox
          checked={true}
          disabled={true}
          description={'Serial'}
        />
      </div>
      <Label
        bold={true}
        css={css`
          margin-bottom: 1rem;
        `}
      >
        Additional Report Columns
      </Label>
      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
          gap: 1.5rem;
        `}
      >
        {autoEmailReportAvailability.columns
          .filter(column => column.availability !== ColumnAvailabilityDto.Mandatory)
          .map(column => (
            <AutoEmailReportFormInputColumn
              key={column.key.value}
              column={column}
            />
          ))}
      </div>
      <Divider
        css={css`
          margin: 1.5rem 0;
        `}
      />
      <Label
        bold={true}
        css={css`
          margin-bottom: 1rem;
        `}
      >
        Sort By
      </Label>
      <FormSelectInput
        fieldKey={autoEmailReportSortFieldKey}
        placeholder={'Select a column'}
        options={autoEmailReportAvailability.columns
          .filter(column => column.isSortable)
          .map(column => ({
            label: column.label,
            value: column.key.value,
          }))}
        fromFormData={(formData: SortState) =>
          typeof formData !== 'undefined' ? formData.key.value : autoEmailReportAvailability.columns[0].key.value
        }
        toFormData={(value): SortState => ({
          key: { value },
          order: 'ascending',
        })}
        onSelect={() => setFormInput<undefined>(autoEmailReportTemplateFieldKey, undefined)}
      />
      <Divider
        css={css`
          margin: 1.5rem 0;
        `}
      />
      <FormCheckboxInput
        fieldKey={autoEmailReportSaveAsTemplateFieldKey}
        description={'Save as Template'}
        onInput={() => setFormInput<undefined>(autoEmailReportTemplateFieldKey, undefined)}
      />
    </div>
  );
};

export default AutoEmailReportFormInputs;
