import { useContext } from 'react';
import OrganizationContactListContext from '~/wm/packages/organization/packages/organization-contact/context/OrganizationContactListContext';

const useOrganizationContactListContext = () => {
  const organizationContactListContext = useContext(OrganizationContactListContext);
  if (!organizationContactListContext) {
    throw new Error('Tried to use OrganizationContactListContext outside of an OrganizationContactListProvider');
  }

  return organizationContactListContext;
};

export default useOrganizationContactListContext;
