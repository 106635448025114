import { css } from '@emotion/react';
import Box from '~/neo-ui/packages/box/Box';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import AutoEmailReportEditButton from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-edit/packages/auto-email-report-edit-button/AutoEmailReportEditButton';
import AutoEmailReportSettingsParameters from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-settings/packages/auto-email-report-settings-parameters/AutoEmailReportSettingsParameters';
import AutoEmailReportSettingsSorting from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-settings/packages/auto-email-report-settings-sorting/AutoEmailReportSettingsSorting';
import AutoEmailReportSettingsColumns from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-settings/packages/auto-email-report-settings-columns/AutoEmailReportSettingsColumns';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import AutoEmailControls from '~/wm/packages/organization/packages/auto-email/packages/auto-email-controls/AutoEmailControls';
import useAutoEmailReportScheduleUpdate from '~/wm/packages/organization/packages/auto-email-report/hooks/useAutoEmailReportScheduleUpdate';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import AutoEmailRecipients from '~/wm/packages/organization/packages/auto-email/packages/auto-email-recipients/AutoEmailRecipients';
import useAutoEmailReportRecipientAdd from '~/wm/packages/organization/packages/auto-email-report/hooks/useAutoEmailReportRecipientAdd';
import useAutoEmailReportRecipientRemove from '~/wm/packages/organization/packages/auto-email-report/hooks/useAutoEmailReportRecipientRemove';
import AutoEmailPreview from '~/wm/packages/organization/packages/auto-email/packages/auto-email-preview/AutoEmailPreview';
import routes from '~/router/types/routes';
import AutoEmailTestMessageButton from '~/wm/packages/organization/packages/auto-email/packages/auto-email-test-message-button/AutoEmailTestMessageButton';
import useAutoEmailReportPdfPreview from '~/wm/packages/organization/packages/auto-email-report/hooks/useAutoEmailReportPdfPreview';
import useAutoEmailReportTestSend from '~/wm/packages/organization/packages/auto-email-report/hooks/useAutoEmailReportTestSend';
import { useState } from 'react';
import { AutoEmailReportDto } from '@BeastClient/Beast/AutoEmail/Packages/AutoEmailReport/Dto/Model.gen';
import useOrganizationContactListMutationContext from '~/wm/packages/organization/packages/organization-contact/context/hooks/useOrganizationContactListMutationContext';

export type AutoEmailReportProps = {
  autoEmailReport: AutoEmailReportDto;
};

const AutoEmailReport = ({ autoEmailReport }: AutoEmailReportProps) => {
  const { organizationId } = useOrganizationContext();
  const { triggerOrganizationContactListReload } = useOrganizationContactListMutationContext();
  const { updateAutoEmailReportSchedule, isUpdatingAutoEmailReportSchedule } = useAutoEmailReportScheduleUpdate();
  const { addAutoEmailReportRecipient, isAddingAutoEmailReportRecipient } = useAutoEmailReportRecipientAdd({
    onSuccess: async () => {
      await triggerOrganizationContactListReload();
    },
  });
  const { removeAutoEmailReportRecipient, isRemovingAutoEmailReportRecipient } = useAutoEmailReportRecipientRemove(
    organizationId,
    autoEmailReport.autoEmailReportId,
    {
      onSuccess: async () => {
        await triggerOrganizationContactListReload();
      },
    },
  );
  const { sendAutoEmailReportTest, isSendingAutoEmailReportTest } = useAutoEmailReportTestSend(organizationId, {
    onSuccess: () => setAutoEmailTestMessageModalOpen(false),
  });
  const { downloadAutoEmailReportPdfPreview } = useAutoEmailReportPdfPreview();

  const [isAutoEmailTestMessageModalOpen, setAutoEmailTestMessageModalOpen] = useState(false);

  return (
    <Box
      borderRadius={'radius300'}
      padding={'padding400'}
      css={css`
        display: flex;
        flex-direction: column;
        gap: 2.25rem;
        transition: all 200ms ease-in-out;
        ${!autoEmailReport.isScheduled &&
        css`
          background-color: ${colorToCode('light-200')};
          box-shadow: 0 0 0 0.0625rem ${colorToCode('light-500')};
        `}
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <Header
          size={3}
          weight={'bold'}
          color={!autoEmailReport.isScheduled ? 'dark-900-32' : undefined}
        >
          {autoEmailReport.title}
        </Header>
      </div>
      <AutoEmailControls
        isAutoEmailEnabled={autoEmailReport.isScheduled}
        frequencyEnum={autoEmailReport.emailFrequency}
        hasRecipients={autoEmailReport.autoEmailReportRecipients.length > 0}
        nextOccurrence={autoEmailReport.nextSendAt}
        onEnablementChange={isAutoEmailEnabled => {
          updateAutoEmailReportSchedule({
            organizationId,
            autoEmailReportId: autoEmailReport.autoEmailReportId,
            autoEmailReportScheduleUpdatePayload: {
              autoEmailReportFrequency: autoEmailReport.emailFrequency,
              isScheduled: isAutoEmailEnabled,
            },
          });
        }}
        onFrequencyChange={emailFrequency => {
          updateAutoEmailReportSchedule({
            autoEmailReportId: autoEmailReport.autoEmailReportId,
            organizationId,
            autoEmailReportScheduleUpdatePayload: {
              autoEmailReportFrequency: emailFrequency,
              isScheduled: autoEmailReport.isScheduled,
            },
          });
        }}
        isUpsertingSettings={isUpdatingAutoEmailReportSchedule}
      />
      <Box
        background={'foregroundAccent'}
        theme={'none'}
        padding={'padding200'}
        css={
          autoEmailReport.isScheduled
            ? undefined
            : css`
                background-color: ${colorToCode('light-200')};
              `
        }
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 1rem;
          `}
        >
          <Header
            size={4}
            weight={'bold'}
            color={!autoEmailReport.isScheduled ? 'dark-900-32' : undefined}
          >
            Report Settings
          </Header>
          <AutoEmailReportEditButton autoEmailReport={autoEmailReport} />
        </div>
        <AutoEmailReportSettingsParameters
          autoEmailReport={autoEmailReport}
          css={css`
            margin-bottom: 1.5rem;
          `}
        />
        <div
          css={css`
            display: flex;
            gap: 1.5rem;
          `}
        >
          <AutoEmailReportSettingsSorting autoEmailReport={autoEmailReport} />
          <AutoEmailReportSettingsColumns autoEmailReport={autoEmailReport} />
        </div>
      </Box>
      <div
        css={css`
          display: flex;
          gap: 1.75rem;
        `}
      >
        <AutoEmailRecipients
          recipients={autoEmailReport.autoEmailReportRecipients}
          isAddingRecipient={isAddingAutoEmailReportRecipient}
          onAddRecipient={addRecipientPayload => {
            addAutoEmailReportRecipient({
              organizationId,
              autoEmailReportId: autoEmailReport.autoEmailReportId,
              autoEmailReportRecipientAddPayload: {
                fullName: `${addRecipientPayload.firstName}${
                  typeof addRecipientPayload.lastName !== 'undefined' ? ` ${addRecipientPayload.lastName}` : ''
                }`,
                emailAddress: addRecipientPayload.email,
              },
            });
          }}
          isRemovingRecipient={isRemovingAutoEmailReportRecipient}
          onRemoveRecipient={emailAddress => {
            const autoEmailReportRecipient = autoEmailReport.autoEmailReportRecipients.find(
              recipient => recipient.emailAddress === emailAddress,
            );

            if (typeof autoEmailReportRecipient !== 'undefined') {
              removeAutoEmailReportRecipient(autoEmailReportRecipient.id);
            }
          }}
          css={css`
            flex-basis: 100%;
          `}
        />
        <div
          css={css`
            flex-basis: 100%;
            display: flex;
            flex-direction: column;
            gap: 0.9375rem;
          `}
        >
          <AutoEmailPreview
            replyToDisplay={autoEmailReport.autoEmailReportEmailTemplatePreview.replyToDisplay}
            subjectDisplay={autoEmailReport.autoEmailReportEmailTemplatePreview.subjectDisplay}
            bodyDisplay={autoEmailReport.autoEmailReportEmailTemplatePreview.bodyDisplay}
            editTemplateUrl={routes.ORGANIZATION_CUSTOM_SCHEDULED_REPORTS_EMAIL_SETTINGS.build([
              organizationId,
              autoEmailReport.autoEmailReportId,
            ])}
            isReplyToUserEmailBouncing={autoEmailReport.autoEmailReportEmailTemplatePreview.replyToUserEmailBouncing}
            onClickDownloadPreviewPdf={() => {
              downloadAutoEmailReportPdfPreview({
                organizationId,
                autoEmailReportId: autoEmailReport.autoEmailReportId,
              });
            }}
          />
          <AutoEmailTestMessageButton
            isOpen={isAutoEmailTestMessageModalOpen}
            setIsOpen={setAutoEmailTestMessageModalOpen}
            isSendingAutoEmailTest={isSendingAutoEmailReportTest}
            onClickSendTest={() => {
              sendAutoEmailReportTest(autoEmailReport.autoEmailReportId);
            }}
          />
        </div>
      </div>
    </Box>
  );
};

export default AutoEmailReport;
