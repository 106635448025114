import Header from '~/neo-ui/packages/text/packages/header/Header';
import { Styleable } from '~/neo-ui/model/capacity';
import Box from '~/neo-ui/packages/box/Box';
import { css } from '@emotion/react';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Button from '~/neo-ui/packages/button/Button';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import Tooltip from '~/neo-ui/packages/tooltip/Tooltip';
import Icon from '~/neo-ui/packages/icon/Icon';
import useAccount from '~/wm/hooks/useAccount';

export type AutoEmailPreviewProps = {
  replyToDisplay: string;
  subjectDisplay: string;
  bodyDisplay: string;
  editTemplateUrl: string;
  isReplyToUserEmailBouncing: boolean;
  onClickDownloadPreviewPdf: () => void;
} & Styleable;

const AutoEmailPreview = ({
  replyToDisplay,
  subjectDisplay,
  bodyDisplay,
  editTemplateUrl,
  isReplyToUserEmailBouncing,
  onClickDownloadPreviewPdf,
  className,
}: AutoEmailPreviewProps) => {
  const { organizationName } = useOrganizationContext();
  const { subscriptionIdentity } = useAccount();

  return (
    <div
      className={className}
      css={css`
        display: flex;
        flex-direction: column;
        gap: 1rem;
      `}
    >
      <Header
        size={4}
        weight={'bold'}
      >
        Preview
      </Header>
      <Box
        borderRadius={'radius300'}
        boxShadow={'shadow100'}
      >
        <Box
          background={'foregroundAccent'}
          theme={'none'}
          padding={'padding300'}
          css={css`
            display: flex;
            justify-content: space-between;
            gap: 0.3125rem;
            width: 100%;
            border-radius: 0.5rem 0.5rem 0 0;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: 0.3125rem;
            `}
          >
            <div
              css={css`
                display: flex;
                gap: 0.25rem;
              `}
            >
              <Label bold={true}>Reply-To:</Label>
              <Label>{replyToDisplay}</Label>
            </div>
            <div
              css={css`
                display: flex;
                gap: 0.25rem;
              `}
            >
              <Label bold={true}>Subject: </Label>
              <Label>{subjectDisplay}</Label>
            </div>
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: 0.5rem;
              flex-shrink: 0;
            `}
          >
            <Button
              iconLeft={'Edit'}
              anchor={{
                href: editTemplateUrl,
              }}
            >
              Edit template
            </Button>
            {isReplyToUserEmailBouncing && (
              <Tooltip content={'The email you chose may not exist. If it looks correct, please contact our support team.'}>
                <Icon
                  icon={'Warning'}
                  color={'warning-400'}
                />
              </Tooltip>
            )}
          </div>
        </Box>
        <Box
          padding={'padding300'}
          css={css`
            display: flex;
            flex-direction: column;
            gap: 0.625rem;
            width: 100%;
            border-radius: 0 0 0.5rem 0.5rem;
          `}
        >
          <Label
            css={css`
              white-space: pre-line;
            `}
          >
            {bodyDisplay}
          </Label>
          {subscriptionIdentity.isLmFree ? (
            <Button
              iconLeft={'PDF'}
              css={css`
                align-self: flex-start;
              `}
              anchor={{
                href: '/assets/reports/hardware/ScalePad-Example-Hardware-Lifecycle-Report.pdf',
                openInNewTab: true,
                download: 'Lifecycle-Manager-Example-Hardware-Lifecycle-Report.pdf',
              }}
            >
              Preview - Example Hardware Lifecycle Report
            </Button>
          ) : (
            <Button
              iconLeft={'PDF'}
              css={css`
                align-self: flex-start;
              `}
              onClick={() => onClickDownloadPreviewPdf()}
            >
              Preview - {organizationName}.pdf
            </Button>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default AutoEmailPreview;
