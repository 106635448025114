import React from 'react';
import { css } from '@emotion/react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import WarrantyCartCostLoader from '~/wm/packages/warranty/packages/warranty-cart/packages/warranty-cart-footer/packages/warranty-cart-cost-loader/WarrantyCartCostLoader';
import Icon from '~/neo-ui/packages/icon/Icon';
import AnchorWrapper from '~/neo-ui/packages/anchor/packages/anchor-wrapper/AnchorWrapper';
import routes from '~/router/types/routes';
import { Styleable } from '~/neo-ui/model/capacity';
import BadgeMarketingPro from '~/neo-ui/packages/badge/packages/badge-marketing/packages/badge-marketing-pro/BadgeMarketingPro';

export type WarrantyCartFooterDiscountSectionProps = {
  totalCostSubunits: number;
  formatCostSubunits: (costSubunits: number) => string;
  isLoading?: boolean;
} & Styleable;

const WarrantyCartFooterDiscountSection = ({
  totalCostSubunits,
  formatCostSubunits,
  isLoading = false,
  className,
}: WarrantyCartFooterDiscountSectionProps) => {
  return (
    <div
      css={css`
        background: linear-gradient(90deg, ${colorToCode('primary-400')}, ${colorToCode('secondary-400')} 100%);
        color: ${colorToCode('light-000')};
        padding: 0.5rem 1rem;
      `}
      className={className}
    >
      <Label
        css={css`
          display: inline-flex;
          align-items: baseline;
          gap: 0.3125rem;
        `}
      >
        Save
        <Label
          size={'lg'}
          bold={true}
        >
          {formatCostSubunits(totalCostSubunits / 5)}
        </Label>
        {isLoading && <WarrantyCartCostLoader color={'light-000'} />}
        on this order when you upgrade to
        <AnchorWrapper
          href={routes.ACCOUNT_BILLING.build()}
          openInNewTab={true}
          css={css`
            display: inline-flex;
            align-items: baseline;
            gap: 0.3125rem;
          `}
        >
          <b>Lifecycle Manager</b>
          <BadgeMarketingPro
            size={'xs'}
            shouldInvertColors={true}
          />
          <Icon
            icon={'GoExternal'}
            color={'light-000'}
          />
        </AnchorWrapper>
      </Label>
    </div>
  );
};

export default WarrantyCartFooterDiscountSection;
