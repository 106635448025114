import React from 'react';
import {
  applyToInnerRecordValues,
  applyToRecordValues,
  arrayOfDatesToPatchToObject,
  objectOfDatesToPatchToArray,
} from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/utils';
import {
  FilterDisplaySettingsDto,
  IntegrationPreferences,
  ValueMappingDisplaySettingsDto,
} from '@AssetManagementClient/AssetManagement/Packages/Integration/IntegrationPage/Dto.gen';
import { Request as IntegrationSetupUpsertRequest } from '@AssetManagementClient/AssetManagement/Packages/Integration/IntegrationPage/Controller/IntegrationSetupUpsertControllerNested.gen';
import { FieldValueSelectedFilter } from '@AssetManagementClient/SyncGod/Shared/ApiHandler/Tag/Selected.gen';
import { IntegrationSetupSubmitFunction } from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/hooks/useIntegrationSetupAndDefinitions';
import { useForm } from 'react-hook-form';
import { PatchDeviceDateEnum } from '@AssetManagementClient/BeastClient/SyncGod/Shared/ApiHandler/Request/Payload/Action/PatchDevice.gen';
import { filterUnassignedOptions } from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/sync-preferences/SyncPreferences';
import invariant from 'tiny-invariant';
import useAlert from '~/neo-ui/packages/toast/hooks/useAlert';
import { ApiServerErrorResponseDto } from '~/wm/packages/api/packages/api-error/model/ApiServerErrorResponseDto';
import captureException from '~/extensions/packages/sentry/captureException';

export type CheckboxInfo = { id: string; label: string; selected: boolean };
export type DatesToPatch = { [key in PatchDeviceDateEnum]: boolean };
export type FormSelectedMappings = { [payloadType: string]: { [mappingSection: string]: { [mapFromId: string]: string | null } } };
export type SyncPreferencesData = {
  nickname?: string;
  datesToPatch?: DatesToPatch;
  datesToPatchCustomFields?: DatesToPatch;
  dontSkipDevicesWithBlankSerialNumber?: boolean;
  maxDaysSinceLastCheckIn?: number;
  maxDaysSinceLastCheckInEnabled?: boolean;
  filters?: {
    [payloadType: string]: {
      [filterName: string]: CheckboxInfo[];
    };
  };
  selectedMappings?: FormSelectedMappings;
};

export const getMatchingSectionKey = (
  sectionKey: string,
  payloadRecord: { [key in string]: ValueMappingDisplaySettingsDto },
): string | undefined => Object.values(payloadRecord).find(dto => dto.name === sectionKey)?.name;

export const defaultStringKey = 'defaultKey';

export const setSelectedValueMappings = (valueMappingDisplaySettings: IntegrationPreferences['valueMappingDisplaySettings']) => {
  return applyToRecordValues(valueMappingDisplaySettings, (payloadRecord: { [key in string]: ValueMappingDisplaySettingsDto }) => {
    const selectedMappingsForForm: Record<string, Record<string, string | null>> = {};
    Object.values(payloadRecord).forEach(mappingDto => {
      mappingDto.mapFromOptions.forEach(({ id, childSectionKey, childOptions }) => {
        const sectionName = mappingDto.name;
        childOptions?.forEach(({ id: childId }) => {
          // Defaults to parent section key
          const childKey = getMatchingSectionKey(childSectionKey ?? sectionName, payloadRecord) ?? sectionName;
          selectedMappingsForForm[childKey] =
            // react-hook-form uses string matching to determine whether to represent the form data as an array, for some reason.
            // If all the keys in an object are strings of numbers, the form representation will be an array.
            // We put a string key defaultStringKey in the defaultFormData object so that react-hook-form creates an object (not an array) representation of the form data.
            // Note: if for some reason we have a mapping option that matched the value of defaultStringKey that's ok. It can be overwritten.
            // Note also that items with null mappings are not sent to the backend.
            selectedMappingsForForm[childKey] === undefined ? { [defaultStringKey]: null } : selectedMappingsForForm[childKey];
          selectedMappingsForForm[childKey][childId] = payloadRecord[childKey]?.selectedMappings[childId]?.selectedValue ?? null;
        });
        selectedMappingsForForm[sectionName] =
          selectedMappingsForForm[sectionName] === undefined ? { [defaultStringKey]: null } : selectedMappingsForForm[sectionName];
        selectedMappingsForForm[sectionName][id] = mappingDto.selectedMappings[id]?.selectedValue ?? null;
      });
    });
    return selectedMappingsForForm;
  });
};

export type UseSyncPreferencesFormProps = {
  integrationPreferences: IntegrationPreferences | undefined;
  submitIntegrationSetup: IntegrationSetupSubmitFunction;
  integrationId: string | undefined;
  integrationSetupId: string | undefined;
};
export const useSyncPreferencesForm = ({
  integrationPreferences,
  submitIntegrationSetup,
  integrationId,
  integrationSetupId,
}: UseSyncPreferencesFormProps) => {
  const integrationSettings = integrationPreferences?.generalSettings;

  const defaultFormData: SyncPreferencesData = React.useMemo(
    () => ({
      datesToPatch: integrationSettings?.datesToPatch
        ? arrayOfDatesToPatchToObject(integrationSettings.datesToPatch)
        : ({} as DatesToPatch),
      datesToPatchCustomFields: integrationSettings?.datesToPatchToCustomFields
        ? arrayOfDatesToPatchToObject(integrationSettings.datesToPatchToCustomFields)
        : ({} as DatesToPatch),
      dontSkipDevicesWithBlankSerialNumber:
        integrationSettings?.skipDevicesWithBlankSerialNumber === undefined ? false : !integrationSettings.skipDevicesWithBlankSerialNumber,
      maxDaysSinceLastCheckIn:
        integrationSettings?.maxDaysSinceLastCheckIn !== undefined
          ? integrationSettings.maxDaysSinceLastCheckIn
          : integrationPreferences?.isMaxDaysSinceLastCheckInSupported
          ? 30
          : undefined,
      maxDaysSinceLastCheckInEnabled:
        integrationSettings?.maxDaysSinceLastCheckIn !== undefined
          ? Boolean(integrationSettings.maxDaysSinceLastCheckIn)
          : integrationPreferences?.isMaxDaysSinceLastCheckInSupported === undefined
          ? undefined
          : false,
      filters: integrationPreferences?.filterDisplaySettings
        ? applyToRecordValues(
            integrationPreferences.filterDisplaySettings,
            (payloadNameToDtos: { [payloadName: string]: FilterDisplaySettingsDto }, payloadName) =>
              applyToRecordValues(payloadNameToDtos, (dto, sectionName) =>
                dto.options.map(d => ({
                  label: d.label,
                  id: d.id,

                  selected:
                    integrationPreferences?.filterDisplaySettings[payloadName][sectionName].optionsSelected?.some(
                      option => option.id === d.id,
                    ) || false,
                })),
              ),
          )
        : {},
      selectedMappings: integrationPreferences?.valueMappingDisplaySettings
        ? setSelectedValueMappings(integrationPreferences.valueMappingDisplaySettings)
        : {},
      nickname: integrationPreferences?.nickname,
    }),
    [
      integrationPreferences?.filterDisplaySettings,
      integrationPreferences?.isMaxDaysSinceLastCheckInSupported,
      integrationSettings?.datesToPatch,
      integrationSettings?.datesToPatchToCustomFields,
      integrationSettings?.maxDaysSinceLastCheckIn,
      integrationPreferences?.valueMappingDisplaySettings,
      integrationSettings?.skipDevicesWithBlankSerialNumber,
      integrationPreferences?.nickname,
    ],
  );

  const useFormReturn = useForm<SyncPreferencesData>({
    defaultValues: defaultFormData,
    // Note that we don't validate the form.
  });

  const sendAlert = useAlert();

  const { reset, handleSubmit } = useFormReturn;

  React.useEffect(() => {
    // When defaultFormData changes, probably because integrationPreferences changed, reset the form data.
    reset(defaultFormData);
  }, [defaultFormData, reset]);

  const submitSyncPreferences = handleSubmit(async (formData: SyncPreferencesData) => {
    // In practice, we know the integration id either from PHP (new integration) or from the dto (update integration) once the page is loaded. This should never happen, it's just there to make typescript happy.
    invariant(integrationId, 'Integration ID must be defined');

    const request: IntegrationSetupUpsertRequest = {
      integrationId,
      integrationSetupId,
      credentialValues: undefined,
      nickname: formData.nickname === undefined || formData.nickname === '' ? undefined : formData.nickname, // It doesn't make sense to allow setting an empty string as a nickname
      settings: {
        datesToPatch: formData.datesToPatch ? objectOfDatesToPatchToArray(formData.datesToPatch) : [],
        datesToPatchToCustomFields: formData.datesToPatchCustomFields ? objectOfDatesToPatchToArray(formData.datesToPatchCustomFields) : [],
        skipDevicesWithBlankSerialNumber:
          formData.dontSkipDevicesWithBlankSerialNumber === undefined ? undefined : !formData.dontSkipDevicesWithBlankSerialNumber,
        maxDaysSinceLastCheckIn:
          formData.maxDaysSinceLastCheckInEnabled === undefined
            ? undefined
            : !formData.maxDaysSinceLastCheckInEnabled
            ? // If the checkbox exists but is not checked
              undefined
            : formData.maxDaysSinceLastCheckIn,

        // <payload type>: {<name>: FieldValueSelectedFilter[]}
        filterSettings: formData.filters
          ? applyToInnerRecordValues<CheckboxInfo[], FieldValueSelectedFilter[]>(
              formData.filters,

              (checkBoxInfos: CheckboxInfo[]) => checkBoxInfos.filter(cbi => cbi.selected).map((cbi: CheckboxInfo) => ({ id: cbi.id })),
            )
          : undefined,

        // <PayloadType>: {<MappingSection>: {<MapFromId>: <MapToId>}}
        valueMappingSettings: formData.selectedMappings ? filterUnassignedOptions(formData.selectedMappings) : undefined,
        fieldMappingSettings: undefined,
      },
    };

    await submitIntegrationSetup(request).catch((e: ApiServerErrorResponseDto) => {
      sendAlert({
        toastType: 'error',
        title: 'Something went wrong',
        description: e.GlobalMessage ?? `Try refreshing the page or contact support if the problem persists.`,
        icon: 'Danger',
        theme: 'negative',
        buttonLeft: {
          label: 'Refresh',
          iconLeft: 'Reload',
          onClick: () => window.location.reload(),
        },
        isNonDismissible: true,
      });

      captureException(e, 'error');
    });
  });
  return {
    submitSyncPreferences,
    useFormReturn,
  };
};
