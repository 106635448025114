import * as Yup from 'yup';
import { DisposalOrderShippingLabelPurchaseFormData } from '~/wm/packages/disposal/packages/order-info/packages/disposal-order-shipment-section/packages/disposal-order-package-module/packages/disposal-order-shipping-label-purchase-form/DisposalOrderShippingLabelPurchaseForm';

const disposalOrderShippingLabelFormValidationSchemaUsps = Yup.object().shape<DisposalOrderShippingLabelPurchaseFormData>({
  packageWeightPoundsList: Yup.array()
    .of(Yup.number().required('Required.').min(1, 'Your package must be at least 1 lb').max(70, 'Your package cannot exceed 70 lbs'))
    .required('At least one package weight is required.'),
});

export default disposalOrderShippingLabelFormValidationSchemaUsps;
