import Button from '~/neo-ui/packages/button/Button';
import { css } from '@emotion/react';
import { Fragment } from 'react';
import ModalConfirmation from '~/neo-ui/packages/modal/packages/modal-confirmation/ModalConfirmation';
import { Styleable } from '~/neo-ui/model/capacity';
import useUserContext from '~/wm/hooks/useUserContext';

export type AutoEmailTestMessageButtonProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isSendingAutoEmailTest: boolean;
  onClickSendTest: () => void;
} & Styleable;

const AutoEmailTestMessageButton = ({ isOpen, setIsOpen, isSendingAutoEmailTest, onClickSendTest }: AutoEmailTestMessageButtonProps) => {
  const { email } = useUserContext();

  return (
    <Fragment>
      <Button
        iconLeft={'Mail'}
        onClick={() => setIsOpen(true)}
        css={css`
          align-self: flex-start;
        `}
      >
        Send Test Message
      </Button>
      <ModalConfirmation
        header={{ title: 'Send test message', icon: 'Mail' }}
        footer={{
          confirmButton: {
            label: 'Send now',
            icon: 'Done',
            loading: isSendingAutoEmailTest,
            onClick: () => onClickSendTest(),
          },
          dismissButton: {
            label: 'Cancel',
            icon: 'Cancel',
            loading: isSendingAutoEmailTest,
            onClick: () => setIsOpen(false),
          },
        }}
        theme={'primary'}
        isOpen={isOpen}
      >
        The preview will only be sent to {email}.
      </ModalConfirmation>
    </Fragment>
  );
};

export default AutoEmailTestMessageButton;
