import { createContext } from 'react';

export type OrganizationContactListMutationContextData = {
  triggerOrganizationContactListReload: (isRequestActive?: () => boolean) => Promise<void>;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const OrganizationContactListMutationContext = createContext<OrganizationContactListMutationContextData>({
  triggerOrganizationContactListReload: async () => {},
});

export default OrganizationContactListMutationContext;
