import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { organizationsSummarySearch, organizationsSummarySearchAvailabilities } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { LifecycleMetadata } from '@AssetManagementClient/BeastClient/Beast/Asset/Model/Device/HardwareStatusType.gen';
import {
  OrganizationSummarySearchResult,
  OrganizationSummarySearchResultNested,
} from '@AssetManagementClient/BeastClient/Beast/Organization/Model/OrganizationsSummary.gen';
import { Discriminant } from '@AssetManagementClient/BeastClient/Beast/Organization/Model/OrganizationsSummary/OrganizationSummarySearchResultNested.gen';
import { css } from '@emotion/react';
import ActionButton from '~/neo-ui/packages/button/packages/action-button/ActionButton';
import Icon from '~/neo-ui/packages/icon/Icon';
import { AsyncFilterTable, QueryResult } from '~/neo-ui/packages/table/packages/async-filter-table/AsyncFilterTable';
import buildSortingSet from '~/neo-ui/packages/table/packages/async-filter-table/builder/buildSortingSet';
import { DataTableColumn } from '~/neo-ui/packages/table/packages/data-table/DataTable';
import { FieldKeyExpressionSegment } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { Metadata, TableState } from '~/neo-ui/packages/table/packages/filter-table/FilterTable';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import useApi from '~/wm/packages/api/hook/useApi';
import LifecycleStatusBar from '~/wm/packages/organization/packages/summary/LifecycleStatusBar';
import OrganizationsSummaryEmptyState from '~/wm/packages/organization/packages/summary/OrganizationsSummaryEmptyState';
import Button from '~/neo-ui/packages/button/Button';
import { Enum } from '@AssetManagementClient/Scoping/Model/ScopeNested.gen';
import buildFrontendApiQueryKey from '~/neo-ui/packages/table/packages/async-filter-table/builder/buildFrontendApiQueryKey';
import Testable from '~/neo-ui/packages/testable/Testable';
import OrganizationStarButton from '~/wm/packages/organization/packages/organization-star/packages/organization-star-button/OrganizationStarButton';
import OrganizationStarSection from '~/wm/packages/organization/packages/organization-star/OrganizationStarSection';
import useOrganizationStar from '~/wm/packages/organization/packages/organization-star/hooks/useOrganizationStar';
import LifecycleReportDownloadButton from '~/neo-ui/packages/table/packages/console/download/packages/lifecycle-report-download-button/LifecycleReportDownloadButton';
import useOrganizationsSummaryFeatureUnlockedSearch from '~/wm/packages/organization/packages/summary/hooks/useOrganizationsSummaryFeatureUnlockedSearch';
import Badge from '~/neo-ui/packages/badge/Badge';

export type OrganizationsSummaryProps = {
  organizationMainPageUrl: string;
  softwareViewUrl: string;
  hardwareLifecycleReportUrl: string;
  softwareReportUrl: string;
  scheduledReportsSettingsUrl: string;
  summaryReportUrl: string;
  issuesPageUrl: string;
  currencyCharacter: string;
  hasSam: boolean;
  canAccessLifecycleReports: boolean;
  hardwareConsoleUrl: string;
  isFreeEdition: boolean;
  lmLastCheckinDateEnabled: boolean;
  hasFullColumnAccess: boolean;
};

const OrganizationsSummary: FunctionComponent<OrganizationsSummaryProps> = ({
  organizationMainPageUrl,
  softwareViewUrl,
  softwareReportUrl,
  scheduledReportsSettingsUrl,
  summaryReportUrl,
  issuesPageUrl,
  currencyCharacter,
  hasSam,
  canAccessLifecycleReports,
  hardwareConsoleUrl,
  isFreeEdition,
  lmLastCheckinDateEnabled,
  hasFullColumnAccess,
}) => {
  const clientsPerPage = 50;

  const { starredOrganizations: organizationStars, addStar, removeStar } = useOrganizationStar(isFreeEdition);

  const { callApi } = useApi();

  const [lifecycleMetadata, setLifecycleMetadata] = useState<LifecycleMetadata | undefined>(undefined);

  const { featureUnlockedOrganizationSummaryResult } = useOrganizationsSummaryFeatureUnlockedSearch();

  type OrganizationColumn = DataTableColumn<OrganizationSummarySearchResult>;
  const tableColumns: OrganizationColumn[] = useMemo(() => {
    const columns: OrganizationColumn[] = [];

    // Add favourite column
    columns.push({
      Header: (
        <Icon
          icon={'Favorite'}
          css={css`
            margin-left: 0.4375rem;
          `}
        />
      ),
      width: '1.875rem',
      fieldKey: row => (row as FieldKeyExpressionSegment<OrganizationSummarySearchResultNested.Visible>).organization.id,
      canClientSideSearch: false,
      renderCell: organizationSummary =>
        // Free partners cannot star their organizations
        isFreeEdition || organizationSummary.case !== Discriminant.Visible ? (
          <Button
            iconLeft={'FavoriteNo'}
            size={'sm'}
            disabled={true}
          />
        ) : (
          <OrganizationStarButton
            isStarred={typeof organizationStars === 'undefined' ? false : organizationStars.has(organizationSummary.organization.id)}
            onStar={isStarred =>
              isStarred ? addStar(organizationSummary.organization.id) : removeStar(organizationSummary.organization.id)
            }
          />
        ),
    });

    // Add client
    columns.push({
      Header: 'Client',
      fieldKey: row => (row as FieldKeyExpressionSegment<OrganizationSummarySearchResultNested.Visible>).organization.name,
      ellipsizeTextContent: true,
      canClientSideSearch: true,
      renderCell: organizationSummary =>
        organizationSummary.case === Discriminant.Visible ? (
          <Testable testId={'client-individual-page-url'}>
            <div
              css={css`
                display: flex;
                flex-direction: row;
                gap: 0.5rem;
                align-items: center;
                vertical-align: center;
              `}
            >
              <a href={organizationMainPageUrl.replace('__ORGANIZATION_ID__', organizationSummary.organization.id)}>
                {organizationSummary.organization.name}
              </a>
              {featureUnlockedOrganizationSummaryResult.some(organization =>
                organization.case === Discriminant.Visible ? organization.organization.id === organizationSummary.organization.id : false,
              ) && (
                <Badge
                  bgColor={'warning-500'}
                  textColor={'light-000'}
                  height={'0.875rem'}
                  css={css`
                    text-transform: uppercase;
                  `}
                >
                  UNLOCKED
                </Badge>
              )}
            </div>
          </Testable>
        ) : undefined,
      width: '17.1875rem',
    });

    if (hasFullColumnAccess) {
      // Add DMI info
      columns.push(
        {
          Header: 'DMI score',
          fieldKey: row => (row as FieldKeyExpressionSegment<OrganizationSummarySearchResultNested.Visible>).dmiScore,
          renderCell: organizationSummary => (organizationSummary.case === Discriminant.Visible ? organizationSummary.dmiScore : undefined),
          width: '6.25rem',
        },
        {
          Header: '30-day trend',
          fieldKey: row => (row as FieldKeyExpressionSegment<OrganizationSummarySearchResultNested.Visible>).dmiScoreDifference,
          renderCell: organizationSummary =>
            organizationSummary.case === Discriminant.Visible ? scoreTrendToElement(organizationSummary.dmiScoreDifference) : undefined,
          width: '6.25rem',
        },
      );
    }

    // Add hardware lifecycle information
    columns.push(
      {
        Header: 'Hardware replacement',
        fieldKey: row =>
          (row as FieldKeyExpressionSegment<OrganizationSummarySearchResultNested.Visible>).hardwareLifecycleSummary
            .totalReplacementOpportunity,
        renderCell: organizationSummary =>
          `${currencyCharacter}${
            organizationSummary.case === Discriminant.Visible
              ? organizationSummary.hardwareLifecycleSummary.totalReplacementOpportunity.toLocaleString()
              : undefined
          }`,
        width: '8.125rem',
      },
      {
        Header: 'Hardware assets',
        fieldKey: row => (row as FieldKeyExpressionSegment<OrganizationSummarySearchResultNested.Visible>).hardwareLifecycleSummary.count,
        renderCell: organizationSummary =>
          organizationSummary.case === Discriminant.Visible ? (
            <a href={hardwareConsoleUrl.replace('__ORGANIZATION_ID__', organizationSummary.organization.id)}>
              {organizationSummary.hardwareLifecycleSummary.count.toLocaleString()}
            </a>
          ) : undefined,
        width: '6.25rem',
      },
      {
        Header: 'Hardware status',
        fieldKey: row =>
          (row as FieldKeyExpressionSegment<OrganizationSummarySearchResultNested.Visible>).hardwareLifecycleSummary.countByStatus,
        renderCell: organizationSummary =>
          organizationSummary.case === Discriminant.Visible ? (
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: space-between;
              `}
            >
              {
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    margin-right: 0.5rem;
                  `}
                >
                  <div
                    css={css`
                      margin-right: 0.3125rem;
                    `}
                  >
                    {canAccessLifecycleReports ? (
                      <LifecycleReportDownloadButton
                        organizationId={organizationSummary.organization.id}
                        lifecycleReportDownloadLocation={'clients'}
                        lmLastCheckinDateEnabled={lmLastCheckinDateEnabled}
                      />
                    ) : (
                      <ActionButton
                        css={css`
                          border-radius: 0.313rem;
                          box-shadow: none;
                        `}
                        icon="PageDownload"
                        size={'sm'}
                        tooltipContent={'Upgrade to download'}
                        activeBackgroundColor={'light-600'}
                        isDisabled={true}
                      />
                    )}
                  </div>
                  {organizationSummary.scheduledReportsSummary && (
                    <div>
                      <ActionButton
                        css={css`
                          border-radius: 0.313rem;
                          box-shadow: none;
                        `}
                        icon={
                          organizationSummary.scheduledReportsSummary.recipientCount > 0
                            ? organizationSummary.scheduledReportsSummary.isEnabled
                              ? organizationSummary.scheduledReportsSummary.bouncingEmailRecipientCount > 0
                                ? 'EmailInvalid'
                                : 'PageScheduled'
                              : 'Paused'
                            : 'AddDocument'
                        }
                        inactiveIconColor={
                          organizationSummary.scheduledReportsSummary.recipientCount > 0
                            ? organizationSummary.scheduledReportsSummary.isEnabled
                              ? organizationSummary.scheduledReportsSummary.bouncingEmailRecipientCount > 0
                                ? 'warning-400'
                                : 'dark-300'
                              : 'excellent-400'
                            : 'primary-400'
                        }
                        size={'sm'}
                        actionUrl={scheduledReportsSettingsUrl.replace('__ORGANIZATION_ID__', organizationSummary.organization.id)}
                        activeBackgroundColor={
                          organizationSummary.scheduledReportsSummary.recipientCount > 0
                            ? organizationSummary.scheduledReportsSummary.isEnabled
                              ? organizationSummary.scheduledReportsSummary.bouncingEmailRecipientCount > 0
                                ? 'warning-400'
                                : 'dark-300'
                              : 'excellent-400'
                            : 'primary-400'
                        }
                        tooltipContent={
                          organizationSummary.scheduledReportsSummary.recipientCount > 0
                            ? organizationSummary.scheduledReportsSummary.isEnabled
                              ? organizationSummary.scheduledReportsSummary.bouncingEmailRecipientCount > 0
                                ? `Problem with ${organizationSummary.scheduledReportsSummary.bouncingEmailRecipientCount} email${
                                    organizationSummary.scheduledReportsSummary.bouncingEmailRecipientCount > 1 ? 's' : ''
                                  }`
                                : `${organizationSummary.scheduledReportsSummary.recipientCount} scheduled report${
                                    organizationSummary.scheduledReportsSummary.recipientCount > 1 ? 's' : ''
                                  }`
                              : 'Scheduled reports paused'
                            : 'Set up scheduled reports'
                        }
                      />
                    </div>
                  )}
                </div>
              }
              {lifecycleMetadata && (
                <LifecycleStatusBar
                  css={css`
                    width: 70%;
                  `}
                  countByStatusEnum={organizationSummary.hardwareLifecycleSummary.countByStatus}
                  metadataByStatusEnum={lifecycleMetadata.hardwareLifecycleMetadataByEnum}
                />
              )}
            </div>
          ) : undefined,
        width: '14.6875rem',
      },
    );

    // Add SAM columns
    if (hasSam && hasFullColumnAccess) {
      columns.push(
        {
          Header: 'Software assets',
          fieldKey: row =>
            (row as FieldKeyExpressionSegment<OrganizationSummarySearchResultNested.Visible>).softwareVersionLifecycleSummary.count,
          renderCell: organizationSummary =>
            organizationSummary.case === Discriminant.Visible ? (
              <a href={softwareViewUrl.replace('__ORGANIZATION_ID__', organizationSummary.organization.id)}>
                {organizationSummary.softwareVersionLifecycleSummary.count.toLocaleString()}
              </a>
            ) : undefined,
          width: '6.25rem',
        },
        {
          Header: 'Software status',
          fieldKey: row =>
            (row as FieldKeyExpressionSegment<OrganizationSummarySearchResultNested.Visible>).softwareVersionLifecycleSummary.versionStatus,
          renderCell: organizationSummary =>
            organizationSummary.case === Discriminant.Visible ? (
              <div
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <div
                  css={css`
                    margin-right: 0.8125rem;
                  `}
                >
                  {canAccessLifecycleReports ? (
                    <ActionButton
                      css={css`
                        border-radius: 0.313rem;
                        box-shadow: none;
                      `}
                      icon="PageDownload"
                      size={'sm'}
                      actionUrl={softwareReportUrl.replace('__ORGANIZATION_ID__', organizationSummary.organization.id)}
                      tooltipContent={'Download lifecycle report'}
                      activeBackgroundColor={'dark-300'}
                    />
                  ) : (
                    <ActionButton
                      css={css`
                        border-radius: 0.313rem;
                        box-shadow: none;
                      `}
                      icon="PageDownload"
                      size={'sm'}
                      tooltipContent={'Upgrade to download'}
                      activeBackgroundColor={'light-600'}
                      isDisabled={true}
                    />
                  )}
                </div>
                {lifecycleMetadata && (
                  <LifecycleStatusBar
                    css={css`
                      width: 75%;
                    `}
                    countByStatusEnum={organizationSummary.softwareVersionLifecycleSummary.versionStatus}
                    metadataByStatusEnum={lifecycleMetadata.versionStatusMetadataByEnum}
                  />
                )}
              </div>
            ) : undefined,
          width: '11.25rem',
        },
      );
    }

    return columns;
  }, [
    addStar,
    canAccessLifecycleReports,
    currencyCharacter,
    featureUnlockedOrganizationSummaryResult,
    hardwareConsoleUrl,
    hasFullColumnAccess,
    hasSam,
    isFreeEdition,
    lifecycleMetadata,
    lmLastCheckinDateEnabled,
    organizationMainPageUrl,
    organizationStars,
    removeStar,
    scheduledReportsSettingsUrl,
    softwareReportUrl,
    softwareViewUrl,
  ]);

  const fetchSearchAvailabilities = useCallback(async (): Promise<Metadata | 'error'> => {
    const response = await callApi(() => organizationsSummarySearchAvailabilities({}));
    if (!response) {
      return 'error';
    }

    return {
      filterSet: { filters: [] },
      sortingSet: buildSortingSet(response.orderings),
      supportsGlobalSearch: true,
    };
  }, [callApi]);

  const scoreTrendToElement = (scoreDifference: number | undefined) => {
    if (typeof scoreDifference === 'undefined') {
      return (
        <Icon
          icon={'Help'}
          color={'light-700'}
        />
      );
    }
    if (scoreDifference === 0) {
      return (
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <Icon
            icon={'Remove'}
            color={'caution-400'}
          />
          <Label
            color={'caution-400'}
            css={css`
              margin-left: 0.625rem;
              line-height: 0;
            `}
          >
            {scoreDifference}
          </Label>
        </div>
      );
    }
    if (scoreDifference > 0) {
      return (
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <Icon
            icon={'GoUp'}
            color={'positive-400'}
          />
          <Label
            color={'positive-400'}
            css={css`
              margin-left: 0.625rem;
              line-height: 0;
            `}
          >
            {scoreDifference}
          </Label>
        </div>
      );
    }
    if (scoreDifference < 0) {
      return (
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <Icon
            icon={'GoDown'}
            color={'negative-400'}
          />
          <Label
            color={'negative-400'}
            css={css`
              margin-left: 0.625rem;
              line-height: 0;
            `}
          >
            {Math.abs(scoreDifference)}
          </Label>
        </div>
      );
    }
    return <div />;
  };

  const fetchOrganizationsSummary = useCallback(
    async (query: TableState<OrganizationSummarySearchResult>): Promise<QueryResult<OrganizationSummarySearchResult>> => {
      const response = await callApi(() =>
        organizationsSummarySearch({
          queryDto: buildFrontendApiQueryKey(query),
          scope: { type: Enum.Account },
        }),
      );
      if (!response) {
        return {
          data: [],
        };
      }
      const { results, paginationMetadata, lifecycleMetadata: responseMetadata } = response;

      // Ideally fetching this metadata would be separate controller from fetching data
      if (typeof lifecycleMetadata === 'undefined') {
        setLifecycleMetadata(responseMetadata);
      }

      return {
        data: results,
        paginationMetadata: {
          totalResults: paginationMetadata.totalResult,
          totalPages: paginationMetadata.totalPages,
        },
      };
    },
    [callApi, lifecycleMetadata],
  );

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 3rem;
      `}
    >
      <OrganizationStarSection starredOrganizations={organizationStars} />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
          `}
        >
          <Header
            size={2}
            weight={'bold'}
          >
            All Clients
          </Header>
          {hasFullColumnAccess && (
            <Button
              anchor={{
                href: summaryReportUrl,
              }}
              iconRight={'PageDownload'}
              size={'md'}
              theme={'primary'}
            >
              Clients Summary Report
            </Button>
          )}
        </div>
        <Testable testId={'client-summary-table'}>
          <AsyncFilterTable
            columns={tableColumns}
            onFetchMetadata={fetchSearchAvailabilities}
            onFetchData={fetchOrganizationsSummary}
            defaultSort={{
              key: row =>
                (row as FieldKeyExpressionSegment<OrganizationSummarySearchResultNested.Visible>).hardwareLifecycleSummary
                  .totalReplacementOpportunity,
              order: 'descending',
            }}
            EmptyStatePlaceholder={() => <OrganizationsSummaryEmptyState issuesPageUrl={issuesPageUrl} />}
            getRowOptions={row => ({
              backgroundColor: row.original.case === Discriminant.Blurred ? '#F8F8FC' : undefined,
            })}
            perPageSize={clientsPerPage}
            enableClientPaginated={'inline'}
            manualSort={true}
            pinnedData={featureUnlockedOrganizationSummaryResult}
          />
        </Testable>
      </div>
    </div>
  );
};

export default OrganizationsSummary;
