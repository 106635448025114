import Window from '~/neo-ui/packages/window/Window';
import Button from '~/neo-ui/packages/button/Button';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import React, { useState } from 'react';
import AutoEmailReportFormData, {
  autoEmailReportConsoleStateFieldKey,
} from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-form/types/AutoEmailReportFormData';
import AutoEmailReportFormInputs from '~/wm/packages/organization/packages/auto-email-report/packages/auto-email-report-form/AutoEmailReportFormInputs';
import { ConsoleState } from '~/neo-ui/packages/table/packages/console/types';

export type AutoEmailReportCreateWindowProps = {
  isOpen: boolean;
  onDismiss: () => void;
};

const AutoEmailReportCreateWindow = ({ isOpen, onDismiss }: AutoEmailReportCreateWindowProps) => {
  const { submitForm, isSubmitting, getFormInput, setFormInput } = useFormContext<AutoEmailReportFormData>();

  const [consoleState, setConsoleState] = useState<ConsoleState>(getFormInput<ConsoleState>(autoEmailReportConsoleStateFieldKey).value);

  return (
    <Window
      title={'Create Scheduled Report'}
      isOpen={isOpen}
      onDismiss={onDismiss}
      footerProps={{
        rightControls: [
          {
            expanded: (
              <Button
                iconLeft={'Done'}
                theme={'primary'}
                loading={isSubmitting}
                onClick={async () => {
                  await setFormInput<ConsoleState>(autoEmailReportConsoleStateFieldKey, consoleState);
                  await submitForm();
                }}
              >
                Save
              </Button>
            ),
          },
        ],
      }}
    >
      <AutoEmailReportFormInputs
        consoleState={consoleState}
        setConsoleState={setConsoleState}
      />
    </Window>
  );
};

export default AutoEmailReportCreateWindow;
